import React from "react";
import styled, { keyframes } from "styled-components";
import { Theme } from "../../theme/theme.model";

export type CardSkeletonProps = React.HTMLAttributes<HTMLDivElement>;

interface StyledCardSkeletonProps extends CardSkeletonProps {
    theme: Theme;
}

const CardBackgroundColor = (props) => props.theme.colors.grey400;

const StyledCardSkeleton = styled.div`
    width: 100%;
    height: 300px;
    overflow: hidden;
    background-color: ${(props: StyledCardSkeletonProps) =>
        CardBackgroundColor(props)};
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const pulse = keyframes`
    0% {
        background-position: 0% 0%;
    }
    100% {
    background-position: -135% 0%;
    }
`;

const StyledCardSkeletonTopContainer = styled.div`
    width: 100%;
    height: 75%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: linear-gradient(
        90deg,
        ${(props: StyledCardSkeletonProps) => CardBackgroundColor(props)} 0%,
        #fbfbfb 50%,
        ${(props: StyledCardSkeletonProps) => CardBackgroundColor(props)} 100%
    );
    background-size: 400% 400%;
    animation-name: ${pulse};
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
`;

const StyledCardSkeletonBottomContainer = styled.div`
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: white;
    padding-left: 5%;
    padding-right: 1%;
`;

const StyledCardSkeletonTitle = styled.div`
    height: 30%;
    width: 30%;
    background: linear-gradient(
        90deg,
        ${(props: StyledCardSkeletonProps) => CardBackgroundColor(props)} 0%,
        #fbfbfb 50%,
        ${(props: StyledCardSkeletonProps) => CardBackgroundColor(props)} 100%
    );
    background-size: 400% 400%;
    animation-name: ${pulse};
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
`;

const StyledCardSkeletonSubtitle = styled.div`
    height: 25%;
    width: 60%;
    background: linear-gradient(
        90deg,
        ${(props: StyledCardSkeletonProps) => CardBackgroundColor(props)} 0%,
        #fbfbfb 50%,
        ${(props: StyledCardSkeletonProps) => CardBackgroundColor(props)} 100%
    );
    background-size: 400% 400%;
    animation-name: ${pulse};
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
`;

const CardSkeleton = () => {
    return (
        <StyledCardSkeleton>
            <StyledCardSkeletonTopContainer />
            <StyledCardSkeletonBottomContainer>
                <StyledCardSkeletonTitle />
                <StyledCardSkeletonSubtitle />
            </StyledCardSkeletonBottomContainer>
        </StyledCardSkeleton>
    );
};

export default CardSkeleton;
