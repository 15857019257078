import { keyframes } from "styled-components";
import styled from "styled-components";

const variants = {
    default: {
        size: "20px",
    },
    medium: {
        size: "90px",
        iconSize: "40px",
    },
    small: {
        size: "20px",
        iconSize: "10px",
    },
};

const AnimCheckboxCheck = keyframes`
    0%{
        width: 0;
        height: 0;
        transform: translate3d(0,0,0) rotate(45deg);
    }
    50%{
        width: 6px;
        height: 0;
        transform: translate3d(0,0,0) rotate(45deg);
    }
    100%{    
        width: 6px;
        height: 10px;
        transform: translate3d(0,-10px,0) rotate(45deg);
    }
`;

export const AnimShrinkBounce = keyframes`
    0%{
        transform: scale(1);
    }
    33%{    
        transform: scale(.7);
    }
    100%{
        transform: scale(1);    
    }
`;

export interface CheckboxProps extends React.HTMLAttributes<HTMLDivElement> {
    icon?: React.ReactNode;
    color?: string;
    variant?: string;
    textDirection?: string;
}

export const StyledCheckboxContainer = styled.div<CheckboxProps>`
    display: flex;
    span {
        transition: ${(props) => props.theme.transition};
        &.checked {
            display: block;
            border: 2px solid ${(props) => props.theme.border.color};
            height: ${variants.default.size};
            width: ${variants.default.size};
            min-width: ${variants.default.size};
            min-height: ${variants.default.size};
            border-radius: ${(props) => props.theme.border.radius};
            transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
            background: transparent;
            top: 2px;
        }
        &:not(.cheched) {
            position: relative;
            display: inline-block;
            color: inherit;
        }
    }

    input {
        &:checked:disabled + label {
            span {
                &.checked {
                    border-color: ${(props) =>
                        props.theme.colors.grey400} !important;
                }
                &:not(.checked) {
                    &:after {
                        border-right: 3px solid
                            ${(props) => props.theme.colors.grey600} !important;
                        border-bottom: 3px solid
                            ${(props) => props.theme.colors.grey600} !important;
                        background-color: ${(props) =>
                            props.theme.colors.grey400} !important;
                    }
                }
            }
        }
        &:disabled + label {
            color: ${(props) => props.theme.colors.grey600} !important;
            &:hover {
                cursor: not-allowed;
            }
            span.checked {
                background-color: ${(props) =>
                    props.theme.colors.grey400} !important;
            }
        }
        &:checked + label {
            span {
                &.checked {
                    animation: ${AnimShrinkBounce} 200ms
                        cubic-bezier(0.4, 0, 0.23, 1);
                    height: 0;
                    width: 0;
                    border: 9.5px solid
                        ${(props) =>
                            props.theme.colors[props.color]
                                ? props.theme.colors[props.color]
                                : props.theme.colors.primary};
                }
                &:not(.checked) {
                    &:after {
                        content: "";
                        position: absolute;
                        top: 13px;
                        ${({ textDirection }) =>
                            textDirection === "left"
                                ? "right: -15px"
                                : "left: -20px"};
                        border-right: 3px solid #fff !important;
                        border-bottom: 3px solid #fff !important;
                        transform: rotate(45deg);
                        transform-origin: 0% 100%;
                        animation: ${AnimCheckboxCheck} 100ms 100ms
                            cubic-bezier(0.4, 0, 0.23, 1) forwards;
                        z-index: 1;
                        background-color: ${(props) =>
                            props.theme.colors[props.color]
                                ? props.theme.colors[props.color]
                                : props.theme.colors.primary};
                    }
                }
            }
        }
    }

    ${({ icon, color, theme, variant }) =>
        `
        ${
            icon &&
            (variant === "medium" || variant === "small") &&
            `
                background-color: transparent;
                border: none;
                outline: none;
                border: 2px solid
                    ${variant === "small" ? theme.border.color : "transparent"};
                padding: ${variant === "small" ? 0 : "5px"};
                max-width: ${
                    variant === "small"
                        ? variants.small.size
                        : variants.medium.size
                };
                min-width: ${
                    variant === "small"
                        ? variants.small.size
                        : variants.medium.size
                };
                max-height: ${
                    variant === "small"
                        ? variants.small.size
                        : variants.medium.size
                };
                width: ${
                    variant === "small"
                        ? variants.small.size
                        : variants.medium.size
                };
                height: ${
                    variant === "small"
                        ? variants.small.size
                        : variants.medium.size
                };
                align-items: center;
                justify-content: center;
                ${
                    variant === "small" &&
                    `border-radius: ${theme.border.radius};`
                };
                span {
                    border-radius: 0 !important;
                    &.icon-checked {
                    }

                    &:before,
                    &:after {
                        display: none;
                        content: initial;
                    }
                }

                input {
                    + label {
                        flex-wrap: wrap;
                        justify-content: center;
                        ${
                            variant === "small"
                                ? `
                            
                                position: relative;
                                &:after{
                                    border: 0 solid ${theme.colors[color]};
                                    content: "";
                                    position: absolute;
                                    top: -5px;
                                    left: -5px;
                                    width: ${variants.small.size};
                                    height: ${variants.small.size};
                                    z-index: 0;
                                    border-radius: ${theme.border.radius};
                                    transition: .4s;
                                }
                            `
                                : ""
                        };

                        span {
                            &.icon-checked {
                                z-index: 1;
                                > svg {
                                    &:first-of-type {
                                        display: none;
                                    }
                                    &:last-of-type {
                                        display: block;
                                    }
                                }
                            }
                            &:not(.icon-checked) {
                                font-size: ${theme.typography.text.small};
                                color: ${theme.colors.grey700};
                                max-width: calc(${variants.medium.size} - 10px);
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                ${variant === "small" && `display:none`};
                            }
                        }
                    }
                    &:disabled + label {
                        svg {
                            rect,
                            polygon,
                            path {
                                fill: ${theme.colors.grey700};
                            }
                        }
                    }
                    &:checked + label {
                        border-color: ${theme.colors[color]};
                        &:after {
                            border: 9.5px solid ${theme.colors[color]};
                        }
                        span {
                            &.icon-checked {
                                > svg {
                                    rect,
                                    polygon,
                                    path {
                                        fill: ${
                                            variant === "small"
                                                ? theme.colors.white
                                                : theme.colors[color]
                                        };
                                    }
                                    &:first-of-type {
                                        display: block;
                                    }
                                    &:last-of-type {
                                        display: none;
                                    }
                                }
                            }
                            &:not(.icon-checked) {
                                color: ${theme.colors[color]};
                            }
                        }
                    }
                }
                &:hover {
                    input {
                        &:not(:disabled):not(:checked) + label span {
                            color: ${theme.colors[color]} !important;
                            svg {
                                path,
                                react,
                                polygon {
                                    fill: ${theme.colors[color]};
                                }
                            }
                        }
                        &:disabled + label {
                            cursor: not-allowed;
                        }
                    }
                }
            `
        }
    `}
`;

export const StyledInput = styled.input`
    display: inline-block;
    height: 0;
    width: 0;
    display: none;
`;

export const StyledLabel = styled.label`
    display: flex;
    &:hover {
        cursor: pointer;
    }
    span {
        &.checked {
            &:first-child {
                margin-right: 5px;
            }
            &:last-child {
                margin-left: 5px;
            }
        }
    }
`;

export const StyledIconContainer = styled.span<CheckboxProps>`
    display: block;
    > svg,
    > img {
        ${({ variant }) => `
            width: ${
                variant === "small"
                    ? variants.small.iconSize
                    : variants.medium.iconSize
            };
            max-width: ${
                variant === "small"
                    ? variants.small.iconSize
                    : variants.medium.iconSize
            };
            height: ${
                variant === "small"
                    ? variants.small.iconSize
                    : variants.medium.iconSize
            };
            max-height: ${
                variant === "small"
                    ? variants.small.iconSize
                    : variants.medium.iconSize
            };
            &:not(:only-child) {
                margin-bottom: ${variant === "small" ? 0 : "5px"};
            }
        `}
    }
`;
