import styled from "styled-components";

export const StyledTooltip = styled.div`
    position: relative;
    display: inline-block;
    cursor: default;
    &:hover {
        .tooltip {
            visibility: visible;
            transform: translateY(-10px);
            opacity: 1;
            transition: 0.3s linear;
        }
    }
`;
