import React, { useRef, useState, useEffect } from "react";

import styled from "styled-components";
import { StyledLabel } from "../Input/Input";

export interface TextareaProps {
    textareaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
    label?: string;
    height?: string;
    maxHeight?: string;
    autoResize?: boolean;
    onChange?: (...args: any) => void;
}

const StyledTextareaContainer = styled.div`
    position: relative;
    display: inline-flex;
`;

const StyledTextarea = styled.textarea<TextareaProps>`
    border: 0;
    background-color: ${({ theme }) => theme.colors.grey400};
    border-radius: ${({ theme }) => theme.border.radius};
    padding: 30px 10px 10px 10px;
    color: ${({ theme }) => theme.colors.grey900};
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
    &:focus {
        outline: none;
    }
`;

const StyledTextareaLabel = styled(StyledLabel)`
    top: 1px;
    left: 0;
    padding: 0 10px;
    line-height: 30px;
    background-color: ${({ theme }) => theme.colors.grey400};
    border-radius: ${({ theme }) => theme.border.radius};
    width: 100%;
`;

const Textarea = ({
    textareaProps,
    label,
    height = "50px",
    maxHeight,
    autoResize = false,
    onChange,
}: TextareaProps) => {
    const [currentVal, setCurrentVal] = useState(textareaProps?.defaultValue);
    const ref = useRef<any>();

    if (textareaProps?.value) {
        setCurrentVal(textareaProps.value);
        delete textareaProps.value;
    }

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentVal(event.target.value);
        setAutoHeight?.(event);
        onChange?.(event);
    };

    const setAutoHeight = (e) => {
        const target = e.target as HTMLTextAreaElement;

        if (autoResize === false) {
            return false;
        }
        ref.current.style.height = height;
        ref.current.style.height = `${target.scrollHeight}px`;
    };

    useEffect(() => {
        setCurrentVal(textareaProps?.defaultValue);
    }, [textareaProps?.defaultValue]);

    return (
        <>
            <StyledTextareaContainer>
                {label && <StyledTextareaLabel>{label}</StyledTextareaLabel>}
                <StyledTextarea
                    maxHeight={maxHeight}
                    ref={ref}
                    onChange={handleChange}
                    {...textareaProps}
                    defaultValue={currentVal}
                />
            </StyledTextareaContainer>
        </>
    );
};

export default Textarea;
