import { Theme } from "./theme.model";

const defaultTheme: Theme = {
    colors: {
        primary: "#91a436",
        secondary: "#7C4789",
        tertiary: "#005874",

        white: "#fff",
        black: "#000",

        red: "#c12f2f",
        yellow: "#ffcc33",
        orange: "#c18f2f",
        blue: "#57a4ff",
        lightYellow: "#FBE1B6",
        lightBlue: "#1890FF",
        lightGreen: "#61B292",

        grey900: "#333333",
        grey800: "#666666",
        grey700: "#979797",
        grey600: "#9c9c9c",
        grey500: "#acacac",
        grey400: "#dfdfdf",
        grey300: "#ebebeb",
        grey200: "#f3f3f3",
        grey100: "#f7f7f7",
        grey50: "#fbfbfb",
        grey30: "#E7EFF3",
    },
    typography: {
        text: {
            smaller: "10px",
            small: "12px",
            normal: "14px",
            medium: "16px",
            big: "20px",
        },
        header: {
            h1: "40px",
            h2: "25px",
            h3: "21px",
            h4: "18px",
            h5: "14px",
            h6: "13px",
        },
        fontFamily: {
            default: "Lato, Roboto, Helvetica, Arial, sans-serif",
            verdana: "Verdana",
        },
        weight: {
            thin: "300",
            normal: "400",
            bold: "700",
        },
    },
    size: {
        form: {
            input: 50,
            button: 45,
        },
    },
    border: {
        radius: "4px",
        color: "#dfdfdf",
    },
    transition: "0.3s",
};

export default defaultTheme;
