import React, { useState } from "react";
import { StyledTooltip } from "./TooltipStyles";

export interface TooltipProps extends React.HTMLProps<HTMLElement> {
    /**
     * Position of the tooltip
     * @default bottom
     */
    position?: "top" | "right" | "bottom" | "left";
    /**
     * Tooltip text
     */
    text?: string;
}

/**
 * Render a square input checkbox
 * @component Tooltip
 */
export const Tooltip = (props: TooltipProps) => {
    const { children, position = "bottom", text = "" } = props;
    const makeId = () => {
        return `tooltip-id-${Math.floor(Math.random() * 1000)}`;
    };

    const elRef = React.createRef<HTMLDivElement>();
    // const [tooltipId, setTooltipId] = useState(makeId());
    const tooltipId = makeId();
    const [dynamicPosition, setDynamicPosition] = useState(position);

    const setPosition = ({ tooltipProps, newPosition }) => {
        const autoAdjustPosition = newPosition || dynamicPosition;
        const { width, height, left, top } = tooltipProps.target;
        const halfWidth = width / 2;
        let offsetLeft, offsetTop;
        switch (autoAdjustPosition) {
            case "bottom":
                offsetLeft = left + halfWidth - 20;
                offsetTop = top + (height + height / 2);
                break;
            case "top":
                offsetLeft = left + halfWidth - 20;
                offsetTop = top - (height + height / 2) - 5;
                break;
            case "right":
                offsetLeft = left + width + 20;
                offsetTop = top - height / 3;
                break;
            case "left":
                offsetLeft = left - 20;
                offsetTop = top - height / 3;
                break;
        }
        return { offsetLeft, offsetTop };
    };

    const removeTooltipElement = () => {
        const tooltipElement = document.getElementsByClassName("tooltip");
        // exit if element not exist
        if (!tooltipElement) {
            return false;
        }

        for (let i = 0; i < tooltipElement.length; i++) {
            tooltipElement[i].remove();
        }
        return true;
    };

    const renderTooltip = (text, tooltipProps, newPosition) => {
        removeTooltipElement();
        const autoAdjustPosition = newPosition || dynamicPosition;
        const tooltipContainer = document.createElement("div");
        const tooltipPosition = setPosition({ tooltipProps, newPosition });

        // Set id, class and tooltip text
        tooltipContainer.setAttribute("id", tooltipId);
        tooltipContainer.classList.add("tooltip");
        tooltipContainer.classList.add(autoAdjustPosition);
        tooltipContainer.innerHTML = text;

        // Set tooltip style
        tooltipContainer.setAttribute(
            `style`,
            `position: fixed; left: ${tooltipPosition.offsetLeft}px; top: ${tooltipPosition.offsetTop}px;`,
        );

        // Inserted Tooltip
        document.body.appendChild(tooltipContainer);

        // Get inserted element
        const insertedElm = document.getElementById(tooltipId);
        const bounding = insertedElm.getBoundingClientRect();
        const insertedElmPadding = 20;

        insertedElm.style.left = `${
            autoAdjustPosition === "right"
                ? tooltipPosition.offsetLeft
                : autoAdjustPosition === "left"
                ? tooltipPosition.offsetLeft - insertedElm.clientWidth
                : tooltipPosition.offsetLeft -
                  insertedElm.clientWidth / 2 +
                  insertedElmPadding
        }px`;

        // Check position
        checkOverflow({
            text,
            tooltipProps,
            bounding,
            insertedElm,
        });
    };

    const attemptedPositions = [];

    const checkOverflow = ({ text, tooltipProps, bounding, insertedElm }) => {
        let newPosition: "bottom" | "right" | "top" | "left";
        const left = bounding.left - insertedElm.clientWidth / 2;
        const top = bounding.top;

        // Top is out of viewport
        if (top <= 1 && attemptedPositions.indexOf("bottom") === -1) {
            setDynamicPosition("bottom");
            attemptedPositions.push("bottom");
            newPosition = "bottom";
        }

        // Left side is out of viewoprt
        if (left <= 1 && attemptedPositions.indexOf("right") === -1) {
            setDynamicPosition("right");
            attemptedPositions.push("right");
            newPosition = "right";
        }

        // Bottom is out of viewport
        if (
            Math.round(bounding.bottom) >
                (window.innerHeight || document.documentElement.clientHeight) &&
            attemptedPositions.indexOf("top") === -1
        ) {
            setDynamicPosition("top");
            attemptedPositions.push("top");
            newPosition = "top";
        }

        // Right side is out of viewport
        if (
            Math.round(bounding.right) >
                (window.innerWidth || document.documentElement.clientWidth) &&
            attemptedPositions.indexOf("left") === -1
        ) {
            setDynamicPosition("left");
            attemptedPositions.push("left");
            newPosition = "left";
        }

        // Reset Position if element is on viewport
        if (
            bounding.top < 0 ||
            bounding.left < 0 ||
            bounding.bottom >
                (window.innerHeight || document.documentElement.clientHeight) ||
            bounding.right >
                (window.innerWidth || document.documentElement.clientWidth)
        ) {
            // if undefined set last attempted Position
            const getPosition =
                newPosition === undefined
                    ? attemptedPositions[attemptedPositions.length - 1]
                    : newPosition;

            handleMouseOut();
            if (newPosition !== undefined) {
                renderTooltip(text, tooltipProps, getPosition);
            }
            return false;
        }

        return true;
    };

    const handleMouseOut = () => {
        removeTooltipElement();
    };

    const handleMouseOver = (ref, text) => {
        const currentTargetRect = ref.currentTarget.getBoundingClientRect();
        const tooltipProps = {
            target: currentTargetRect,
        };

        renderTooltip(text, tooltipProps, false);
    };

    return (
        <StyledTooltip
            ref={elRef}
            onMouseOver={(elRef) => {
                handleMouseOver(elRef, text);
            }}
            onMouseOut={() => {
                handleMouseOut();
            }}
        >
            {children}
        </StyledTooltip>
    );
};

export default Tooltip;
