import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Container } from "../Container";
import { Icon } from "../Icon";
export interface ContainerWithSidebarProps
    extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Children of the main right part of the container
     */
    children: React.ReactNode;
    /**
     * Sidebar of the left part of the container
     */
    sidebar?: React.ReactNode;
    /**
     * Size of the sidebar
     */
    size?: string;
    /**
     * is Sidebar collapsible
     */
    collapsible?: boolean;
    /**
     * is Sidebar collapsible
     */
    collapsed?: boolean;
    /**
     * onChange collapse status
     */
    onChange?: (...args: any) => any;
}

export interface ContainerSidebarProps {
    /**
     * Size of the sidebar
     */
    size?: string;
}

export const StyledContainerWithSidebar = styled.div<ContainerWithSidebarProps>`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: ${({ size }) => `${size} calc(100% - ${size})`};
    transition: grid-template-columns 0.5s;
    @media (max-width: 767px) {
        grid-template-columns: initial;
    }
`;
export const StyledContainer = styled(Container)`
    height: 100%;
    position: relative;
    align-items: inherit;
    justify-content: inherit;
    flex-wrap: wrap;
`;

export const StyledContainerSidebar = styled(
    StyledContainer,
)<ContainerSidebarProps>`
    position: sticky;
    top: 70px;
    width: ${({ size }) => `${size}`};
    height: calc(100vh - 70px);
    @media (max-width: 767px) {
        transition: width 0.5s;
        position: absolute;
        width: ${({ size }) => `${size}`};
        z-index: 1;
    }
`;

export const StyledCollapseIcon1 = styled.div<{ isCollapsed: boolean }>`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: ${({ isCollapsed }) => (isCollapsed ? "initial" : "pointer")};
    width: 25px;
    height: 25px;
    z-index: 2;
    opacity: ${({ isCollapsed }) => (isCollapsed ? "0" : "1")};
    @media (max-width: 767px) {
        transition: 0.5s;
    }
`;
export const StyledCollapseIcon2 = styled.div<{ isCollapsed: boolean }>`
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: ${({ isCollapsed }) => (isCollapsed ? "pointer" : "initial")};
    width: 25px;
    height: 25px;
    z-index: 2;
    opacity: ${({ isCollapsed }) => (isCollapsed ? "1" : "0")};
    @media (max-width: 767px) {
        transition: 0.5s;
    }
`;

const ContainerWithSidebar = ({
    sidebar,
    children,
    size = "260px",
    collapsible = true,
    collapsed = false,
    onChange,
}: ContainerWithSidebarProps) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsed);

    useEffect(() => {
        setIsCollapsed(collapsed);
    }, [collapsed]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [manuallySet, setManuallySet] = useState(false);
    const updateWindowSizeState = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWindowSizeState);
        if (!manuallySet) {
            if (!size.includes("%") && parseInt(size) / windowWidth > 0.3) {
                setIsCollapsed(true);
            } else {
                setIsCollapsed(false);
            }
        }

        return () => {
            window.removeEventListener("resize", updateWindowSizeState);
        };
    }, []);

    useEffect(() => {
        if (!manuallySet) {
            if (!size.includes("%") && parseInt(size) / windowWidth > 0.3) {
                setIsCollapsed(true);
            } else {
                setIsCollapsed(false);
            }
        }
    }, [windowWidth, windowHeight]);

    useEffect(() => {
        setIsCollapsed(isCollapsed);
        handleOnchage(isCollapsed);
    }, [isCollapsed]);

    const handleOnchage = (isCollapsed: boolean) => {
        if (!onChange) {
            return false;
        }
        onChange(isCollapsed);
    };

    return (
        <StyledContainerWithSidebar
            className="h-sidebar"
            size={isCollapsed ? "0px" : size}
        >
            <StyledContainerSidebar
                color="grey300"
                size={isCollapsed ? "0px" : size}
            >
                {sidebar}
                {collapsible && (
                    <StyledCollapseIcon1
                        isCollapsed={isCollapsed}
                        onClick={() => {
                            setIsCollapsed(true);
                            setManuallySet(true);
                        }}
                    >
                        <Icon name={"ArrowLeftIcon"} size="24px" />
                    </StyledCollapseIcon1>
                )}
            </StyledContainerSidebar>
            <StyledContainer color="grey200">
                {children}
                {collapsible && (
                    <StyledCollapseIcon2
                        isCollapsed={isCollapsed}
                        onClick={() => {
                            setManuallySet(true);
                            setIsCollapsed(false);
                        }}
                    >
                        <Icon name={"ArrowRightIcon"} size="24px" />
                    </StyledCollapseIcon2>
                )}
            </StyledContainer>
        </StyledContainerWithSidebar>
    );
};

export default ContainerWithSidebar;
