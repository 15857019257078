import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    /* Box sizing rules */
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    /* Remove default padding */
    ul[class],
    ol[class] {
        padding: 0;
    }

    /* Remove default margin */
    body,
    h1,
    h2,
    h3,
    h4,
    p,
    ul[class],
    ol[class],
    li,
    figure,
    figcaption,
    blockquote,
    dl,
    dd {
        margin: 0;
    }

    html {
      font-family: ${({ theme }) => theme.typography.fontFamily.default};
    }

    /* Set core body defaults */
    body {
        min-height: 100vh;
        scroll-behavior: smooth;
        text-rendering: optimizeSpeed;
        line-height: 1.5;
    }

    #root {
        min-height: 100vh;
    }

    /* Remove list styles on ul, ol elements with a class attribute */
    ul[class],
    ol[class] {
        list-style: none;
    }

    /* A elements that don't have a class get default styles */
    a:not([class]) {
        text-decoration-skip-ink: auto;
    }

    /* Make images easier to work with */
    img {
        max-width: 100%;
        display: block;
    }

    /* Inherit fonts for inputs and buttons */
    input,
    button,
    textarea,
    select {
        font: inherit;
    }

    /* Remove all animations and transitions for people that prefer not to see them */
    @media (prefers-reduced-motion: reduce) {
        * {
            animation-duration: 0.01ms !important;
            animation-iteration-count: 1 !important;
            transition-duration: 0.01ms !important;
            scroll-behavior: auto !important;
        }
    }


    // Global Style for components
    .tooltip{
        background-color: ${({ theme }) => theme.colors.white};
        padding: 5px 10px;
        color: ${({ theme }) => theme.colors.grey800};
        border-radius: ${({ theme }) => theme.border.radius};
        box-shadow: 0 1px 5px rgba(0,0,0,.15);
        z-index: 100;
        &:after {
            content: "▲";
            top: -14px;
            left: -10px;
            right: 0;
            width: 10px;
            height: 10px;
            position: absolute;
            margin: auto;
            display: inline-block;
            transform: scale(.6, .3);
            color: #fff;
            text-shadow: 0 -5px 2px rgba(255,255,255,0.7), 0 -3px 4px rgba(0,0,0,.15);
            font-size: 32px;
        }
        &.top {
            &:after{
                top: initial;
                bottom: -1px;
                transform: scale(.6, .3) rotate(60deg);
                left: 15px;
                text-shadow: 5px 3px 4px rgba(0,0,0,.15);
            }
        }
        &.right {
            &:after{
                top: 2px;
                bottom: initial;
                right: initial;
                left: -7px;
                transform: scale(.4) rotate(27deg);
                text-shadow: -5px 4px 5px rgba(0,0,0,.15);
            }
        }
        &.left {
            &:after{
                top: 6px;
                bottom: initial;
                left: initial;
                right: 1px;
                transform: scale(.4) rotate(-27deg);
                text-shadow: 3px 4px 5px rgba(0,0,0,.15);
            }
        }
    }

    .select-input{
        z-index: 1;
        position: relative;
        ul{
            min-width: 200px;
            background-color: ${({ theme }) => theme.colors.white};
            border-radius: 4px;
            box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05);
            max-height: 300px;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 10px;
            background-color: ${({ theme }) => theme.colors.white};
            z-index: 1;
        }
        ul li{
            display: flex;
            border-bottom: 1px solid  ${({ theme }) => theme.border.color};
            padding: 8px 10px;
            color: ${({ theme }) => theme.colors.grey800};
            &:hover{
                color: ${({ theme }) => theme.colors.primary};
                cursor: pointer;
            }
            &:last-child{
                border-bottom: none;
            }
        }
        #select-overlay{
            background-color: rgba(150,250,350,.2);
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 0;
        }
    }
`;

export default GlobalStyles;
