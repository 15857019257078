import React from "react";

import styled from "styled-components";
import { Color, Theme } from "../../theme/theme.model";
import containerBackgroundCorner from "./containerBackgroundCorner.png";

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Show the image in the bottom left corner
     */
    image?: boolean;
    /**
     * Show the image in the bottom left corner
     */
    color?: Color;
    children: React.ReactNode;
}

interface StyledContainerProps extends ContainerProps {
    theme: Theme;
}

export const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-image: ${({ image }: StyledContainerProps) =>
        image ? ` url(${containerBackgroundCorner})` : "none"};
    background-size: 400px;
    background-color: ${({ color, theme }: StyledContainerProps) =>
        theme.colors[color] ?? theme.colors.grey200};
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = (props: ContainerProps) => {
    return <StyledContainer {...props} />;
};

export default Container;
