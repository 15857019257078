import React from "react";
import styled from "styled-components";
import { Icon } from "../../../components/Icon";
export interface ClearInputButtonProps {
    onClick: () => void;
}

const StyledIcon = styled.button<ClearInputButtonProps>`
    cursor: pointer;
    background: none;
    border: 0;
    padding: 0;
    outline: 0;
`;

const ClearInputButton = (props: ClearInputButtonProps) => (
    <StyledIcon
        aria-label="clear"
        className="icon-clear"
        onClick={props.onClick}
    >
        <Icon name={"CloseIcon"} size="15px" color="grey800" />
    </StyledIcon>
);

export default ClearInputButton;
