import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Theme, Color } from "../../theme/theme.model";
import { color } from "../Icons/Icons.stories";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    color?: Color;
    fontSize?: number;
    textAlign?: "left" | "center" | "right";
}

export interface StyledInputProps extends InputProps {
    theme: Theme;
}

const StyledInputContainer = styled.span`
    position: relative;
    display: inline-flex;
`;

const StyledInput = styled.input`
    width: 100%;
    background: transparent;

    padding: 5px;
    font-size: ${({ fontSize }: StyledInputProps) => `${fontSize ?? 14}px`};
    border: 1px dashed transparent;
    color: ${({ theme, color }: StyledInputProps) =>
        theme.colors[color] ?? theme.colors.grey800};
    font-weight: 700;
    touch-action: manipulation;
    text-overflow: ellipsis;
    border-radius: 4px;
    transition: all 0.3s;
    font-variant: tabular-nums;
    outline: none;
    text-align: ${({ textAlign }: StyledInputProps) => textAlign};

    &:hover,
    &:focus {
        border-color: ${({ theme }: StyledInputProps) => theme.colors.grey600};
    }

    &[disabled] {
        opacity: 0.5;
        user-select: none;

        &:hover {
            cursor: not-allowed;
        }
        &::selection {
            background: transparent;
            color: ${({ theme }: StyledInputProps) => theme.colors.grey800};
        }
        &::-moz-selection {
            background: transparent;
            color: ${({ theme }: StyledInputProps) => theme.colors.grey800};
        }
    }
`;

const InlineEditor = ({
    value = "",
    onChange,
    textAlign = "left",
    ...otherProps
}: InputProps) => {
    const [currentVal, setCurrentVal] = useState(value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentVal(event.target.value);
        onChange?.(event);
    };

    useEffect(() => setCurrentVal(value), [value]);

    return (
        <StyledInputContainer>
            <StyledInput
                value={currentVal}
                onChange={handleChange}
                textAlign={textAlign}
                {...otherProps}
            />
        </StyledInputContainer>
    );
};

export default InlineEditor;
