import React from "react";
import styled from "styled-components";

import { IconName, IconProps, iconsMap, StyledSvg } from "../Icons";
import Spinner from "../Spinner/Spinner";
import { Tooltip, TooltipProps } from "../Tooltip/Tooltip";
import { Color, Theme } from "../../theme/theme.model";

export interface badgeProps {
    status?: "alert" | "warning" | "info";
    number?: number;
}
export interface IconButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    size?: IconButtonSize;
    loading?: boolean;
    color?: string;
    label?: string;
    /**
     * @default circle
     */
    variant?: "circle" | "square" | "mini";
    selected?: boolean;
    disabled?: boolean;
    name?: IconName;
    iconProps?: IconProps;
    tooltipProps?: TooltipProps;
    badge?: badgeProps;
}

const IconButton = ({
    color = "primary",
    loading,
    size = "medium",
    selected = false,
    iconProps,
    tooltipProps,
    children,
    name,
    variant = "circle",
    label,
    badge,
    ...otherProps
}: IconButtonProps) => {
    let content: React.ReactNode;
    if (children) {
        const childrenWithProps = React.Children.map(children, (child) => {
            const childProps = {
                color,
            };

            if (React.isValidElement(child)) {
                return React.cloneElement(child, childProps);
            }
            return child;
        });
        content = childrenWithProps;
    } else {
        const Component = iconsMap[name];
        content = <Component {...iconProps} />;
    }

    const renderTooltip = (iconButton) => {
        if (tooltipProps?.text === undefined) {
            return iconButton;
        }
        return <Tooltip {...tooltipProps}>{iconButton}</Tooltip>;
    };

    const getStatusColor = (status) => {
        let color = "blue";
        switch (status) {
            case "alert":
                color = "red";
                break;
            case "warning":
                color = "yellow";
                break;
            case "info":
                color = "blue";
                break;
        }
        return color;
    };

    const renderIconButton = (
        <StyledIconButton
            color={color}
            size={size}
            selected={selected}
            variant={variant}
            {...otherProps}
        >
            {badge?.status && (
                <StyledBadge color={getStatusColor(badge.status)}>
                    {badge?.number}
                </StyledBadge>
            )}
            {loading ? <Spinner /> : content}
            {label ? <p>{label}</p> : ""}
        </StyledIconButton>
    );

    const renderComponent = () => {
        return renderTooltip(renderIconButton);
    };

    return renderComponent();
};

type IconButtonSize = "mini" | "small" | "medium" | "large";

const iconButtonSizes: { [key in IconButtonSize]: number } = {
    mini: 25,
    small: 34,
    medium: 37,
    large: 50,
};

const StyledBadge = styled.span<badgeProps>`
    border-radius: 50%;
    position: absolute;
    min-width: 17px;
    height: 17px;
    top: -5px;
    right: -5px;
    z-index: 1;
    font-size: 10px;
    line-height: 17px;
    display: flex;
    align-content: center;
    text-align: center;
    justify-content: center;
    color: #fff;
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    padding-left: 3px;
    padding-right: 3px;
    background-color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.red};
`;

const StyledIconButton = styled.button<IconButtonProps>`
    position: relative;
    ${({ variant, size, theme, selected, color }) => `
        ${
            variant === "circle"
                ? `
            height: ${iconButtonSizes[size]}px;
            width: ${iconButtonSizes[size]}px;
            max-height: ${iconButtonSizes[size]}px;
            min-height: ${iconButtonSizes[size]}px;
            max-width: ${iconButtonSizes[size]}px;
            min-width: ${iconButtonSizes[size]}px;

            padding: 0;
            border-radius: 50%;
            line-height: ${iconButtonSizes[size]}px;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: auto;
            cursor: pointer;
            border: none;
            outline: none;
            color: ${selected ? theme.colors.white : theme.colors.grey800};
            background-color: ${
                selected ? theme.colors.primary : theme.colors.grey400
            };
            ${StyledSvg} {
                path,
                polygon,
                circle,
                rect {
                    fill: ${
                        selected ? theme.colors.white : theme.colors.grey800
                    };
                }
            }

            svg {
                width: 18px;
            }

            &:focus,
            &:hover {
                color: ${theme.colors.primary};
                background-color: ${
                    selected ? theme.colors.primary : theme.colors.grey400
                };

                ${StyledSvg} {
                    path,
                    polygon,
                    circle,
                    rect {
                        fill: ${
                            selected ? theme.colors.white : theme.colors[color]
                        };
                    }
                }
            }

            &[disabled],
            &[disabled]:hover,
            &[disabled]:focus,
            &[disabled]:active,
            &[disabled].active {
                color: ${theme.colors.grey200};
                background-color: ${
                    color ? theme.colors[color + "Hover"] : theme.colors.grey800
                };
                cursor: not-allowed;
                opacity: 0.5;

                svg {
                    fill: ${theme.colors.grey200};
                }
            }
        `
                : ``
        }

        ${
            variant === "square"
                ? `
                background-color: transparent;
                border: none;
                outline: none;
                border: 2px solid transparent;
                padding: 5px;
                max-width: 90px;
                min-width: 90px;
                max-height: 90px;
                width: 90px;
                height: 90px;
                &:hover {
                    cursor: pointer;
                    svg {
                        rect,
                        polygon,
                        circle,
                        path {
                            fill: ${theme.colors[color]};
                        }
                    }
                    p {
                        color: ${theme.colors[color]};
                    }
                }

                > svg,
                > img {
                    width: 40px;
                    max-width: 40px;
                    height: 40px;
                    max-height: 40px;
                    &:not(:only-child){
                        margin-bottom: 5px;
                    }
                }

                > svg {
                    rect,
                    polygon,
                    circle,
                    path {
                        fill: ${
                            selected
                                ? theme.colors[color]
                                : theme.colors.grey700
                        };
                    }
                }
                
                > p {
                    font-size: ${theme.typography.text.small};
                    color: ${
                        selected ? theme.colors[color] : theme.colors.grey700
                    };
                    max-width: 80px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                &:disabled {
                    cursor: not-allowed;
                    svg {
                        rect,
                        polygon,
                        circle,
                        path {
                            fill: ${
                                selected
                                    ? theme.colors[color]
                                    : theme.colors.grey300
                            };
                        }
                    }
                    p {
                        color: ${
                            selected
                                ? theme.colors[color]
                                : theme.colors.grey300
                        };
                    }
                }
            `
                : ``
        }

        ${
            variant === "mini"
                ? `
                background-color: ${
                    selected ? theme.colors[color] : theme.colors.white
                };
                border: none;
                outline: none;
                border: 2px solid ${theme.border.color};
                border-color: ${
                    selected ? theme.colors[color] : theme.border.color
                };
                border-radius: ${theme.border.radius};
                padding: 0;
                max-width: 20px;
                min-width: 20px;
                max-height: 20px;
                width: 20px;
                height: 20px;
                line-height: 10px;
                transition: 0.3s;

                &:hover {
                    cursor: pointer;
                    border-color: ${theme.colors[color]};
                    > svg {
                        polygon,
                        rect,
                        circle,
                        path {
                            fill: ${
                                selected
                                    ? theme.colors.white
                                    : theme.colors[color]
                            };
                        }
                    }
                }
                > svg {
                    width: 10px;
                    height: 10px;
                    polygon,
                    rect,
                    circle,
                    path {
                        fill: ${
                            selected ? theme.colors.white : theme.colors.grey700
                        };
                    }
                }
                &:disabled {
                    cursor: not-allowed;
                    svg {
                        rect,
                        polygon,
                        circle,
                        path {
                            fill: ${
                                selected
                                    ? theme.colors.white
                                    : theme.colors.grey700
                            };
                        }
                    }
                    &:hover {
                        border-color: ${
                            selected ? theme.colors[color] : theme.border.color
                        };
                    }
                }
            `
                : ``
        }
    `}
`;

export default IconButton;
