import React from "react";
import Spinner from "../Spinner/Spinner";

import styled from "styled-components";
import { Color, Theme } from "../../theme/theme.model";

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
    color?: Color;
    children: React.ReactNode;
}

interface StyledButtonProps extends ButtonProps {
    theme: Theme;
}

export const StyledButton = styled.button`
    & {
        min-width: ${({ type }: StyledButtonProps) =>
            !type ? "90px" : "default"};
        background-color: ${({ color, theme }: StyledButtonProps) =>
            color ? theme.colors[color] : theme.colors.secondary};
        color: ${({ theme }: StyledButtonProps) => theme.colors.grey200};
        border-radius: 4px;
        font-weight: bold;
        border: 0;
        height: ${({ theme }: StyledButtonProps) =>
            theme.size.form.button + "px"};
        padding: 2px 15px;
        align-items: center;
        display: flex;
        outline: none;
        opacity: ${({ loading }: StyledButtonProps) =>
            loading ? 0.7 : "initial"};
        transition: 0.3s;
        justify-content: center;
        > div {
            width: auto;
            &:not(:only-child) {
                margin-right: 8px;
            }
        }
        > .loading {
            opacity: 0.8;
        }
        &:focus,
        &:hover {
            background-color: ${({ color, theme }: StyledButtonProps) =>
                color ? theme.colors[color] : theme.colors.secondary};
            color: ${({ theme }: StyledButtonProps) => theme.colors.grey200};
            cursor: ${({ loading }: StyledButtonProps) =>
                loading ? "not-allowed" : "pointer"};
            filter: brightness(120%);
        }
        svg {
            height: 100%;
            width: auto;
        }

        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active,
        &[disabled].active {
            color: ${({ theme }: StyledButtonProps) => theme.colors.grey200};
            cursor: not-allowed;
            opacity: 0.7;
        }
    }
`;

const Button = ({ color, loading, children, ...otherProps }: ButtonProps) => (
    <StyledButton color={color} {...otherProps} loading={loading}>
        {loading ? (
            <>
                <Spinner maxSize="25px" align="center" />
                <span className="loading">{children}</span>
            </>
        ) : (
            children
        )}
    </StyledButton>
);

export default Button;
