import React, { useState, useEffect } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import merge from "../utils/merge";
import { Theme } from "./theme.model";
import GlobalStyles from "./GlobalStyles";
import defaultTheme from "./defaultTheme";

export interface ThemeProviderProps {
    theme?: Theme;
    children?: any;
}

export const ThemeProvider = ({ theme, children }: ThemeProviderProps) => {
    const [activeTheme, setActiveTheme] = useState<Theme>(defaultTheme);

    useEffect(() => {
        if (theme) {
            const newTheme = merge(defaultTheme, theme) as Theme;
            setActiveTheme(newTheme);
        } else {
            setActiveTheme(defaultTheme);
        }
    }, [theme]);

    return (
        <StyledThemeProvider theme={activeTheme}>
            <GlobalStyles />
            {children}
        </StyledThemeProvider>
    );
};

export default ThemeProvider;
