import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import styled from "styled-components";

import { ArrowTop, ArrowBottom } from "../Icons";
import ClearInputButton from "./components/ClearInputButton";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    clearableInput?: boolean;
    endAdornment?: JSX.Element | undefined;
    startAdornment?: JSX.Element | undefined;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    toFixed?: number;
}
export interface InputContainerProps
    extends React.HTMLAttributes<HTMLDivElement> {
    focus?: boolean;
}

const Input = ({
    value = "",
    onChange,
    onFocus,
    onBlur,
    clearableInput = false,
    endAdornment,
    startAdornment,
    className = "",
    placeholder,
    toFixed = 2,
    inputProps,
    ...otherProps
}: InputProps) => {
    const [currentVal, setCurrentVal] = useState(value);
    const [isFocus, setIsFocus] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        setIsFocus(isFocus);
    }, [isFocus]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentVal(event.target.value);
        onChange?.(event);
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocus(true);
        onFocus?.(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocus(false);
        onBlur?.(event);
    };

    const setFocus = () => {
        inputRef.current.focus();
    };

    const handleClear = () => {
        const fakeEvent = {
            target: {
                value: "",
            },
        };

        handleChange(fakeEvent as ChangeEvent<HTMLInputElement>);
    };

    const ClearInput = () => {
        if (!clearableInput || !value) {
            return null;
        }

        return <ClearInputButton onClick={handleClear} />;
    };

    const increase = () => {
        const { step } = otherProps;
        const inputStep = Number(step);
        const InputValue = Number(inputRef.current.value);
        const total = (InputValue + inputStep).toFixed(toFixed);
        setCurrentVal(total);
    };

    const decrease = () => {
        const { step } = otherProps;
        const inputStep = Number(step);
        const InputValue = Number(inputRef.current.value);
        const total = (InputValue - inputStep).toFixed(toFixed);
        setCurrentVal(total);
    };

    useEffect(() => {
        setCurrentVal(value);
    }, [value]);

    const inputContainerClasses = [
        typeof endAdornment === "object" && "h-endAdornment",
        typeof startAdornment === "object" && "h-startAdornment",
        placeholder && "h-placeholder",
        "h-input",
    ]
        .filter(Boolean)
        .join(" ");

    const classes = [className, isFocus && "h-focus"].filter(Boolean).join(" ");

    return (
        <StyledInputMainContainer focus={isFocus} className={`${classes}`}>
            {startAdornment && (
                <StyledStartAdornment>{startAdornment}</StyledStartAdornment>
            )}
            <StyledInputContainer className={`${inputContainerClasses}`}>
                <StyledInput
                    ref={inputRef}
                    value={currentVal}
                    {...otherProps}
                    {...inputProps}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {otherProps?.type === "number" && (
                    <StyledNumberBtnContainer>
                        <StyledNumberBtn type="button" onClick={increase}>
                            <ArrowTop />
                        </StyledNumberBtn>
                        <StyledNumberBtn type="button" onClick={decrease}>
                            <ArrowBottom />
                        </StyledNumberBtn>
                    </StyledNumberBtnContainer>
                )}
                {placeholder && (
                    <StyledLabel onClick={setFocus}>{placeholder}</StyledLabel>
                )}
                <ClearInput />
            </StyledInputContainer>
            {endAdornment && (
                <StyledEndAdornment>{endAdornment}</StyledEndAdornment>
            )}
        </StyledInputMainContainer>
    );
};

const StyledNumberBtnContainer = styled.div`
    position: absolute;
    right: 10px;
    &:after {
        height: 1px;
        width: 25px;
        background-color: ${({ theme }) => theme.colors.grey500};
        opacity: 0.4;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        content: "";
        right: 0;
        left: 0;
    }
    button {
        &:first-child {
            align-items: flex-end;
            &:hover {
                align-items: center;
            }
        }
        &:last-child {
            align-items: flex-start;
            &:hover {
                align-items: center;
            }
        }
    }
`;
const StyledNumberBtn = styled.button`
    height: 25px;
    width: auto;
    display: flex;
    border: none;
    background-color: transparent;
    transform: scale(0.8);
    transition: 0.1s;
    &:hover {
        cursor: pointer;
        transform: scale(1);
    }
    svg {
        height: 20px;
        width: auto;
    }
`;

const StyledInputMainContainer = styled.div<InputContainerProps>`
    position: relative;
    display: flex;
    background-color: ${({ theme }) => theme.colors.grey400};
    border-radius: ${({ theme }) => theme.border.radius};
    transition: all 0.3s;
    width: 100%;
    &:after {
        height: 5px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: transparent;
        content: "";
        transition: all 0.3s;
        border-radius: ${({ theme }) => theme.border.radius};
        border-bottom: 2px solid transparent;
    }
    &:hover {
        &:after {
            border-color: ${({ theme }) => theme.colors.primary};
        }
    }

    &.h-focus {
        &:after {
            border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
        }
    }
    ${(props) =>
        props.focus === true &&
        `
        &::after{
            border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
        }
    `}
`;
const StyledInputContainer = styled.span`
    position: relative;
    width: 100%;
    display: inline-flex;

    .icon-clear {
        position: absolute;
        display: flex;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        align-items: center;
        width: 37px;
        height: 37px;
        justify-content: center;
    }

    &.h-endAdornment {
        &:after {
            background-color: ${({ theme }) => theme.colors.grey500};
            content: "";
            width: 1px;
            z-index: 1;
            height: 30px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 0;
            opacity: 0.4;
        }
    }
    &.h-startAdornment {
        &:before {
            background-color: ${({ theme }) => theme.colors.grey500};
            content: "";
            width: 1px;
            z-index: 1;
            height: 30px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
            opacity: 0.4;
        }
    }
    &:not(.h-placeholder) {
        input {
            padding-top: 5px;
        }
    }
`;

export const StyledLabel = styled.label`
    font-size: ${({ theme }) => theme.typography.text.normal};
    margin: auto auto 5px auto;
    position: absolute;
    line-height: 40px;
    left: 15px;
    top: 5px;
    transition-timing-function: ease-in;
    transition-duration: 0.125s;
    text-shadow: 0px 1px 0px ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.grey800};
    font-weight: ${({ theme }) => theme.typography.weight.bold};

    &:hover {
        + input {
            border-color: transparent;
            border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
        }
    }

    &.no-empty {
        opacity: 0 !important;
    }
`;

const StyledInput = styled.input`
    width: 100%;
    border: 0;
    margin: 0;
    border-bottom: 2px solid transparent;
    background-color: ${({ theme }) => theme.colors.grey400};
    font-weight: bold;
    font-size: ${({ theme }) => theme.typography.text.normal};
    min-height: ${({ theme }) => theme.size.form.input}px;
    max-height: ${({ theme }) => theme.size.form.input}px;
    height: ${({ theme }) => theme.size.form.input}px;
    min-width: 100%;
    max-width: 100%;
    border-radius: ${({ theme }) => theme.border.radius};
    color: ${({ theme }) => theme.colors.grey900};
    padding: 22px 15px 5px 15px;
    outline: none;
    border-bottom: 2px solid transparent;
    transition: all 0.3s;
    font-variant: tabular-nums;
    text-overflow: ellipsis;
    &[value=""]:not(:disabled) {
        & + label,
        & + span {
            opacity: 1;
            cursor: text;
        }
    }
    &:focus {
        border-color: transparent;
        /* border-bottom: 2px solid ${({ theme }) => theme.colors.primary}; */
        & + label {
            top: 6px;
            line-height: 20px;
            cursor: default;
            font-size: ${({ theme }) => theme.typography.text.small};
        }
    }
    &:hover:not(:disabled) {
        border-color: transparent;
        /* border-bottom: 2px solid ${({ theme }) => theme.colors.primary}; */
    }
    &:not([value=""]) {
        & + label {
            top: 6px;
            line-height: 20px;
            cursor: default;
            font-size: ${({ theme }) => theme.typography.text.small};
        }
    }

    &:hover:enabled {
        /* border-color: ${({ theme }) => theme.colors.primary}; */
        box-shadow: none;
    }

    &[disabled] {
        opacity: 0.5;
        user-select: none;

        &:hover {
            cursor: not-allowed;
        }
        &::selection {
            background: transparent;
            color: ${({ theme }) => theme.colors.grey800};
        }
        &::-moz-selection {
            background: transparent;
            color: ${({ theme }) => theme.colors.grey800};
        }
    }
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type="number"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }
`;

const StyledEndAdornment = styled.span`
    display: flex;
    align-items: center;
`;

const StyledStartAdornment = styled.span`
    display: flex;
    align-items: center;
    &:empty {
        display: none;
    }
`;

export default Input;
