import React from "react";
import styled from "styled-components";
import { Color } from "../../theme/theme.model";

type TypographyVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";

export interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
    variant?: TypographyVariant;
    component?: React.ReactNode;
    noWrap?: boolean;
    color?: Color;
    as?: any;
}

const Typography = (props: TypographyProps) => {
    const { component, noWrap = false, variant = "p", ...otherProps } = props;
    const Component = component || variant || "span";
    const classes = `
        ${noWrap ? "no-wrap" : ""}
    `.trim();

    return (
        <StyledTypography as={Component} className={classes} {...otherProps} />
    );
};

const StyledTypography = styled.span<TypographyProps>`
    font-size: ${({ theme, as }) =>
        theme.typography.header[as] || theme.typography.text.normal};
    color: ${({ theme, color }) => theme.colors[color] || theme.colors.grey800};

    &.no-wrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export default Typography;
