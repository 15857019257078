import React from "react";

import styled from "styled-components";

import loadingImgV1 from "./spinner.png";
import { Theme } from "../../theme/theme.model";

export interface SpinnerProps {
    /**
     * Align the spinner in respect of the container
     */
    align?: "left" | "center" | "right";
    /**
     * Set max size of the spinner, can be px or %
     */
    maxSize?: string;
    /**
     * Set loading percentage of the spinner
     */
    percentage?: number;
}

interface StyledSpinnerProps extends SpinnerProps {
    theme?: Theme;
}

const StyledNewSpinner = styled.div`
    display: flex;
    justify-content: ${({ align }: StyledSpinnerProps) => {
        switch (align) {
            case "left":
                return "flex-start";
            case "right":
                return "flex-end";
            case "center":
            default:
                return "center";
        }
    }};
    align-items: center;
    position: relative;
    bottom: none;
    left: none;

    width: 100%;
    height: 100%;

    z-index: 998;
    background-color: none;
    transition: background-color 0.3s ease-in-out;
    > div {
        width: ${({ maxSize }: StyledSpinnerProps) => maxSize};
        max-width: 100%;
        height: ${({ maxSize }: StyledSpinnerProps) => maxSize};
        max-height: 100%;
        img {
            z-index: 10;
            max-width: 100%;
            max-height: 100%;
        }
    }
`;

const StyledPercentageContainer = styled.div`
    & {
        position: relative;
        text-align: center;
        .color-group {
            path {
                transition: 0.2s;
                opacity: 0;
            }
        }
        > div {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            text-align: center;
            height: 100px;
            width: 100px;
            svg {
                position: absolute;
            }
            span {
                width: 100%;
                p {
                    font-size: "60%";
                    font-weight: ${({ theme }: StyledSpinnerProps) =>
                        theme.typography.weight.bold};
                    line-height: ${({ maxSize }: StyledSpinnerProps) =>
                        maxSize};

                    text-align: center;
                    margin: auto;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
`;

const Spinner = ({ align, maxSize = "50px", percentage }: SpinnerProps) => {
    return (
        <StyledNewSpinner maxSize={maxSize} align={align}>
            {percentage ? (
                <StyledPercentageContainer>
                    <div>
                        <svg
                            style={{ width: maxSize, height: maxSize }}
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 178.3 178.3"
                        >
                            <g id="color-circle">
                                <g id="grey-group">
                                    <path
                                        style={{ fill: "#E5E4E4" }}
                                        d="M149.5,23.3C134.5,9.5,114.7,0.9,93,0v14.8c17.6,1,33.6,8.1,45.9,19L149.5,23.3z"
                                    />
                                    <path
                                        style={{ fill: "#E5E4E4" }}
                                        d="M28.9,154.9c14.9,13.8,34.7,22.4,56.4,23.4v-14.9c-17.6-0.9-33.6-7.9-45.9-18.9L28.9,154.9z"
                                    />
                                    <path
                                        style={{ fill: "#E5E4E4" }}
                                        d="M33.8,39.4L23.3,28.9C9.7,43.8,0.9,63.4,0,85.1h14.9C15.8,67.5,22.9,51.6,33.8,39.4z"
                                    />
                                    <path
                                        style={{ fill: "#E5E4E4" }}
                                        d="M85.3,14.8V0C63.6,0.9,43.8,9.5,28.9,23.3l10.5,10.5C51.7,22.9,67.7,15.8,85.3,14.8z"
                                    />
                                    <path
                                        style={{ fill: "#E5E4E4" }}
                                        d="M14.9,93H0c0.9,21.7,9.7,41.3,23.3,56.4l10.5-10.5C22.9,126.6,15.8,110.6,14.9,93z"
                                    />
                                    <path
                                        style={{ fill: "#E5E4E4" }}
                                        d="M93,163.4v14.9c21.7-1,41.5-9.7,56.4-23.4l-10.5-10.4C126.6,155.5,110.6,162.5,93,163.4z"
                                    />
                                    <path
                                        style={{ fill: "#E5E4E4" }}
                                        d="M163.4,93c-0.9,17.6-7.9,33.6-18.9,45.9l10.5,10.5c13.6-15.1,22.3-34.7,23.3-56.4H163.4z"
                                    />
                                    <path
                                        style={{ fill: "#E5E4E4" }}
                                        d="M163.4,85.1h14.9c-0.9-20.2-8.6-40.3-23.3-56.3l-10.6,10.6C156.3,52.5,162.6,68.8,163.4,85.1z"
                                    />
                                </g>
                                <g id="color-group">
                                    <path
                                        id="v-8"
                                        style={{
                                            fill: "#6F236E",
                                            opacity: percentage >= 100 ? 1 : 0,
                                        }}
                                        d="M85.3,14.8V0C63.6,0.9,43.8,9.5,28.9,23.3l10.5,10.5C51.7,22.9,67.7,15.8,85.3,14.8z"
                                    />
                                    <path
                                        id="v-7"
                                        style={{
                                            fill: "#6F236E",
                                            opacity: percentage >= 87.5 ? 1 : 0,
                                        }}
                                        d="M33.8,39.4L23.3,28.9C9.7,43.8,0.9,63.4,0,85.1h14.9C15.8,67.5,22.9,51.6,33.8,39.4z"
                                    />
                                    <path
                                        id="v-6"
                                        style={{
                                            fill: "#6F236E",
                                            opacity: percentage >= 75 ? 1 : 0,
                                        }}
                                        d="M14.9,93H0c0.9,21.7,9.7,41.3,23.3,56.4l10.5-10.5C22.9,126.6,15.8,110.6,14.9,93z"
                                    />
                                    <path
                                        id="b-5"
                                        style={{
                                            fill: "#2A526D",
                                            opacity: percentage >= 62.5 ? 1 : 0,
                                        }}
                                        d="M28.9,154.9c14.9,13.8,34.7,22.4,56.4,23.4v-14.9c-17.6-0.9-33.6-7.9-45.9-18.9L28.9,154.9z"
                                    />
                                    <path
                                        id="b-4"
                                        style={{
                                            fill: "#2A526D",
                                            opacity: percentage >= 50 ? 1 : 0,
                                        }}
                                        d="M93,163.4v14.9c21.7-1,41.5-9.7,56.4-23.4l-10.5-10.4C126.6,155.5,110.6,162.5,93,163.4z"
                                    />
                                    <path
                                        id="g-3"
                                        style={{
                                            fill: "#91A436",
                                            opacity: percentage >= 37.5 ? 1 : 0,
                                        }}
                                        d="M163.4,93c-0.9,17.6-7.9,33.6-18.9,45.9l10.5,10.5c13.6-15.1,22.3-34.7,23.3-56.4H163.4z"
                                    />
                                    <path
                                        id="g-2"
                                        style={{
                                            fill: "#91A436",
                                            opacity: percentage >= 25 ? 1 : 0,
                                        }}
                                        d="M163.4,85.1h14.9c-0.9-20.2-8.6-40.3-23.3-56.3l-10.6,10.6C156.3,52.5,162.6,68.8,163.4,85.1z"
                                    />
                                    <path
                                        id="g-1"
                                        style={{
                                            fill: "#91A436",
                                            opacity: percentage >= 12.5 ? 1 : 0,
                                        }}
                                        d="M149.5,23.3C134.5,9.5,114.7,0.9,93,0v14.8c17.6,1,33.6,8.1,45.9,19L149.5,23.3z"
                                    />
                                </g>
                            </g>
                            <g id="circle-center">
                                <path
                                    style={{ fill: "#E5E4E4" }}
                                    d="M153,89.1c0-35.2-28.6-63.7-63.9-63.7c-35.2,0-63.7,28.6-63.7,63.7c0,35.3,28.6,63.9,63.7,63.9
                                                C124.4,153,153,124.4,153,89.1z"
                                />
                            </g>
                        </svg>
                        <span>
                            <p>{percentage}%</p>
                        </span>
                    </div>
                </StyledPercentageContainer>
            ) : (
                <div>
                    <img src={loadingImgV1} alt="Please wait..." />
                </div>
            )}
        </StyledNewSpinner>
    );
};

export default Spinner;
