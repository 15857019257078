import React, { useState, useEffect } from "react";

import {
    StyledCheckboxContainer,
    StyledInput,
    StyledLabel,
    StyledIconContainer,
} from "./CheckboxStyles";
import { Color } from "../../theme/theme.model";

export interface CheckboxProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    /**
     * Specifies the initial state: whether or not the checkbox is selected
     * @default false
     */
    defaultChecked?: boolean;
    /**
     * Checkbox label, that come on the right | left side
     */
    label?: string;
    /**
     * Background color on checked. Choose one of the theme color
     * @default primary
     */
    color?: Color;
    /**
     * If *id* is empty default will be a random number
     */
    id?: string;
    /**
     * Title position left or right of checkbox
     * @default right
     */
    align?: "left" | "right";
    /**
     * Variant to change component view
     * @default default
     */
    variant?: "default" | "small" | "medium";
    /**
     * css className
     */
    classNames?: string;
    /**
     * Attributes applied to the input element.
     */
    inputProps?: any;
    /**
     * The icon name.
     */
    icon?: React.ReactNode;
    /**
     * The checked icon name.
     */
    checkedIcon?: React.ReactNode;
}

/**
 * @component Checkbox
 */
const Checkbox = (props: CheckboxProps) => {
    const {
        onChange,
        defaultChecked = false,
        disabled = false,
        color = "primary",
        align = "right",
        classNames = "",
        id,
        inputProps,
        icon = false,
        checkedIcon = false,
        variant = "small",
        label,
        checked,
        value,
    } = props;

    const initialCheckedValue =
        typeof defaultChecked !== "undefined"
            ? defaultChecked
            : typeof checked !== "undefined"
            ? checked
            : false;

    const [checkboxState, setCheckboxState] = useState<boolean>(
        initialCheckedValue,
    );

    useEffect(() => {
        setCheckboxState(checked);
    }, [checked]);

    /**
     * Send event, status and value of the checkbox on change
     */
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
            return;
        }

        setCheckboxState(event.target.checked);
        if (typeof onChange !== "undefined") {
            onChange(event);
        }
        return event;
    };

    // set random number if id not exist
    const inputId =
        typeof id !== "undefined" ? id : Math.floor(Math.random() * 1000);

    return (
        <StyledCheckboxContainer
            icon={icon}
            variant={variant}
            className={`h-checkbox ${classNames}`}
            color={color}
            textDirection={align}
        >
            <StyledInput
                id={`el-${inputId}`}
                type="checkbox"
                checked={checkboxState}
                disabled={disabled}
                onChange={handleOnChange}
                value={value}
                {...inputProps}
            />
            <StyledLabel htmlFor={`el-${inputId}`}>
                {icon && (
                    <StyledIconContainer
                        variant={variant}
                        className="icon-checked"
                    >
                        {checkedIcon}
                        {icon}
                    </StyledIconContainer>
                )}

                {align && align === "left" && <span>{label}</span>}

                {!icon && <span className={"checked"}></span>}

                {((align && align === "right") || align == null) && (
                    <span>{label}</span>
                )}
            </StyledLabel>
        </StyledCheckboxContainer>
    );
};

export default Checkbox;
