import React from "react";
import styled from "styled-components";

import { Color } from "../../theme/theme.model";

export interface IconProps {
    /**
     * Color of the icon
     */
    color?: Color;
    /**
     * Color of the icon on hover
     */
    hoverColor?: Color;
    /**
     * Size of the icon (pixel or percentage)
     */
    size?: string;
}

const easing = "cubic-bezier(.25,1.7,.35,.8)";
const duration = "0.5s";
const bgColor = "#666";

export const StyledSvg = styled.svg<IconProps>`
    & path,
    & polygon,
    & circle,
    & ellipse,
    & rect {
        fill: ${({ color = "grey700", theme }) => theme.colors[color]};
        transition: fill 0.25s;
        stroke: ${({ color = "grey700", theme }) => theme.colors[color]};
    }
    &:hover path,
    &:hover polygon,
    &:hover circle,
    &:hover ellipse,
    &:hover rect {
        fill: ${({ hoverColor = "primary", theme }) =>
            theme.colors[hoverColor]};
        transition: fill 0.25s;
        stroke: ${({ hoverColor = "primary", theme }) =>
            theme.colors[hoverColor]};
    }
    width: ${({ size = "100%" }) => size};
    height: ${({ size = "100%" }) => size};
`;

export const StyledArrowAnimate = styled.i`
    height: 15px;
    width: 33px;
    display: block;
    padding: 0;
    margin: auto;
    position: relative;
    cursor: pointer;
    border-radius: 4px;

    &.arrow-vertical {
        height: 33px;
        width: 15px;
    }

    &:hover {
        .left-bar:after {
            transform: rotate(-30deg);
        }
        .right-bar:after {
            transform: rotate(30deg);
        }
    }

    &.arrow-down,
    &.arrow-left {
        .left-bar:after {
            transform: rotate(18deg);
        }
        .right-bar:after {
            transform: rotate(-18deg);
        }
        &:hover {
            .left-bar:after {
                transform: rotate(30deg);
            }
            .right-bar:after {
                transform: rotate(-30deg);
            }
        }
    }

    .left-bar {
        position: absolute;
        background-color: transparent;
        top: 5.5px;
        left: 0;
        width: 20px;
        height: 4px;
        display: block;
        transform: rotate(0deg);
        float: right;
        border-radius: 2px;
        &:after {
            width: 16px;
            height: 4px;
            float: right;
            display: block;
            content: "";
            background-color: ${bgColor};
            border-radius: 10px;
            transition: all ${duration} ${easing};
            z-index: -1;
            transform-origin: center center;
            transform: rotate(-18deg);
        }
    }

    &.arrow-vertical {
        .left-bar {
            top: 2px;
            left: 0;
            right: 0;
            width: 4px;
            height: 20px;
            float: none;
            margin: auto;
            &:after {
                width: 4px;
                height: 16px;
                float: none;
            }
        }
    }

    .right-bar {
        position: absolute;
        background-color: transparent;
        top: 5.5px;
        left: 13px;
        width: 20px;
        height: 4px;
        display: block;
        transform: rotate(0deg);
        float: right;
        border-radius: 2px;
        &:after {
            display: block;
            width: 16px;
            float: right;
            height: 4px;
            content: "";
            background-color: ${bgColor};
            border-radius: 10px;
            transition: all ${duration} ${easing};
            z-index: -1;
            transform-origin: center center;
            transform: rotate(18deg);
        }
    }

    &.arrow-vertical {
        .right-bar {
            top: 13px;
            right: 0;
            left: 0;
            width: 4px;
            height: 20px;
            margin: auto;

            &:after {
                width: 4px;
                height: 16px;
                float: none;
            }
        }
    }
`;

export const HegiasLogo = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128.58 76"
    >
        <g>
            <polygon points="111.43 60.56 64.19 76 26.64 58.44 67.83 48.45 111.43 60.56" />
        </g>
        <g>
            <polygon points="56.02 56.92 13.63 39.66 0 10.8 49.05 28.56 56.02 56.92" />
        </g>
        <g>
            <polygon points="50.26 26.34 0.3 8.48 66.61 0 128.38 10.29 50.26 26.34" />
        </g>
        <g>
            <polygon points="118.69 40.57 58.54 57.23 51.58 28.46 128.58 12.62 118.69 40.57" />
        </g>
    </StyledSvg>
);

export const LogoVertical = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 212.3 125.5"
    >
        <g>
            <polygon
                style={{ fill: "#C2BFB6" }}
                points="184,100 106,125.5 44,96.5 112,80"
            />
        </g>
        <g>
            <polygon
                style={{ fill: "#54055F" }}
                points="92.5,94 22.5,65.5 0,17.8 81,47.2"
            />
        </g>
        <g>
            <polygon
                style={{ fill: "#7A9723" }}
                points="83,43.5 0.5,14 110,0 212,17"
            />
        </g>
        <g>
            <polygon
                style={{ fill: "#1E3451" }}
                points="196,67 96.7,94.5 85.2,47 212.3,20.8"
            />
        </g>
    </StyledSvg>
);

export const LogoVerticalSlogan = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 212.33 216.93"
    >
        <g>
            <polygon
                points="184 100 106 125.5 44 96.5 112 80 184 100"
                style={{ fill: "#cdcccc" }}
            />
        </g>
        <g>
            <polygon
                points="92.5 94 22.5 65.5 0 17.83 81 47.17 92.5 94"
                style={{ fill: "#6f256f" }}
            />
        </g>
        <g>
            <polygon
                points="83 43.5 0.5 14 110 0 212 17 83 43.5"
                style={{ fill: "#91a436" }}
            />
        </g>
        <g>
            <polygon
                points="196 67 96.67 94.5 85.17 47 212.33 20.83 196 67"
                style={{ fill: "#2a526d" }}
            />
        </g>
        <g>
            <g>
                <path
                    d="M7.46,182.5H2.28V146.69H7.46v14.69a10.19,10.19,0,0,1,4.57-1c2.11,0,8.4,1.05,11.66,1.05a12.61,12.61,0,0,0,4.85-.91V146.69h5.23V182.5H28.54V165.84a12.61,12.61,0,0,1-4.85.91c-3.26,0-9.55-1.05-11.66-1.05a10.19,10.19,0,0,0-4.57,1Z"
                    style={{ fill: "#6c6c6c" }}
                />
                <path
                    d="M51.34,177.27H73.42v5.23H46.1V146.69H71.31V152h-20v10.85a12.83,12.83,0,0,1,6.77-1.39h7.82v5.32H58.11a13.88,13.88,0,0,0-6.77,1.4Z"
                    style={{ fill: "#6c6c6c" }}
                />
                <path
                    d="M110.91,155.23a14.62,14.62,0,0,0-10.47-4.32c-8,0-14.11,5.72-14.11,13.78,0,7.73,6.67,13.39,14.16,13.39a14.36,14.36,0,0,0,9.41-3.45V168.1h-4.61L103,162.91h12v13.93a19.59,19.59,0,0,1-14.64,6.48,19,19,0,0,1-19.25-19.25c0-10.56,9-18.44,19.3-18.44a19.44,19.44,0,0,1,13.78,5.67Z"
                    style={{ fill: "#6c6c6c" }}
                />
                <path
                    d="M125.74,182.5V146.69h5.13V182.5Z"
                    style={{ fill: "#6c6c6c" }}
                />
                <path
                    d="M152.42,146.06h5.76l17.24,36.44h-5.86l-2.35-5.23h-10.9c-6.53,0-10,2.06-10.85,3.55l-.91,1.68h-5.66l15.5-32ZM164.86,172,157,156.87,149.11,174c1.3-1.1,4.23-2,9.12-2Z"
                    style={{ fill: "#6c6c6c" }}
                />
                <path
                    d="M195.77,183.32A19,19,0,0,1,181,176l3.74-3.5a14.17,14.17,0,0,0,11,5.61c5.28,0,10.61-2,10.61-6.48,0-5.47-5.43-5.47-10.61-5.47-8.55,0-13.2-3.17-13.2-9.26,0-5.81,5.52-11.24,13.2-11.24a19.51,19.51,0,0,1,14.4,5.67l-3.27,3.84c-3.84-3.12-6.57-4.23-11.13-4.23-4,0-7.4,2.79-7.4,5.91,0,2.16,1.54,4.13,7.64,4.13,12.53,0,15.74,3.88,15.74,10.65C211.75,178.8,204.84,183.32,195.77,183.32Z"
                    style={{ fill: "#6c6c6c" }}
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M7.41,212.32a3.64,3.64,0,0,1-3-1.38H4.19l-.23.92-.23.23H2.11l-.23-.23V196.19l.23-.23H4l.23.23v5.53h.23a3.64,3.64,0,0,1,3-1.38c3,0,5.07,2.3,5.07,6C12.48,209.79,10.41,212.32,7.41,212.32Zm-.23-9.91c-1.84,0-3,1.15-3,3.92s1.15,3.92,3,3.92,3-1.39,3-3.92S9,202.41,7.18,202.41Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M24.46,212.09H22.85l-.23-.23-.23-.92h-.23a3.45,3.45,0,0,1-2.76,1.38c-3.23,0-4.38-2.3-4.38-4.84V200.8l.23-.23h1.84l.23.23v6.68c0,1.38.46,2.77,2.31,2.77,1.61,0,2.76-1.16,2.76-3.92V200.8l.23-.23h1.84l.24.23v11.06Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M30.23,198.73H28.38l-.23-.23v-1.85l.23-.23h1.85l.23.23v1.85Zm0,13.36H28.38l-.23-.23V200.8l.23-.23h1.85l.23.23v11.06Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M36,212.09H34.15l-.23-.23V196.19l.23-.23H36l.23.23v15.67Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M49.35,212.09H47.74l-.23-.23-.23-.92h-.23a3.64,3.64,0,0,1-3,1.38c-3.22,0-5.06-2.53-5.06-6s1.84-6,5.06-6a3.64,3.64,0,0,1,3,1.38h.23v-5.53l.23-.23h1.84l.23.23v15.67Zm-5.07-9.68c-1.84,0-3,1.38-3,3.92s1.15,3.92,3,3.92,3-1.16,3-3.92S46.13,202.41,44.28,202.41Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M55.12,198.73H53.27L53,198.5v-1.85l.23-.23h1.85l.23.23v1.85Zm0,13.36H53.27l-.23-.23V200.8l.23-.23h1.85l.23.23v11.06Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M68.48,212.09H66.64l-.23-.23v-6.68c0-1.16-.23-2.77-2.31-2.77s-3,1.15-3,3.92v5.53l-.23.23H59l-.23-.23V200.8l.23-.23h1.62l.23.23.23.92h.23a3.64,3.64,0,0,1,3-1.38c3.23,0,4.38,2.07,4.38,4.84v6.68Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M79.54,210.94h-.23a3.64,3.64,0,0,1-3,1.38c-3.23,0-5.07-2.53-5.07-6s1.84-6,5.07-6a3.64,3.64,0,0,1,3,1.38h.23l.23-.92.23-.23h1.62l.23.23v9.91c0,4.6-2.54,6.22-5.3,6.22s-4.38-1.39-4.61-3.23l.23-.23H74l.23.23c.46.92,1.16,1.15,2.31,1.15C78.62,214.85,79.54,213.47,79.54,210.94Zm-3-8.53c-1.85,0-3,1.38-3,3.92s1.15,3.92,3,3.92,3-1.16,3-3.92S78.39,202.41,76.55,202.41Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M94.06,198.73H92.22L92,198.5v-1.85l.23-.23h1.84l.23.23v1.85Zm0,13.36H92.22l-.23-.23V200.8l.23-.23h1.84l.23.23v11.06Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M113.65,212.09H111.8l-.23-.23V205c0-1.39-.23-2.54-2.07-2.54-1.61,0-2.53,1.15-2.53,3v6.45l-.23.23h-1.85l-.23-.23V205c0-1.39-.23-2.54-2.07-2.54-1.15,0-2.54.69-2.54,3v6.45l-.23.23H98l-.23-.23V200.8l.23-.23h1.61l.23.23.23.92h.23a3.42,3.42,0,0,1,3-1.38,3.18,3.18,0,0,1,3,1.61h.23a4.19,4.19,0,0,1,3.46-1.61c3.23,0,3.92,2.07,3.92,4.61v6.91Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M125.86,212.09h-1.61l-.23-.23-.23-.92h-.23a3.85,3.85,0,0,1-3.23,1.38c-2.53,0-3.91-1.38-3.91-3.69,0-2.53,2.07-3.68,5.07-3.68a9.49,9.49,0,0,1,2.07.23l.23-.23v-.46c0-1.62-.69-2.08-2.3-2.08s-2.31.46-2.31,1.38L119,204h-1.84l-.23-.23c0-1.61,1.15-3.45,4.61-3.45s4.6,1.61,4.6,5.07v6.45Zm-2.07-4.84-.23-.23a6.36,6.36,0,0,0-1.84-.23c-2.08,0-3,.69-3,1.84s.69,1.62,2.07,1.62c1.85,0,3-.93,3-2.31Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M136.93,210.94h-.23a3.67,3.67,0,0,1-3,1.38c-3.23,0-5.07-2.53-5.07-6s1.84-6,5.07-6a3.67,3.67,0,0,1,3,1.38h.23l.23-.92.23-.23H139l.23.23v9.91c0,4.6-2.53,6.22-5.3,6.22s-4.38-1.39-4.61-3.23l.23-.23h1.85l.23.23c.46.92,1.15,1.15,2.3,1.15C136,214.85,136.93,213.47,136.93,210.94Zm-3-8.53c-1.84,0-3,1.38-3,3.92s1.15,3.92,3,3.92,3-1.16,3-3.92S135.77,202.41,133.93,202.41Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M144.76,198.73h-1.84l-.23-.23v-1.85l.23-.23h1.84l.23.23v1.85Zm0,13.36h-1.84l-.23-.23V200.8l.23-.23h1.84l.23.23v11.06Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M158.13,212.09h-1.85l-.23-.23v-6.68c0-1.16-.23-2.77-2.3-2.77s-3,1.15-3,3.92v5.53l-.23.23h-1.84l-.23-.23V200.8l.23-.23h1.61l.23.23.23.92H151a3.67,3.67,0,0,1,3-1.38c3.23,0,4.38,2.07,4.38,4.84v6.68Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M170.34,212.09h-1.61l-.23-.23-.23-.92H168a3.85,3.85,0,0,1-3.23,1.38c-2.53,0-3.92-1.38-3.92-3.69,0-2.53,2.08-3.68,5.07-3.68a9.53,9.53,0,0,1,2.08.23l.23-.23v-.46c0-1.62-.69-2.08-2.31-2.08s-2.3.46-2.3,1.38l-.23.23h-1.84l-.24-.23c0-1.61,1.16-3.45,4.61-3.45s4.61,1.61,4.61,5.07v6.45Zm-2.07-4.84L168,207a6.4,6.4,0,0,0-1.85-.23c-2.07,0-3,.69-3,1.84s.69,1.62,2.07,1.62c1.85,0,3-.93,3-2.31Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M179.79,212.09H178a3,3,0,0,1-3.23-3v-6.22l-.23-.23h-1.61l-.23-.23V200.8l.23-.23h1.61l.23-.23v-2.08L175,198h1.84l.24.23v2.08l.23.23h2.53l.23.23v1.61l-.23.23h-2.53l-.23.23v6.22a.85.85,0,0,0,.92.93h1.84l.23.23v1.61Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M185.09,198.73h-1.84l-.23-.23v-1.85l.23-.23h1.84l.23.23v1.85Zm0,13.36h-1.84l-.23-.23V200.8l.23-.23h1.84l.23.23v11.06Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M193.62,200.34c3.45,0,5.53,2.3,5.53,6s-2.08,6-5.53,6-5.53-2.3-5.53-6S190.16,200.34,193.62,200.34Zm0,9.91c2.07,0,3.22-1.39,3.22-3.92s-1.15-3.92-3.22-3.92-3.23,1.38-3.23,3.92S191.54,210.25,193.62,210.25Z"
                    style={{ fill: "#6d6d6c" }}
                />
                <path
                    d="M211.59,212.09h-1.84l-.23-.23v-6.68c0-1.16-.23-2.77-2.31-2.77s-3,1.15-3,3.92v5.53l-.23.23h-1.84l-.24-.23V200.8l.24-.23h1.61l.23.23.23.92h.23a3.64,3.64,0,0,1,3-1.38c3.23,0,4.38,2.07,4.38,4.84v6.68Z"
                    style={{ fill: "#6d6d6c" }}
                />
            </g>
        </g>
    </StyledSvg>
);
export const Document = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 80"
        width="16px"
        height="16px"
    >
        <path
            fill="#fff"
            d="M12.5 75.5L12.5 4.5 49.793 4.5 67.5 22.207 67.5 75.5z"
        />
        <path
            fill="#788b9c"
            d="M49.586,5L67,22.414V75H13V5H49.586 M50,4H12v72h56V22L50,4L50,4z"
        />
        <path
            fill="#fff"
            d="M49.5 22.5L49.5 4.5 49.793 4.5 67.5 22.207 67.5 22.5z"
        />
        <path
            fill="#788b9c"
            d="M50 5.414L66.586 22H50V5.414M50 4h-1v19h19v-1L50 4 50 4zM24 32H56V33H24zM24 38H48V39H24zM24 44H56V45H24zM24 50H48V51H24zM24 56H56V57H24z"
        />
    </StyledSvg>
);
export const Warning = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 486.46 486.46"
    >
        <g>
            <path
                d="M243.23,333.38c-13.6,0-25,11.4-25,25c0,13.6,11.4,25,25,25c13.1,0,25-11.4,24.4-24.4
            C268.23,344.68,256.92,333.38,243.23,333.38z"
            />
            <path
                d="M474.83,335.58l-156.6-271.2c-15.5-27.3-43.5-43.5-74.9-43.5c-31.4,0-59.4,16.3-74.9,43.4l-156.8,271.5
            c-15.6,27.3-15.5,59.8,0.3,86.9c15.6,26.8,43.5,42.9,74.7,42.9h312.8c31.3,0,59.4-16.3,75.2-43.6
            C490.33,394.88,490.42,362.58,474.83,335.58z M440.62,402.38c-8.7,15-24.1,23.9-41.3,23.9H86.53c-17,0-32.3-8.7-40.8-23.4
            c-8.6-14.9-8.7-32.7-0.1-47.7l156.8-271.4c8.5-14.9,23.7-23.7,40.9-23.7c17.1,0,32.4,8.9,40.9,23.8l156.7,271.4
            C449.33,369.88,449.23,387.48,440.62,402.38z"
            />
            <path
                d="M237.03,157.88c-11.9,3.4-19.3,14.2-19.3,27.3c0.6,7.9,1.1,15.9,1.7,23.8c1.7,30.1,3.4,59.6,5.1,89.7
            c0.6,10.2,8.5,17.6,18.7,17.6c10.2,0,18.2-7.9,18.7-18.2c0-6.2,0-11.9,0.6-18.2c1.1-19.3,2.3-38.6,3.4-57.9
            c0.6-12.5,1.7-25,2.3-37.5c0-4.5-0.6-8.5-2.3-12.5C260.83,160.78,248.92,155.08,237.03,157.88z"
            />
        </g>
    </StyledSvg>
);
export const WarningV2 = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 486.5 486.5"
    >
        <g>
            <path
                d="M474.8,335.6L318.2,64.4c-15.5-27.3-43.5-43.5-74.9-43.5c-31.4,0-59.4,16.3-74.9,43.4L11.6,335.8
              c-15.6,27.3-15.5,59.8,0.3,86.9c15.6,26.8,43.5,42.9,74.7,42.9h312.8c31.3,0,59.4-16.3,75.2-43.6
              C490.3,394.9,490.4,362.6,474.8,335.6z M243.2,383.4c-13.6,0-25-11.4-25-25c0-13.6,11.4-25,25-25c13.7,0,25,11.3,24.4,25.6
              C268.2,372,256.3,383.4,243.2,383.4z M265.9,222c-1.1,19.3-2.3,38.6-3.4,57.9c-0.6,6.3-0.6,12-0.6,18.2
              c-0.5,10.3-8.5,18.2-18.7,18.2c-10.2,0-18.1-7.4-18.7-17.6c-1.7-30.1-3.4-59.6-5.1-89.7c-0.6-7.9-1.1-15.9-1.7-23.8
              c0-13.1,7.4-23.9,19.3-27.3c11.9-2.8,23.8,2.9,28.9,14.1c1.7,4,2.3,8,2.3,12.5C267.6,197,266.5,209.5,265.9,222z"
            />
        </g>
    </StyledSvg>
);
export const Exclamation = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 486.5 486.5"
    >
        <g>
            <path
                d="M243.3,367.3c-26.7,0-49.1,22.4-49.1,49.1c0,26.7,22.4,49.1,49.1,49.1c25.8,0,49.1-22.4,48-48
              C292.4,389.5,270.2,367.3,243.3,367.3z"
            />
            <path
                d="M206.5,299.1c1.2,20.1,16.7,34.6,36.8,34.6c20.1,0,35.8-15.5,36.8-35.8c0-12.2,0-23.4,1.2-35.8
              c2.2-37.9,4.5-75.9,6.7-113.8c1.2-24.6,3.3-49.1,4.5-73.7c0-8.8-1.2-16.7-4.5-24.6c-10-22-33.4-33.2-56.8-27.7
              c-23.4,6.7-37.9,27.9-37.9,53.7c1.2,15.5,2.2,31.3,3.3,46.8C199.8,181.9,203.2,239.9,206.5,299.1z"
            />
        </g>
    </StyledSvg>
);

export const Plus = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 168 168"
    >
        <polygon points="168 76 92 76 92 0 76 0 76 76 0 76 0 92 76 92 76 168 92 168 92 92 168 92 168 76" />
    </StyledSvg>
);

export const Setting = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 215.01 215.01"
    >
        <g>
            <path
                d="M1066.39,528.57c-.34-3-3.86-5.3-6.91-5.3A23.74,23.74,0,0,1,1043.23,482a6,6,0,0,0,.66-8.11,106.36,106.36,0,0,0-17-17.2,6,6,0,0,0-8.17.66c-6.41,7.1-17.92,9.73-26.81,6A23.88,23.88,0,0,1,977.35,440a6,6,0,0,0-5.28-6.28,107.26,107.26,0,0,0-24.19-.06,6,6,0,0,0-5.32,6.15,23.93,23.93,0,0,1-14.72,22.94c-8.79,3.59-20.22,1-26.61-6.06a6,6,0,0,0-8.11-.68,106.84,106.84,0,0,0-17.39,17.2,6,6,0,0,0,.64,8.17,23.82,23.82,0,0,1,6,26.83c-3.71,8.84-12.89,14.53-23.39,14.53a5.85,5.85,0,0,0-6.21,5.28,107.66,107.66,0,0,0,0,24.45c.34,3,4,5.29,7.05,5.29a23.58,23.58,0,0,1,22.12,14.73,23.91,23.91,0,0,1-6,26.59,6,6,0,0,0-.65,8.1,106.7,106.7,0,0,0,17,17.21,6,6,0,0,0,8.18-.65c6.43-7.11,17.94-9.75,26.8-6A23.84,23.84,0,0,1,941.78,641a6,6,0,0,0,5.27,6.28,107.08,107.08,0,0,0,24.19.07,6,6,0,0,0,5.32-6.16,23.9,23.9,0,0,1,14.71-22.93c8.85-3.61,20.23-1,26.62,6.06a6,6,0,0,0,8.11.68,107.11,107.11,0,0,0,17.39-17.19,6,6,0,0,0-.64-8.17,23.82,23.82,0,0,1-6-26.82,24.07,24.07,0,0,1,22-14.57l1.33,0a6,6,0,0,0,6.29-5.27A107.27,107.27,0,0,0,1066.39,528.57Zm-106.66,48a35.86,35.86,0,1,1,35.86-35.86A35.9,35.9,0,0,1,959.73,576.59Z"
                transform="translate(-852.06 -433)"
            />
            <circle cx="107.88" cy="107.99" r="14" />
        </g>
    </StyledSvg>
);

export const ArrowToTop = (props: IconProps) => (
    <StyledArrowAnimate className="arrow-top">
        <span className="left-bar" />
        <span className="right-bar" />
    </StyledArrowAnimate>
);

export const ArrowToBottom = (props: IconProps) => (
    <StyledArrowAnimate className="arrow-down arrow-bottom">
        <span className="left-bar" />
        <span className="right-bar" />
    </StyledArrowAnimate>
);

export const ArrowToRight = (props: IconProps) => (
    <StyledArrowAnimate className="arrow-vertical arrow-right">
        <span className="left-bar" />
        <span className="right-bar" />
    </StyledArrowAnimate>
);

export const ArrowToLeft = (props: IconProps) => (
    <StyledArrowAnimate className="arrow-vertical arrow-left">
        <span className="left-bar" />
        <span className="right-bar" />
    </StyledArrowAnimate>
);

export const ArrowLeft = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 240.8 240.8"
    >
        <path d="M190.1,217.9l-97.5-97.5l97.5-97.5L167.2,0L55.7,111.5c-4.8,4.8-4.8,12.9,0,17.7l111.5,111.5L190.1,217.9z" />
    </StyledSvg>
);

export const ArrowRight = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 240.8 240.8"
    >
        <path d="M51.4,22.9l97.5,97.5l-97.5,97.5l22.9,22.9l111.5-111.5c4.8-4.8,4.8-12.9,0-17.7L74.2,0L51.4,22.9z" />
    </StyledSvg>
);

export const ArrowTop = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 240.8 240.8"
    >
        <path d="M23.6,189.4l97.5-97.5l97.5,97.5l22.9-22.9L129.9,55c-4.8-4.8-12.9-4.8-17.7,0L0.7,166.6L23.6,189.4z" />
    </StyledSvg>
);

export const ArrowBottom = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 240.8 240.8"
    >
        <path d="M218.6,51.4l-97.5,97.5L23.6,51.4L0.7,74.2l111.5,111.5c4.8,4.8,12.9,4.8,17.7,0L241.5,74.2L218.6,51.4z" />
    </StyledSvg>
);

export const List = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.03 17.63"
    >
        <g>
            <rect x="5.95" width="13.08" height="3.74" />
            <rect width="3.74" height="3.74" />
            <rect x="5.95" y="6.95" width="13.08" height="3.74" />
            <rect y="6.95" width="3.74" height="3.74" />
            <rect x="5.95" y="13.9" width="13.08" height="3.74" />
            <rect y="13.9" width="3.74" height="3.74" />
        </g>
    </StyledSvg>
);

export const ListThumb = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15.29 15.63"
    >
        <g>
            <rect width="3.74" height="3.74" />
            <rect y="5.95" width="3.74" height="3.74" />
            <rect y="11.9" width="3.74" height="3.74" />
        </g>
        <g>
            <rect x="5.78" width="3.74" height="3.74" />
            <rect x="5.78" y="5.95" width="3.74" height="3.74" />
            <rect x="5.78" y="11.9" width="3.74" height="3.74" />
        </g>
        <g>
            <rect x="11.56" width="3.74" height="3.74" />
            <rect x="11.56" y="5.95" width="3.74" height="3.74" />
            <rect x="11.56" y="11.9" width="3.74" height="3.74" />
        </g>
    </StyledSvg>
);

export const Add = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13.08 13.08"
    >
        <polygon points="13.08 4.67 8.41 4.67 8.41 0 4.67 0 4.67 4.67 0 4.67 0 8.41 4.67 8.41 4.67 13.08 8.41 13.08 8.41 8.41 13.08 8.41 13.08 4.67" />
    </StyledSvg>
);

export const Copy = (props: IconProps) => (
    <StyledSvg
        {...props}
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="m407 15v90h90zm0 0" />
        <path d="m377 135v-135h-197v60h44.425781l8.789063 8.785156 120 120 8.785156 8.789063v224.425781h150v-287zm0 0" />
        <path d="m227 195h90l-90-90zm0 0" />
        <path d="m197 90h-197v422h332v-287h-135zm0 0" />
    </StyledSvg>
);

export const Public = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 448"
    >
        <g>
            <path d="M184.4,319.59a56,56,0,0,0,79.19,0,56,56,0,0,0,0-79.19,56,56,0,0,0-79.19,0,56,56,0,0,0,0,79.19Z" />
            <path d="M324.11,379.24a123.66,123.66,0,0,0-3.87-15.82A75,75,0,0,0,314,349.2a51.22,51.22,0,0,0-9-11.81,38.08,38.08,0,0,0-12.47-7.8,43.84,43.84,0,0,0-16.26-2.92q-1.31,0-6.12,3.14t-10.86,7a69,69,0,0,1-15.75,7,62,62,0,0,1-38.94,0,69,69,0,0,1-15.75-7q-6.05-3.87-10.86-7t-6.12-3.14a43.81,43.81,0,0,0-16.26,2.92,38.07,38.07,0,0,0-12.47,7.8,51.28,51.28,0,0,0-9,11.81,75.16,75.16,0,0,0-6.27,14.22,123.66,123.66,0,0,0-3.86,15.82,148.42,148.42,0,0,0-2,15.9q-.51,7.37-.51,15.1,0,17.5,10.65,27.64T160.27,448H287.73q17.64,0,28.29-10.13t10.65-27.64q0-7.73-.51-15.1A149.32,149.32,0,0,0,324.11,379.24Z" />
            <path d="M223.67,215.67a71.82,71.82,0,0,1,7.8.43V161.6L256,186.16l10.55-10.55-37.3-37.3-.05.05a7.34,7.34,0,0,0-10.5,0l-37.3,37.3L192,186.17l24.57-24.57V216C218.87,215.79,221.24,215.67,223.67,215.67Z" />
            <path d="M387,137.58a6.5,6.5,0,0,0-9,.34l-30.63,26,9.66,11.38,18.38-15.59.09,72.89L286.51,262.4a66.14,66.14,0,0,1,2.27,15L385.4,245a7.46,7.46,0,0,0,5.09-7.09l-.08-80.12,18.95,13.11,8.48-12.28-30.69-21.19Z" />
            <path d="M130,65a65,65,0,1,0-65,65A65,65,0,0,0,130,65ZM50.72,30.52a20.19,20.19,0,0,1,28.56,0A19.46,19.46,0,0,1,85.2,44.8,20.2,20.2,0,0,1,65,65,20.21,20.21,0,0,1,44.8,44.8,19.46,19.46,0,0,1,50.72,30.52ZM31.81,101.74Q28,98.09,28,91.77q0-2.79.18-5.45a53.55,53.55,0,0,1,.74-5.73,44.61,44.61,0,0,1,1.39-5.71,27.11,27.11,0,0,1,2.26-5.13,18.5,18.5,0,0,1,3.26-4.26,13.73,13.73,0,0,1,4.5-2.81,15.8,15.8,0,0,1,5.86-1.05,6.43,6.43,0,0,1,2.21,1.13l3.92,2.53A24.88,24.88,0,0,0,58,67.81a22.36,22.36,0,0,0,14,0,24.88,24.88,0,0,0,5.68-2.52l3.92-2.53a6.43,6.43,0,0,1,2.21-1.13,15.81,15.81,0,0,1,5.87,1.05,13.73,13.73,0,0,1,4.5,2.81,18.47,18.47,0,0,1,3.26,4.26,27.07,27.07,0,0,1,2.26,5.13,44.61,44.61,0,0,1,1.39,5.71,53.86,53.86,0,0,1,.74,5.73Q102,89,102,91.77q0,6.31-3.84,10T88,105.4H42Q35.65,105.4,31.81,101.74Z" />
            <path d="M224,130a65,65,0,1,0-65-65A65,65,0,0,0,224,130ZM209.72,30.52A20.2,20.2,0,0,1,244.2,44.8a20.2,20.2,0,0,1-40.4,0A19.46,19.46,0,0,1,209.72,30.52ZM187.15,86.33a53.55,53.55,0,0,1,.74-5.73,44.61,44.61,0,0,1,1.39-5.71,27.11,27.11,0,0,1,2.26-5.13,18.5,18.5,0,0,1,3.26-4.26,13.73,13.73,0,0,1,4.5-2.81,15.8,15.8,0,0,1,5.86-1.05,6.43,6.43,0,0,1,2.21,1.13l3.92,2.53A24.88,24.88,0,0,0,217,67.81a22.36,22.36,0,0,0,14,0,24.88,24.88,0,0,0,5.68-2.52l3.92-2.53a6.43,6.43,0,0,1,2.21-1.13,15.81,15.81,0,0,1,5.87,1.05,13.73,13.73,0,0,1,4.5,2.81,18.47,18.47,0,0,1,3.26,4.26,27.07,27.07,0,0,1,2.26,5.13,44.61,44.61,0,0,1,1.39,5.71,53.86,53.86,0,0,1,.74,5.73Q261,89,261,91.77q0,6.31-3.84,10T247,105.4H201q-6.36,0-10.2-3.65t-3.84-10Q187,89,187.15,86.33Z" />
            <path d="M383,0a65,65,0,1,0,65,65A65,65,0,0,0,383,0ZM368.72,30.52A20.2,20.2,0,0,1,403.2,44.8a20.2,20.2,0,0,1-40.4,0A19.46,19.46,0,0,1,368.72,30.52Zm47.47,71.22q-3.84,3.65-10.2,3.65H360q-6.36,0-10.2-3.65t-3.84-10q0-2.79.18-5.45a53.55,53.55,0,0,1,.74-5.73,44.61,44.61,0,0,1,1.39-5.71,27.11,27.11,0,0,1,2.26-5.13,18.5,18.5,0,0,1,3.26-4.26,13.73,13.73,0,0,1,4.5-2.81,15.8,15.8,0,0,1,5.86-1.05,6.43,6.43,0,0,1,2.21,1.13l3.92,2.53A24.88,24.88,0,0,0,376,67.81a22.36,22.36,0,0,0,14,0,24.88,24.88,0,0,0,5.68-2.52l3.92-2.53a6.43,6.43,0,0,1,2.21-1.13,15.81,15.81,0,0,1,5.87,1.05,13.73,13.73,0,0,1,4.5,2.81,18.47,18.47,0,0,1,3.26,4.26,27.07,27.07,0,0,1,2.26,5.13,44.61,44.61,0,0,1,1.39,5.71,53.86,53.86,0,0,1,.74,5.73Q420,89,420,91.77,420,98.09,416.19,101.74Z" />
            <path d="M161.5,262.4,72.44,232.58l.09-72.89,18.38,15.59,9.66-11.38-30.63-26a7.46,7.46,0,0,0-8-1.09,7.51,7.51,0,0,0-1,.75l-.09-.12L30.17,158.64l8.48,12.28L57.6,157.81l-.08,80.12A7.46,7.46,0,0,0,62.6,245l96.62,32.36A66.14,66.14,0,0,1,161.5,262.4Z" />
        </g>
    </StyledSvg>
);

export const Delete = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path
                    d="M381.621,65.523h-53.943V54.004C327.678,24.226,303.451,0,273.673,0h-35.346c-29.778,0-54.005,24.226-54.005,54.004
                v11.519h-53.943c-34.76,0-63.04,28.28-63.04,63.04v15.975H444.66v-15.976C444.66,93.803,416.38,65.523,381.621,65.523z
                M297.261,65.523H214.74V54.004c0-13.005,10.581-23.587,23.588-23.587h35.346c13.006,0,23.588,10.581,23.588,23.587V65.523z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M88.452,174.955v306.112c0,17.056,13.877,30.933,30.934,30.933h273.227c17.057,0,30.934-13.877,30.934-30.933V174.955
                H88.452z M197.532,466.16h-30.417V220.795h30.417V466.16z M271.208,466.16h-30.417V220.795h30.417V466.16z M344.886,466.16
                h-30.417V220.795h30.417V466.16z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const Save = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <rect x="139.636" y="372.364" width="232.727" height="46.545" />
            </g>
        </g>
        <g>
            <g>
                <polygon points="139.636,465.455 139.636,488.727 139.636,512 372.364,512 372.364,488.727 372.364,465.455     " />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M507.338,133.843L413.823,9.3c-4.395-5.854-11.29-9.3-18.61-9.3h-38.364v23.273v23.273v147.394
              c0,12.851-10.42,23.273-23.273,23.273H116.364c-12.853,0-23.273-10.422-23.273-23.273V46.545V23.273V0H23.273
              C10.42,0,0,10.422,0,23.273v465.455C0,501.578,10.42,512,23.273,512h69.818v-23.273v-23.273v-23.273v-93.091
              c0-12.854,10.42-23.273,23.273-23.273h279.273c12.853,0,23.273,10.418,23.273,23.273v93.091v23.273v23.273V512h69.818
              C501.58,512,512,501.578,512,488.727v-340.91C512,142.778,510.363,137.872,507.338,133.843z"
                />
            </g>
        </g>
        <g>
            <g>
                <polygon
                    points="139.636,0 139.636,23.273 139.636,46.545 139.636,170.667 310.303,170.667 310.303,46.545 310.303,23.273
              310.303,0     "
                />
            </g>
        </g>
    </StyledSvg>
);

export const SaveAs = (props: IconProps) => (
    <StyledSvg
        {...props}
        id="prefix__Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <style>
            {`
				.prefix__st1{
					stroke-width:22.6772;
					stroke-miterlimit:10
				}
				.prefix__st2{
					fill:#fff !important;
				}
			`}
        </style>
        <path d="M139.6 372.4h232.7v46.5H139.6zM139.6 465.5V512h232.8v-46.5z" />
        <path d="M507.3 133.8L413.8 9.3C409.4 3.4 402.5 0 395.2 0h-38.4v194c0 12.9-10.4 23.3-23.3 23.3H116.4c-12.9 0-23.3-10.4-23.3-23.3V0H23.3C10.4 0 0 10.4 0 23.3v465.5C0 501.6 10.4 512 23.3 512h69.8V349c0-12.9 10.4-23.3 23.3-23.3h279.3c12.9 0 23.3 10.4 23.3 23.3v163h69.8c12.9 0 23.3-10.4 23.3-23.3V147.8c-.1-5-1.7-9.9-4.8-14z" />
        <path d="M139.6 0v170.7h170.7V0z" />
        <circle cx={360.8} cy={362.4} r={135.3} className="prefix__st2" />
        <path
            className="prefix__st1"
            d="M360.8 291.9c-5.8 0-10.6 4.7-10.6 10.6v119.9c0 5.8 4.7 10.6 10.6 10.6s10.6-4.7 10.6-10.6V302.5c0-5.9-4.8-10.6-10.6-10.6z"
        />
        <path
            className="prefix__st1"
            d="M420.7 351.8H300.8c-5.8 0-10.6 4.7-10.6 10.6 0 5.8 4.7 10.6 10.6 10.6h119.9c5.8 0 10.6-4.7 10.6-10.6 0-5.9-4.7-10.6-10.6-10.6z"
        />
    </StyledSvg>
);

export const Camera = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <g>
            <g>
                <path
                    d="M50,40c-8.3,0-15,6.7-15,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C65,46.7,58.3,40,50,40z M90,25H78
              c-1.7,0-3.4-1.3-3.9-2.8l-3.1-9.3C70.4,11.3,68.7,10,67,10H33c-1.6,0-3.4,1.3-3.9,2.8l-3.1,9.3C25.4,23.7,23.6,25,22,25H10
              C4.5,25,0,29.5,0,35v45c0,5.5,4.5,10,10,10h80c5.5,0,10-4.5,10-10V35C100,29.5,95.5,25,90,25z M50,80c-13.8,0-25-11.2-25-25
              c0-13.8,11.2-25,25-25c13.8,0,25,11.2,25,25C75,68.8,63.8,80,50,80z M86.5,42c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5
              c1.9,0,3.5,1.6,3.5,3.5C90,40.4,88.4,42,86.5,42z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const Filter = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 247.46 228.09"
    >
        <path d="M246.74,4.3A7.48,7.48,0,0,0,240,0H7.5A7.5,7.5,0,0,0,1.71,12.27L91.07,120.65v99.94a7.52,7.52,0,0,0,10.83,6.73l50.21-24.89a7.49,7.49,0,0,0,4.17-6.71l.1-75.06L245.75,12.27A7.51,7.51,0,0,0,246.74,4.3ZM143.1,113.19a7.51,7.51,0,0,0-1.72,4.76l-.09,73.1-35.21,17.46V118a7.49,7.49,0,0,0-1.72-4.77L23.4,15H224.05Z" />
    </StyledSvg>
);

export const Ellipsis = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 172.64 36.5"
    >
        <circle cx="18.25" cy="18.25" r="18.25" />
        <circle cx="86.32" cy="18.25" r="18.25" />
        <circle cx="154.39" cy="18.25" r="18.25" />
    </StyledSvg>
);

export const EllipsisVertical = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 36.5 172.6"
    >
        <g>
            <circle cx="18.3" cy="18.3" r="18.2" />
            <circle cx="18.3" cy="86.3" r="18.2" />
            <circle cx="18.3" cy="154.4" r="18.2" />
        </g>
    </StyledSvg>
);

export const Pencil = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="15px"
        height="15px"
        viewBox="0 0 432.544 432.544"
    >
        <g>
            <g>
                <path
                    d="M0,313.775v118.77h118.771l237.541-237.541L237.539,76.232L0,313.775z M103.638,395.999L103.638,395.999l-30.55,0.004
                v-36.546H36.545v-30.553l25.981-25.981l67.093,67.092L103.638,395.999z M246.683,124.77c4.182,0,6.276,2.095,6.276,6.28
                c0,1.906-0.664,3.521-1.999,4.856L96.214,290.651c-1.333,1.328-2.952,1.995-4.854,1.995c-4.184,0-6.279-2.098-6.279-6.279
                c0-1.906,0.666-3.521,1.997-4.856l154.747-154.743C243.154,125.436,244.773,124.77,246.683,124.77z"
                />
                <path
                    d="M421.976,77.654l-67.091-66.806C347.653,3.619,338.992,0,328.903,0c-10.283,0-18.842,3.619-25.693,10.848l-47.394,47.109
                l118.773,118.77l47.394-47.392c7.042-7.043,10.561-15.608,10.561-25.697C432.54,93.743,429.022,85.08,421.976,77.654z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const Share = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 426.667 426.667"
    >
        <g>
            <g>
                <path
                    d="M352,256c-23.983,0-45.13,11.569-58.801,29.201l-144.438-55.553c0.146-1.897,0.572-3.714,0.572-5.647
                c0-3.589-0.568-7.025-1.06-10.479l148.009-68.32c13.693,15.487,33.473,25.466,55.717,25.466c41.167,0,74.667-33.5,74.667-74.667
                c0-41.167-33.5-74.667-74.667-74.667S277.333,54.833,277.333,96c0,3.589,0.568,7.025,1.06,10.479l-148.009,68.32
                c-13.693-15.487-33.473-25.466-55.717-25.466C33.5,149.333,0,182.833,0,224c0,41.167,33.5,74.667,74.667,74.667
                c23.983,0,45.13-11.569,58.801-29.201l144.438,55.553c-0.146,1.897-0.572,3.714-0.572,5.647c0,41.167,33.5,74.667,74.667,74.667
                s74.667-33.5,74.667-74.667C426.667,289.5,393.167,256,352,256z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const Search = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200.25 200.25"
    >
        <path
            d="M28.57,195.35l43.5-43.5c.23-.23.39-.5.61-.74a82.36,82.36,0,1,0-23.53-23.53c-.24.21-.51.38-.74.61L4.9,171.68a16.73,16.73,0,1,0,23.66,23.66m35.57-113a53.79,53.79,0,1,1,53.79,53.79A53.79,53.79,0,0,1,64.13,82.33"
            transform="translate(0 0)"
        />
    </StyledSvg>
);

export const EmptyFavourite = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 49.94 49.94"
    >
        <path
            d="M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757  c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996  L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438  c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701  c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0  l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904  l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z"
            data-original="#000000"
            className="active-path"
            data-old_color="#000000"
            fill="#000000"
        />
    </StyledSvg>
);

export const FullFavourite = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 49.94 49.94"
    >
        <path
            d="M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757  c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996  L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438  c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701  c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0  l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904  l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z"
            data-original="#000000"
            className="active-path"
            data-old_color="#000000"
            fill="#000000"
        />
    </StyledSvg>
);

export const Lock = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path
                    d="M437.333,192h-32v-42.667C405.333,66.99,338.344,0,256,0S106.667,66.99,106.667,149.333V192h-32
                    C68.771,192,64,196.771,64,202.667v266.667C64,492.865,83.135,512,106.667,512h298.667C428.865,512,448,492.865,448,469.333
                    V202.667C448,196.771,443.229,192,437.333,192z M287.938,414.823c0.333,3.01-0.635,6.031-2.656,8.292
                    c-2.021,2.26-4.917,3.552-7.948,3.552h-42.667c-3.031,0-5.927-1.292-7.948-3.552c-2.021-2.26-2.99-5.281-2.656-8.292l6.729-60.51
                    c-10.927-7.948-17.458-20.521-17.458-34.313c0-23.531,19.135-42.667,42.667-42.667s42.667,19.135,42.667,42.667
                    c0,13.792-6.531,26.365-17.458,34.313L287.938,414.823z M341.333,192H170.667v-42.667C170.667,102.281,208.948,64,256,64
                    s85.333,38.281,85.333,85.333V192z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const LockOpen = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <path
            d="M437.3,192h-32v0h-64v0H170.7v-42.7c0-47.1,38.3-85.3,85.3-85.3c34.1,0,63.5,20.1,77.2,49h67.7C384.6,48.1,325.8,0,256,0
            c-82.3,0-149.3,67-149.3,149.3V192h-32c-5.9,0-10.7,4.8-10.7,10.7v266.7c0,23.5,19.1,42.7,42.7,42.7h298.7
            c23.5,0,42.7-19.1,42.7-42.7V202.7C448,196.8,443.2,192,437.3,192z M287.9,414.8c0.3,3-0.6,6-2.7,8.3c-2,2.3-4.9,3.6-7.9,3.6h-42.7
            c-3,0-5.9-1.3-7.9-3.6s-3-5.3-2.7-8.3l6.7-60.5c-10.9-7.9-17.5-20.5-17.5-34.3c0-23.5,19.1-42.7,42.7-42.7s42.7,19.1,42.7,42.7
            c0,13.8-6.5,26.4-17.5,34.3L287.9,414.8z"
        />
    </StyledSvg>
);

export const Verified = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 496 496"
    >
        <path
            d="M487.9,247.9l-39-53.9l7-66l-60.8-27L368,40.1L301.8,47L248,8l-53.9,39L128,40.1l-27,60.8L40.1,128l6.9,66.2
        L8,248l39,53.9L40.1,368l60.8,27l27.1,60.8l66.2-6.9l53.8,39l53.9-39l66.1,6.9l27-60.8l60.8-27.1l-6.9-66.2L487.9,247.9z M364,191.2
        L205.6,349.7c-3.1,3.1-8.2,3.1-11.3,0l-67.9-67.9c-1.6-1.5-2.4-3.5-2.4-5.7s0.9-4.2,2.4-5.7l22.7-22.5c3.1-3.1,8.2-3.1,11.3,0
        l39.6,39.5l130.1-130.1c3.1-3.1,8.2-3.1,11.3,0l22.6,22.6C367.1,183,367.1,188.1,364,191.2z"
        />
    </StyledSvg>
);

export const Close = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="20px"
        viewBox="0 0 348.333 348.334"
    >
        <g>
            <path
                d="M336.559,68.611L231.016,174.165l105.543,105.549c15.699,15.705,15.699,41.145,0,56.85
            c-7.844,7.844-18.128,11.769-28.407,11.769c-10.296,0-20.581-3.919-28.419-11.769L174.167,231.003L68.609,336.563
            c-7.843,7.844-18.128,11.769-28.416,11.769c-10.285,0-20.563-3.919-28.413-11.769c-15.699-15.698-15.699-41.139,0-56.85
            l105.54-105.549L11.774,68.611c-15.699-15.699-15.699-41.145,0-56.844c15.696-15.687,41.127-15.687,56.829,0l105.563,105.554
            L279.721,11.767c15.705-15.687,41.139-15.687,56.832,0C352.258,27.466,352.258,52.912,336.559,68.611z"
            />
        </g>
    </StyledSvg>
);

export const Transfer = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path
                    style={{ opacity: ".5" }}
                    d="M505,359l-80-80c-15-15-41-4.5-41,17v40h-32l-52.8-56.6l-53.3,57.1l70.6,75.6c2.3,2.4,5.4,3.8,8.8,3.8H384v40
              c0,21.5,26,32,41,17l80-80C514.3,383.6,514.3,368.4,505,359z"
                />
                <path
                    d="M122.8,96H12c-6.6,0-12,5.4-12,12v56c0,6.6,5.4,12,12,12h84l52.8,56.6l53.3-57.1l-70.6-75.6
              C129.3,97.4,126.1,96,122.8,96z"
                />
            </g>
            <path
                d="M384,176v40c0,21.5,26,32,41,17l80-80c9.4-9.4,9.4-24.6,0-33.9l-80-80c-15-15-41-4.5-41,17v40h-58.8
            c-3.3,0-6.5,1.4-8.8,3.8L96,336H12c-6.6,0-12,5.4-12,12v56c0,6.6,5.4,12,12,12h110.8c3.3,0,6.5-1.4,8.8-3.8L352,176H384z"
            />
        </g>
    </StyledSvg>
);

export const User = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 350 350"
    >
        <g>
            <path
                d="M175,171.173c38.914,0,70.463-38.318,70.463-85.586C245.463,38.318,235.105,0,175,0s-70.465,38.318-70.465,85.587
              C104.535,132.855,136.084,171.173,175,171.173z"
            />
            <path d="M41.909,301.853C41.897,298.971,41.885,301.041,41.909,301.853L41.909,301.853z" />
            <path d="M308.085,304.104C308.123,303.315,308.098,298.63,308.085,304.104L308.085,304.104z" />
            <path
                d="M307.935,298.397c-1.305-82.342-12.059-105.805-94.352-120.657c0,0-11.584,14.761-38.584,14.761
              s-38.586-14.761-38.586-14.761c-81.395,14.69-92.803,37.805-94.303,117.982c-0.123,6.547-0.18,6.891-0.202,6.131
              c0.005,1.424,0.011,4.058,0.011,8.651c0,0,19.592,39.496,133.08,39.496c113.486,0,133.08-39.496,133.08-39.496
              c0-2.951,0.002-5.003,0.005-6.399C308.062,304.575,308.018,303.664,307.935,298.397z"
            />
        </g>
    </StyledSvg>
);

export const Key = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <path
                d="M463.748,48.251c-64.336-64.336-169.013-64.335-233.349,0.001c-43.945,43.945-59.209,108.706-40.181,167.461
                L4.396,401.536c-2.813,2.813-4.395,6.621-4.395,10.606V497c0,8.291,6.709,15,15,15h84.858c3.984,0,7.793-1.582,10.605-4.395
                l21.211-21.226c3.237-3.237,4.819-7.778,4.292-12.334l-2.637-22.793l31.582-2.974c7.178-0.674,12.847-6.343,13.521-13.521
                l2.974-31.582l22.793,2.651c4.233,0.571,8.496-0.85,11.704-3.691c3.193-2.856,5.024-6.929,5.024-11.206V363h27.422
                c3.984,0,7.793-1.582,10.605-4.395l38.467-37.958c58.74,19.043,122.381,4.929,166.326-39.046
                C528.084,217.266,528.084,112.587,463.748,48.251z M421.313,154.321c-17.549,17.549-46.084,17.549-63.633,0
                s-17.549-46.084,0-63.633s46.084-17.549,63.633,0S438.861,136.772,421.313,154.321z"
            />
        </g>
    </StyledSvg>
);

export const AddUser = (props: IconProps) => (
    <StyledSvg {...props} version="1.1" x="0px" y="0px" viewBox="0 0 350 350">
        <g>
            <path d="M124,178.5c29.5,0,53.4-29,53.4-64.8s-7.8-64.8-53.4-64.8s-53.4,29-53.4,64.8C70.6,149.5,94.5,178.5,124,178.5z" />
            <path d="M23.2,277.5C23.2,275.3,23.2,276.9,23.2,277.5L23.2,277.5z" />
            <path d="M224.8,279.2C224.8,278.6,224.8,275.1,224.8,279.2L224.8,279.2z" />
            <path
                d="M224.7,274.9c-1-62.4-9.1-80.1-71.5-91.4c0,0-8.8,11.2-29.2,11.2s-29.2-11.2-29.2-11.2c-61.7,11.1-70.3,28.6-71.4,89.4
              c-0.1,5-0.1,5.2-0.2,4.6c0,1.1,0,3.1,0,6.6c0,0,14.8,29.9,100.8,29.9s100.8-29.9,100.8-29.9c0-2.2,0-3.8,0-4.8
              C224.8,279.6,224.7,278.9,224.7,274.9z"
            />
        </g>
        <polygon
            points="325.9,134.3 268.7,134.3 268.7,77.1 256.6,77.1 256.6,134.3 199.4,134.3 199.4,146.3 256.6,146.3 256.6,203.5
            268.7,203.5 268.7,146.3 325.9,146.3 "
        />
    </StyledSvg>
);

export const Switch = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        x="0px"
        y="0px"
        width="45.363px"
        height="45.363px"
        viewBox="0 0 45.363 45.363"
    >
        <path
            d="M1.788,16.945c0.388,0.385,0.913,0.601,1.459,0.601l27.493-0.035v3.831c0.003,0.836,0.556,1.586,1.329,1.904
              c0.771,0.314,1.658,0.135,2.246-0.459l9.091-9.18c1.062-1.071,1.06-2.801-0.009-3.868l-9.137-9.134
              c-0.59-0.591-1.479-0.768-2.25-0.446c-0.77,0.319-1.271,1.074-1.27,1.908L30.74,5.9L3.219,5.937
              C2.08,5.94,1.161,6.864,1.163,8.004l0.018,7.483C1.182,16.034,1.401,16.56,1.788,16.945z"
        />
        <path
            d="M42.146,27.901l-27.522-0.035l-0.001-3.834c0.002-0.835-0.5-1.587-1.27-1.907c-0.771-0.321-1.66-0.146-2.25,0.445
              l-9.136,9.135c-1.067,1.064-1.071,2.796-0.009,3.866l9.09,9.181c0.588,0.596,1.475,0.772,2.247,0.458
              c0.772-0.316,1.326-1.066,1.329-1.904v-3.83l27.493,0.035c0.547,0,1.072-0.216,1.459-0.602s0.605-0.91,0.607-1.456L44.2,29.97
              C44.203,28.83,43.284,27.903,42.146,27.901z"
        />
    </StyledSvg>
);

export const Leave = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        x="0px"
        y="0px"
        width="447.674px"
        height="447.674px"
        viewBox="0 0 447.674 447.674"
    >
        <path
            d="M182.725,379.151c-0.572-1.522-0.769-2.816-0.575-3.863c0.193-1.04-0.472-1.902-1.997-2.566
              c-1.525-0.664-2.286-1.191-2.286-1.567c0-0.38-1.093-0.667-3.284-0.855c-2.19-0.191-3.283-0.288-3.283-0.288h-3.71h-3.14H82.224
              c-12.562,0-23.317-4.469-32.264-13.421c-8.945-8.946-13.417-19.698-13.417-32.258V123.335c0-12.562,4.471-23.313,13.417-32.259
              c8.947-8.947,19.702-13.422,32.264-13.422h91.361c2.475,0,4.421-0.614,5.852-1.854c1.425-1.237,2.375-3.094,2.853-5.568
              c0.476-2.474,0.763-4.708,0.859-6.707c0.094-1.997,0.048-4.521-0.144-7.566c-0.189-3.044-0.284-4.947-0.284-5.712
              c0-2.474-0.905-4.611-2.712-6.423c-1.809-1.804-3.949-2.709-6.423-2.709H82.224c-22.648,0-42.016,8.042-58.101,24.125
              C8.042,81.323,0,100.688,0,123.338v200.994c0,22.648,8.042,42.018,24.123,58.095c16.085,16.091,35.453,24.133,58.101,24.133
              h91.365c2.475,0,4.422-0.622,5.852-1.854c1.425-1.239,2.375-3.094,2.853-5.571c0.476-2.471,0.763-4.716,0.859-6.707
              c0.094-1.999,0.048-4.518-0.144-7.563C182.818,381.817,182.725,379.915,182.725,379.151z"
        />
        <path
            d="M442.249,210.989L286.935,55.67c-3.614-3.612-7.898-5.424-12.847-5.424c-4.949,0-9.233,1.812-12.851,5.424
              c-3.617,3.617-5.424,7.904-5.424,12.85v82.226H127.907c-4.952,0-9.233,1.812-12.85,5.424c-3.617,3.617-5.424,7.901-5.424,12.85
              v109.636c0,4.948,1.807,9.232,5.424,12.847c3.621,3.61,7.901,5.427,12.85,5.427h127.907v82.225c0,4.945,1.807,9.233,5.424,12.847
              c3.617,3.617,7.901,5.428,12.851,5.428c4.948,0,9.232-1.811,12.847-5.428L442.249,236.69c3.617-3.62,5.425-7.898,5.425-12.848
              C447.674,218.894,445.866,214.606,442.249,210.989z"
        />
    </StyledSvg>
);

export const Correct = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        width="442.533px"
        height="442.533px"
        viewBox="0 0 442.533 442.533"
    >
        <g>
            <path
                d="M434.539,98.499l-38.828-38.828c-5.324-5.328-11.799-7.993-19.41-7.993c-7.618,0-14.093,2.665-19.417,7.993L169.59,247.248
              l-83.939-84.225c-5.33-5.33-11.801-7.992-19.412-7.992c-7.616,0-14.087,2.662-19.417,7.992L7.994,201.852
              C2.664,207.181,0,213.654,0,221.269c0,7.609,2.664,14.088,7.994,19.416l103.351,103.349l38.831,38.828
              c5.327,5.332,11.8,7.994,19.414,7.994c7.611,0,14.084-2.669,19.414-7.994l38.83-38.828L434.539,137.33
              c5.325-5.33,7.994-11.802,7.994-19.417C442.537,110.302,439.864,103.829,434.539,98.499z"
            />
        </g>
    </StyledSvg>
);

export const Restore = (props: IconProps) => (
    <StyledSvg {...props} x="0px" y="0px" viewBox="0 0 448 448">
        <g>
            <path
                d="M256,32C149.973,32,64,117.973,64,224H0l85.333,85.333L170.667,224h-64c0-82.453,66.88-149.333,149.333-149.333
                S405.333,141.547,405.333,224S338.453,373.333,256,373.333c-32.32,0-62.08-10.347-86.613-27.84L139.2,376.107
                C171.627,401.067,212.053,416,256,416c106.027,0,192-85.973,192-192S362.027,32,256,32z"
            />
            <path
                d="M298.667,224c0-23.573-19.093-42.667-42.667-42.667S213.333,200.427,213.333,224s19.093,42.667,42.667,42.667
                S298.667,247.573,298.667,224z"
            />
        </g>
    </StyledSvg>
);

export const Organization = (props: IconProps) => (
    <StyledSvg
        {...props}
        x="0px"
        y="0px"
        width="484.738px"
        height="484.738px"
        viewBox="0 0 484.738 484.738"
    >
        <g>
            <path
                d="M131.012,438.372h85.624v-87.557h51.467v87.557h85.624V46.366H131.012V438.372z M257.993,92.564h51.466v51.467h-51.466
              V92.564z M257.993,175.279h51.466v51.467h-51.466V175.279z M257.993,257.992h51.466v51.467h-51.466V257.992z M175.279,92.564
              h51.466v51.467h-51.466V92.564z M175.279,175.279h51.466v51.467h-51.466V175.279z M175.279,257.992h51.466v51.467h-51.466V257.992
              z"
            />
            <path
                d="M373.719,81.482V358.66h33.065v79.712h77.954V81.482H373.719z M444.437,321.009H397.58v-46.856h46.856V321.009z
              M444.437,245.703H397.58v-46.855h46.856V245.703z M444.437,170.398H397.58v-46.855h46.856V170.398z"
            />
            <path
                d="M0,438.372h77.954V358.66h33.065V81.482H0V438.372z M40.302,123.543h46.856v46.855H40.302V123.543z M40.302,198.848
              h46.856v46.855H40.302V198.848z M40.302,274.151h46.856v46.855H40.302V274.151z"
            />
        </g>
    </StyledSvg>
);

export const Usage = (props: IconProps) => (
    <StyledSvg {...props} viewBox="0 0 425.2 425.2">
        <g>
            <path d="M362.93,62.26A212.6,212.6,0,1,0,62.27,362.92,212.6,212.6,0,1,0,362.93,62.26Zm38.45,150.33c0,104.09-84.69,188.78-188.78,188.78S23.82,316.68,23.82,212.59,108.5,23.81,212.6,23.81,401.38,108.49,401.38,212.59Z" />
            <path d="M228.27,65.15l-25.47,149,136.4,67.6A147.58,147.58,0,0,0,228.27,65.15Z" />
        </g>
    </StyledSvg>
);

export const HegiasWarning = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 507.04 475.44"
    >
        <g>
            <path
                d="M406.79,149.51,211.73,36.89,16.67,149.51,211.73,262.12Z"
                style={{
                    fill: "#91a436",
                    transform: "translate(-2.46 -36.89)",
                }}
            />
            <path
                d="M225.89,512,421,399.35V174L225.89,286.65Z"
                style={{
                    fill: "#2b526d",
                    transform: "translate(-2.46 -36.89)",
                }}
            />
            <path
                d="M2.46,174V399.35L197.57,512V286.65Z"
                style={{
                    fill: "#4c225b",
                    transform: "translate(-2.46 -36.89)",
                }}
            />
        </g>
        <path
            d="M506.22,473.63l-111-197c-9.46-17.21-35.26-17.21-44.72,0l-111,197c-9.47,17.2,2.58,38.7,22.36,38.7H483C503.64,511.47,515.69,490.83,506.22,473.63Z"
            style={{ fill: "#e24c4b", transform: "translate(-2.46 -36.89)" }}
        />
        <path
            d="M373.91,289.57l111,197H263l111-197Z"
            style={{ fill: "#fff", transform: "translate(-2.46 -36.89)" }}
        />
        <g>
            <path
                d="M355.85,379.88l6.88,45.58a11.19,11.19,0,0,0,11.18,9.46h0a11.9,11.9,0,0,0,11.18-9.46L392,379.88c1.72-11.18-6.88-20.64-18.06-20.64h0C362.73,359.23,355,368.7,355.85,379.88Z"
                style={{
                    fill: "#3f4448",
                    transform: "translate(-2.46 -36.89)",
                }}
            />
            <circle
                cx="371.45"
                cy="417.82"
                r="10.32"
                style={{ fill: "#3f4448" }}
            />
        </g>
    </StyledSvg>
);
export const Send = (props: IconProps) => (
    <StyledSvg
        {...props}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z" />
        <path d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z" />
    </StyledSvg>
);

export const MultiUser = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 511.6 511.6"
    >
        <g>
            <g>
                <path
                    d="M301.9,327.6c30.9-13,55.3-30.8,73.2-53.2C393,251.9,402,227.4,402,201c0-26.5-8.9-50.9-26.8-73.4
                c-17.9-22.5-42.3-40.2-73.2-53.2C271,61.3,237.4,54.8,201,54.8c-36.4,0-70,6.5-100.9,19.6c-30.9,13-55.3,30.8-73.2,53.2
                C8.9,150.1,0,174.5,0,201c0,22.6,6.8,44,20.3,64c13.5,20,32.1,36.8,55.7,50.5c-1.9,4.6-3.9,8.8-5.9,12.6c-2,3.8-4.4,7.5-7.1,11
                c-2.8,3.5-4.9,6.3-6.4,8.3c-1.5,2-4,4.8-7.4,8.4c-3.4,3.6-5.6,6-6.6,7.1c0-0.2-0.4,0.2-1.1,1.3c-0.8,1-1.2,1.5-1.3,1.4
                c-0.1-0.1-0.5,0.4-1.1,1.4c-0.7,1-1,1.6-1,1.6l-0.7,1.4c-0.3,0.6-0.5,1.1-0.6,1.7c-0.1,0.6-0.1,1.2-0.1,1.9s0.1,1.3,0.3,1.9
                c0.4,2.5,1.5,4.5,3.3,6c1.8,1.5,3.8,2.3,5.9,2.3h0.9c9.5-1.3,17.7-2.9,24.6-4.6c29.3-7.6,55.8-19.8,79.4-36.5
                c17.1,3,33.9,4.6,50.2,4.6C237.4,347.2,271,340.6,301.9,327.6z"
                />
                <path
                    d="M491.4,338.2c13.5-19.9,20.3-41.2,20.3-64.1c0-23.4-7.1-45.3-21.4-65.7c-14.3-20.4-33.7-37.3-58.2-50.8
                c4.4,14.3,6.6,28.7,6.6,43.4c0,25.5-6.4,49.7-19.1,72.5c-12.8,22.8-31,43-54.8,60.5c-22.1,16-47.2,28.3-75.4,36.8
                c-28.2,8.6-57.6,12.8-88.2,12.8c-5.7,0-14.1-0.4-25.1-1.1c38.3,25.1,83.2,37.7,134.8,37.7c16.4,0,33.1-1.5,50.3-4.6
                c23.6,16.8,50.1,28.9,79.4,36.5c6.9,1.7,15,3.2,24.6,4.6c2.3,0.2,4.4-0.5,6.3-2c1.9-1.5,3.1-3.6,3.7-6.3c-0.1-1.1,0-1.8,0.3-1.9
                c0.3-0.1,0.2-0.7-0.1-1.9c-0.4-1.1-0.6-1.7-0.6-1.7l-0.7-1.4c-0.2-0.4-0.5-0.9-1-1.6c-0.5-0.7-0.9-1.1-1.1-1.4
                c-0.3-0.3-0.7-0.8-1.3-1.4c-0.6-0.7-1-1.1-1.1-1.3c-1-1.1-3.1-3.5-6.6-7.1c-3.4-3.6-5.9-6.4-7.4-8.4c-1.5-2-3.7-4.8-6.4-8.3
                c-2.8-3.5-5.1-7.2-7.1-11c-2-3.8-3.9-8-5.9-12.6C459.3,374.9,477.8,358.1,491.4,338.2z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const Refresh = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 438.529 438.528"
    >
        <g>
            <g>
                <path
                    d="M433.109,23.694c-3.614-3.612-7.898-5.424-12.848-5.424c-4.948,0-9.226,1.812-12.847,5.424l-37.113,36.835
                c-20.365-19.226-43.684-34.123-69.948-44.684C274.091,5.283,247.056,0.003,219.266,0.003c-52.344,0-98.022,15.843-137.042,47.536
                C43.203,79.228,17.509,120.574,5.137,171.587v1.997c0,2.474,0.903,4.617,2.712,6.423c1.809,1.809,3.949,2.712,6.423,2.712h56.814
                c4.189,0,7.042-2.19,8.566-6.565c7.993-19.032,13.035-30.166,15.131-33.403c13.322-21.698,31.023-38.734,53.103-51.106
                c22.082-12.371,45.873-18.559,71.376-18.559c38.261,0,71.473,13.039,99.645,39.115l-39.406,39.397
                c-3.607,3.617-5.421,7.902-5.421,12.851c0,4.948,1.813,9.231,5.421,12.847c3.621,3.617,7.905,5.424,12.854,5.424h127.906
                c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.42-7.898,5.42-12.847V36.542C438.529,31.593,436.733,27.312,433.109,23.694z"
                />
                <path
                    d="M422.253,255.813h-54.816c-4.188,0-7.043,2.187-8.562,6.566c-7.99,19.034-13.038,30.163-15.129,33.4
                c-13.326,21.693-31.028,38.735-53.102,51.106c-22.083,12.375-45.874,18.556-71.378,18.556c-18.461,0-36.259-3.423-53.387-10.273
                c-17.13-6.858-32.454-16.567-45.966-29.13l39.115-39.112c3.615-3.613,5.424-7.901,5.424-12.847c0-4.948-1.809-9.236-5.424-12.847
                c-3.617-3.62-7.898-5.431-12.847-5.431H18.274c-4.952,0-9.235,1.811-12.851,5.431C1.807,264.844,0,269.132,0,274.08v127.907
                c0,4.945,1.807,9.232,5.424,12.847c3.619,3.61,7.902,5.428,12.851,5.428c4.948,0,9.229-1.817,12.847-5.428l36.829-36.833
                c20.367,19.41,43.542,34.355,69.523,44.823c25.981,10.472,52.866,15.701,80.653,15.701c52.155,0,97.643-15.845,136.471-47.534
                c38.828-31.688,64.333-73.042,76.52-124.05c0.191-0.38,0.281-1.047,0.281-1.995c0-2.478-0.907-4.612-2.715-6.427
                C426.874,256.72,424.731,255.813,422.253,255.813z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const Walking = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 457 457"
    >
        <g>
            <g>
                <path
                    d="M203,314.1c2,0.1,3.9,0,5.8-0.1c6.2,10.1,11.4,16.3,14.5,28c3.1,11.7,4.3,53.5,4.1,59.5c-0.5,13,9.6,23.9,22.6,24.3
                c0.3,0,0.6,0,0.9,0c22.9,0,23.5-24.7,23.5-24.7c0.1-4.3,0.3-11.9,0.1-21.1l-0.9-18.8c-0.9-12.2-2.6-24.7-5.8-34.2
                c-4.3-13-13.4-28.9-21.3-41.3c0.7-1.7,1.3-3.4,1.7-5.2l16.1-60.8c18.8,26.4,41.3,42.2,65.8,46c1,0.2,1.9,0.2,2.9,0.2
                c9.1,0,17.1-6.6,18.5-15.9c1.6-10.2-5.4-19.8-15.7-21.4c-30.9-4.8-53-47.3-61.5-69.5c-0.7-2.5-6.5-15.6-15.7-24.1
                c28-2.2,50.1-25.7,50.1-54.3c0-30.1-24.4-54.4-54.5-54.4c-30.1,0-54.4,24.4-54.4,54.4c0,17.3,8.1,32.7,20.6,42.7
                c-11.3,3.1-72.5,22.8-113.5,98.5c-4.9,9.1-1.5,20.5,7.6,25.4c2.8,1.5,5.9,2.3,8.9,2.3c6.7,0,13.1-3.6,16.5-9.8
                c10.7-19.8,23.8-36.5,39.1-50.1l-17.9,67.8c-2.1,7.9-1.9,15.9,0.1,23.3c-4,24.2-9.9,53-14.4,62.4c-2.3,5-7,11.4-12.5,18l-17,18.8
                c-1.9,1.9-3.7,3.6-5.2,5.1C94,403,113,419.9,113,419.9c4.4,3.8,9.9,5.7,15.4,5.7c5.8,0,11.7-2.1,16.2-6.5
                c31.3-30.5,45.7-57.9,45.7-57.9C195.1,350.1,199.5,331.6,203,314.1z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const Bird = (props: IconProps) => (
    <StyledSvg
        {...props}
        viewBox="0 0 509.035 509.035"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="m509.032 38.266c-.032-12.515-13.829-20.17-24.467-13.537-120.825 75.354-247.846 192.407-306.319 193.954-49.274-44.23-88.855-60.793-120.9-50.628-27.663 8.775-46.83 37.015-56.968 83.935-2.841 13.152 10.897 23.739 22.889 17.643 25.909-13.169 44.635-13.591 62.494-1.382 34.42 27.501 29.27 115.717 74.682 139.303 29.759 15.457 66.558 16.663 109.63 3.637 6.961 35.506 42.005 62.156 72.533 81.509 3.91 2.479 8.823 3.203 13.439 1.727 72.484-23.179 130.193-84.06 95.481-149.315-3.521-6.62-11.164-9.908-18.396-7.906-28.913 8.005-59.475 5.638-93.381-7.24l-14.497-9.551c147.667-14.259 184.149-133.178 183.78-282.149z" />
        <path d="m187.586 184.44c29.099-9.479 73.521-40.8 98.779-58.867-8.252-42.184-30.014-77.864-60.654-107.268-8.827-8.474-23.546-4.274-26.578 7.576l-35.825 139.955c7.92 5.503 16.01 11.701 24.278 18.604z" />
    </StyledSvg>
);

export const Move = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
    >
        <path
            d="M220.8,184.1c-2.3,2.3-3.6,5.5-3.6,8.8l0.2,165.4h-23.1c-5,0-9.5,3.3-11.5,8c-1.9,4.6-0.8,10,2.8,13.5
              l55.2,54.7c6.4,6.4,16.9,6.4,23.3-0.1l55-55c3.6-3.6,4.6-8.9,2.7-13.5s-6.5-7.6-11.5-7.6h-23.1L287,192.7c0-6.9-5.6-12.4-12.4-12.4
              l-45,0.1C226.3,180.4,223.1,181.7,220.8,184.1z"
        />
        <path
            d="M283.9,327.9c2.3-2.3,3.6-5.5,3.6-8.8l-0.2-165.4h23.1c5,0,9.5-3.3,11.5-8c1.9-4.6,0.8-10-2.8-13.5l-55.2-54.7
              c-6.4-6.4-16.9-6.4-23.3,0.1l-55,55c-3.6,3.6-4.6,8.9-2.7,13.5c1.9,4.6,6.5,7.6,11.5,7.6h23.1l0.2,165.6c0,6.9,5.6,12.4,12.4,12.4
              l45-0.1C278.4,331.6,281.6,330.3,283.9,327.9z"
        />
        <path
            d="M324.3,224.4c-2.3-2.3-5.5-3.6-8.8-3.6L150.1,221v-23c0-5-3.3-9.5-8-11.5c-4.6-1.9-10-0.8-13.5,2.8l-54.7,55.2
              c-6.4,6.4-6.4,16.9,0.1,23.3l55,55c3.6,3.6,8.9,4.6,13.5,2.7s7.6-6.5,7.6-11.5v-23.1l165.6-0.2c6.9,0,12.4-5.6,12.4-12.4l-0.1-45
              C327.9,229.9,326.6,226.8,324.3,224.4z"
        />
        <path
            d="M180.4,287.6c2.3,2.3,5.5,3.6,8.8,3.6l165.4-0.2v23c0,5,3.3,9.5,8,11.5c4.6,1.9,10,0.8,13.5-2.8l54.7-55.2
              c6.4-6.4,6.4-16.9-0.1-23.3l-55-55c-3.6-3.6-8.9-4.6-13.5-2.7c-4.6,1.9-7.6,6.5-7.6,11.5v23.1L189,221.3c-6.9,0-12.4,5.6-12.4,12.4
              l0.1,45C176.8,282.1,178.1,285.2,180.4,287.6z"
        />
    </StyledSvg>
);

export const Scale = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path
                    d="M60,0v282H0v230h230v-60h282V0H60z M200,482H30V312h170V482z M452,175h-30v-63.787l-95.394,95.394l-21.213-21.213
                  L400.787,90H337V60h115V175z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const Rotate = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 65.3 66.2"
    >
        <path
            d="M56.7,31.1c-2.8,0-5,2.2-5,5c0,10.5-8.5,19-19,19c-10.5,0-19-8.5-19-19s8.5-19,19-19c0.2,0,0.5,0,0.7-0.1v6.3
            c0,0.9,1.1,1.3,1.7,0.7l10.6-10.6c0.3-0.4,0.3-1-0.1-1.4L35.1,1.4c-0.6-0.6-1.7-0.2-1.7,0.7v5.1c-0.2,0-0.5-0.1-0.7-0.1
            c-16,0-29,13-29,29c0,16,13,29,29,29s29-13,29-29C61.7,33.3,59.4,31.1,56.7,31.1z"
        />
    </StyledSvg>
);

export const Question = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="31.357px"
        height="31.357px"
        viewBox="0 0 31.357 31.357"
    >
        <g>
            <path
                d="M15.255,0c5.424,0,10.764,2.498,10.764,8.473c0,5.51-6.314,7.629-7.67,9.62c-1.018,1.481-0.678,3.562-3.475,3.562
              c-1.822,0-2.712-1.482-2.712-2.838c0-5.046,7.414-6.188,7.414-10.343c0-2.287-1.522-3.643-4.066-3.643
              c-5.424,0-3.306,5.592-7.414,5.592c-1.483,0-2.756-0.89-2.756-2.584C5.339,3.683,10.084,0,15.255,0z M15.044,24.406
              c1.904,0,3.475,1.566,3.475,3.476c0,1.91-1.568,3.476-3.475,3.476c-1.907,0-3.476-1.564-3.476-3.476
              C11.568,25.973,13.137,24.406,15.044,24.406z"
            />
        </g>
    </StyledSvg>
);

export const Folder = (props: IconProps) => (
    <StyledSvg
        {...props}
        viewBox="0 -37 512.00092 512"
        width="512pt"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path d="M 496.691406 160.375 L 15.316406 160.375 C 10.863281 160.375 6.789062 162.15625 3.734375 165.59375 C 0.679688 169.03125 -0.464844 173.230469 0.167969 177.6875 L 31.863281 404.753906 C 34.535156 423.464844 50.699219 437.847656 69.664062 437.847656 L 442.980469 437.847656 C 461.945312 437.847656 478.238281 423.464844 480.785156 404.628906 L 511.839844 177.558594 C 512.476562 173.105469 511.203125 168.90625 508.277344 165.46875 C 505.222656 162.15625 501.148438 160.375 496.691406 160.375 Z M 337.464844 301.019531 C 335.554688 302.804688 333.007812 303.695312 330.589844 303.695312 C 327.917969 303.695312 325.117188 302.546875 323.082031 300.511719 L 266.1875 239.289062 L 266.1875 384.773438 C 266.1875 390.371094 261.605469 394.953125 256.003906 394.953125 C 250.402344 394.953125 245.820312 390.371094 245.820312 384.773438 L 245.820312 239.289062 L 188.925781 300.382812 C 185.109375 304.457031 178.617188 304.710938 174.542969 300.894531 C 170.472656 297.074219 170.214844 290.582031 174.035156 286.511719 L 248.621094 206.449219 C 250.53125 204.414062 253.203125 203.269531 256.132812 203.269531 C 259.058594 203.269531 261.605469 204.414062 263.640625 206.449219 L 338.226562 286.511719 C 341.792969 290.710938 341.539062 297.074219 337.464844 301.019531 Z M 337.464844 301.019531 " />
            <path d="M 42.554688 140.011719 L 473.65625 140.011719 L 473.65625 107.808594 C 473.65625 86.804688 456.980469 70.003906 435.851562 70.003906 L 276.625 70.003906 C 256.132812 70.003906 236.914062 58.804688 226.476562 41.238281 L 213.238281 18.710938 C 206.363281 7.253906 193.890625 0 180.527344 0 L 76.285156 0 C 65.71875 0 55.917969 4.457031 48.664062 12.09375 C 41.40625 19.730469 37.589844 29.910156 38.097656 40.476562 Z M 42.554688 140.011719 " />
        </g>
    </StyledSvg>
);

export const ArrowTop2 = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512.2 512.2"
    >
        <g>
            <g>
                <path
                    d="M33.1,228.2c1.7,4,5.5,6.6,9.9,6.6h116.9v266.7c0,5.9,4.8,10.7,10.7,10.7h170.7c5.9,0,10.7-4.8,10.7-10.7V234.8h117.3
             c4.3,0,8.2-2.6,9.9-6.6c1.6-4,0.7-8.6-2.3-11.6L264,3.1c-2-2-4.7-3.1-7.6-3.1c-2.8,0-5.5,1.1-7.6,3.1L35.4,216.6
             C32.4,219.7,31.5,224.3,33.1,228.2z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const ArrowBottom2 = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512.171 512.171"
    >
        <g>
            <g>
                <path
                    d="M479.046,283.925c-1.664-3.989-5.547-6.592-9.856-6.592H352.305V10.667C352.305,4.779,347.526,0,341.638,0H170.971
                c-5.888,0-10.667,4.779-10.667,10.667v266.667H42.971c-4.309,0-8.192,2.603-9.856,6.571c-1.643,3.989-0.747,8.576,2.304,11.627
                l212.8,213.504c2.005,2.005,4.715,3.136,7.552,3.136s5.547-1.131,7.552-3.115l213.419-213.504
                C479.793,292.501,480.71,287.915,479.046,283.925z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const Camera2 = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 432 432"
    >
        <path
            d="M204.3,347L410,268.1c13.7-5.3,20.5-20.6,15.2-34.3l-62.7-163c-5.3-13.7-20.6-20.5-34.3-15.2l-205.7,78.9
            c-13.7,5.3-20.5,20.6-15.2,34.3l62.7,163C175.3,345.4,190.6,352.3,204.3,347z"
        />
        <path d="M109.1,222.6L6,208.9l61.6,160.3l67.3-79.1L109.1,222.6z" />
    </StyledSvg>
);

export const ArrowDownFilled = (props: IconProps) => (
    <StyledSvg {...props} viewBox="0 0 1024 1024" width="12px" height="12px">
        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
    </StyledSvg>
);
export const Check = (props: IconProps) => (
    <StyledSvg {...props} viewBox="0 0 174.239 174.239">
        <path d="M74.439,157.519c-1.59,1.848-4.34,1.989-6.111,0.313L1.38,94.468c-1.771-1.676-1.848-4.496-0.173-6.267l21.33-22.539  c1.676-1.771,4.496-1.848,6.267-0.173l36.571,34.611c1.771,1.676,4.521,1.535,6.111-0.313l71.447-83.015  c1.59-1.848,4.404-2.059,6.251-0.468l23.518,20.242c1.848,1.59,2.059,4.404,0.468,6.252L74.439,157.519z" />
    </StyledSvg>
);

export const Embed = (props: IconProps) => (
    <StyledSvg {...props} viewBox="0 0 469 469">
        <path d="M189,409.1l-28.7,0l-32.8,0L279.2,59l60.1,0L189,409.1z" />
        <g>
            <polygon points="341.8,283.1 365.4,337.9 429.2,274.1 429.2,274.1 468.7,234.5 429.2,194.9 429.2,194.9 365.1,130.8 341.6,185.6 389.7,234.5" />
        </g>
        <g>
            <polygon points="126.9,283.1 103.4,337.9 39.6,274.1 39.6,274.1 0,234.5 39.6,194.9 39.6,194.9 103.6,130.8 127.2,185.6 79.1,234.5" />
        </g>
    </StyledSvg>
);

export const CrossSection = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="91.828px"
        height="91.828px"
        viewBox="0 0 91.828 91.828"
        // style="enable-background:new 0 0 91.828 91.828;"
        xmlSpace="preserve"
    >
        <g>
            <path
                d="M78.957,0H12.873c-1.104,0-2,0.896-2,2v87.828c0,1.104,0.896,2,2,2h19.375c1.104,0,2-0.896,2-2V73.914h23.333v15.914
		c0,1.104,0.896,2,2,2h19.375c1.104,0,2-0.896,2-2V2C80.957,0.895,80.06,0,78.957,0z M32.998,58.997c0,0.829-0.671,1.5-1.5,1.5
		h-8.917c-0.829,0-1.5-0.671-1.5-1.5V50.08c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5V58.997z M32.998,40.122
		c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5v-8.917c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5
		V40.122z M32.998,21.247c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5V12.33c0-0.829,0.671-1.5,1.5-1.5h8.917
		c0.829,0,1.5,0.671,1.5,1.5V21.247z M51.873,58.997c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5V50.08
		c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5V58.997z M51.873,40.122c0,0.829-0.671,1.5-1.5,1.5h-8.917
		c-0.829,0-1.5-0.671-1.5-1.5v-8.917c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5V40.122z M51.873,21.247
		c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5V12.33c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5
		V21.247z M70.748,58.997c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5V50.08c0-0.829,0.671-1.5,1.5-1.5h8.917
		c0.829,0,1.5,0.671,1.5,1.5V58.997z M70.748,40.122c0,0.829-0.671,1.5-1.5,1.5h-8.917c-0.829,0-1.5-0.671-1.5-1.5v-8.917
		c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5V40.122z M70.748,21.247c0,0.829-0.671,1.5-1.5,1.5h-8.917
		c-0.829,0-1.5-0.671-1.5-1.5V12.33c0-0.829,0.671-1.5,1.5-1.5h8.917c0.829,0,1.5,0.671,1.5,1.5V21.247z"
            />
        </g>
    </StyledSvg>
);

export const Hand = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 59.1 59.1"
    >
        <path
            d="M52.8,27.1c-0.4-0.7-1.2-1.1-2.1-1.1c-1.7,0-3.7,0.7-5.2,1.7c-1.4,1-3.5,2.8-5.3,5.9c-0.3,0.6-0.9,1-1.5,1.1
                c0-0.1,0-0.3,0-0.4c0-0.3,0-0.7,0-1.1V11c0-2.2-1.8-4-4-4s-4,1.8-4,4v15.5c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5V4
                c0-2.2-1.8-4-4-4s-4,1.8-4,4v22.5c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5v-18c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5v20
                c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5V16c0-1.9-1.6-3.5-3.5-3.5c-1.9,0-3.5,1.6-3.5,3.5l0,27.2c1,14,9.5,15.9,17.8,15.9
                c6.8,0,13-3.4,16-8.8c0.4-0.6,1.4-2.3,2.7-4.3c3.1-4.9,5.7-9,6.1-9.8c0.9-1.6,1.8-2.9,2.8-3.7c0.9-0.7,1.4-1.6,1.8-2.4
                C53.3,29.7,53.5,28.2,52.8,27.1z"
        />
    </StyledSvg>
);
export const Stature = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <circle cx="127.2" cy="93.7" r="32.9" />
            <path
                d="M138.6,142.2c-8.2-1.7-17.1-1.6-25.1,0.3c-54.6,6.4-72.4,69.6-67.4,117.8c2.2,20.8,35,21.1,32.8,0
                c-1.8-17.6-1-44.1,8.3-62.9c0,22.9,0,45.8,0,68.7c0,0.8,0.1,1.6,0.1,2.3c0,0.4-0.1,0.7-0.1,1.1c0,54.5-0.1,109-2.3,163.5
                c-1,24.2,36.6,24.1,37.5,0c1.8-42.7,2.2-85.4,2.3-128.2c1.8,0.1,3.6,0.1,5.3,0c0.1,42.7,0.5,85.5,2.3,128.1
                c1,24.1,38.6,24.2,37.5,0c-2.3-54.5-2.3-109-2.3-163.5c0-1.7-0.2-3.2-0.6-4.7c-0.1-23.7-1-47.4-0.7-71
                c11.2,18.9,12.2,47.8,10.3,66.5c-2.2,21,30.7,20.8,32.8,0C214.6,211.2,196,146.3,138.6,142.2z"
            />
        </g>
        <path
            d="M403.9,63.3H283.5c-6.7,0-12,5.4-12,12v361.3c0,6.7,5.4,12,12,12h120.4c6.7,0,12-5.4,12-12V75.4
            C415.9,68.7,410.5,63.3,403.9,63.3z M391.8,424.6h-96.3v-36.1h60.2v-24.1h-60.2v-36.1h36.1v-24.1h-36.1V268h60.2V244h-60.2v-36.1
            h36.1v-24.1h-36.1v-36.1h60.2v-24.1h-60.2V87.4h96.3V424.6z"
        />
    </StyledSvg>
);
export const ModelView = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <path
            d="M387.6,309.1v142.2h70.2V213.7c0-4.5-3.7-8.2-8.2-8.2H344.3c-4.5,0-8.2,3.7-8.2,8.2v70.8H363
            C376.5,284.5,387.6,295.5,387.6,309.1z M406.3,248.8c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2v8.8c0,4.5-3.7,8.2-8.2,8.2
            s-8.2-3.7-8.2-8.2V248.8z M406.3,292.7c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2v8.8c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2V292.7z
                M406.3,336.6c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2v8.8c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2V336.6z M406.3,380.4
            c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2v8.8c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2V380.4z M371.2,248.8c0-4.5,3.7-8.2,8.2-8.2
            s8.2,3.7,8.2,8.2v8.8c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2V248.8z"
        />
        <path
            d="M371.2,451.2V309.1c0-4.5-3.7-8.2-8.2-8.2H239c-4.5,0-8.2,3.7-8.2,8.2v142.2h44.5v-27.5c0-4.5,3.7-8.2,8.2-8.2
            h35.1c4.5,0,8.2,3.7,8.2,8.2v27.5L371.2,451.2L371.2,451.2z M274.1,388.6c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2v-8.8
            c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2V388.6z M274.1,344.8c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2V336c0-4.5,3.7-8.2,8.2-8.2
            s8.2,3.7,8.2,8.2V344.8z M309.2,388.6c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2v-8.8c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2V388.6z
                M309.2,344.8c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2V336c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2V344.8z M327.9,336
            c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2v8.8c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2V336z M327.9,379.9c0-4.5,3.7-8.2,8.2-8.2
            s8.2,3.7,8.2,8.2v8.8c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2V379.9z"
        />
        <g>
            <circle cx="127.2" cy="93.7" r="32.9" />
            <path
                d="M138.6,142.2c-8.2-1.7-17.1-1.6-25.1,0.3c-54.6,6.4-72.4,69.6-67.4,117.8c2.2,20.8,35,21.1,32.8,0
                    c-1.8-17.6-1-44.1,8.3-62.9c0,22.9,0,45.8,0,68.7c0,0.8,0.1,1.6,0.1,2.3c0,0.4-0.1,0.7-0.1,1.1c0,54.5-0.1,109-2.3,163.5
                    c-1,24.2,36.6,24.1,37.5,0c1.8-42.7,2.2-85.4,2.3-128.2c1.8,0.1,3.6,0.1,5.3,0c0.1,42.7,0.5,85.5,2.3,128.1
                    c1,24.1,38.6,24.2,37.5,0c-2.3-54.5-2.3-109-2.3-163.5c0-1.7-0.2-3.2-0.6-4.7c-0.1-23.7-1-47.4-0.7-71
                    c11.2,18.9,12.2,47.8,10.3,66.5c-2.2,21,30.7,20.8,32.8,0C214.6,211.2,196,146.3,138.6,142.2z"
            />
        </g>
    </StyledSvg>
);
export const Library = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 448 448"
        version="1.1"
        viewBox="0 0 448 448"
        xmlSpace="preserve"
    >
        <path d="M241 432h16v16h-16v-16zM65 368h144v48H65v-48zM225 320h48v96h-48v-96z" />
        <path d="M241 304v-72c0-13.3-10.7-24-24-24H57c-13.3 0-24 10.7-24 24v72h24c4.4 0 8 3.6 8 8v40h144v-40c0-4.4 3.6-8 8-8h24z" />
        <path d="M1 320h48v96H1v-96zM17 432h16v16H17v-16zM41 144h192V32H41v112zm8-104h176v96H49V40z" />
        <path d="M65 56h144v64H65V56zM119.8 16h34.4L137 2.2 119.8 16zM329 432h112v16H329v-16zM411.1 0h-52.2l-35 112h122.2l-35-112zM377 128h16v288h-16V128z" />
        <path d="M329 432h112v16H329v-16z" />
    </StyledSvg>
);
export const Environment = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 512 512"
        version="1.1"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <path d="M302.4 271.8l-50 48.1c-3.4 3.2-8.1 4.7-12.7 4-4.6-.7-8.6-3.5-10.9-7.7l-35.1-64.4-113.3 77.6-78.8 161c-2.3 4.6-2 10.1.7 14.5S9.8 512 15 512h381c5.2 0 10-2.7 12.7-7.1 2.7-4.4 3-9.9.7-14.5l-107-218.6z" />
        <path d="M202.5 215.4c4.1 1 7.6 3.7 9.7 7.4l33.6 61.7 42.8-41.1-69.6-142c-2.5-5.1-7.7-8.4-13.5-8.4s-11 3.3-13.5 8.4l-84.9 173.3 83.4-57.1c3.5-2.4 7.9-3.2 12-2.2zM407 150c24.8 0 45-20.2 45-45s-20.2-45-45-45-45 20.2-45 45 20.2 45 45 45zM317 120h12c8.3 0 15-6.7 15-15s-6.7-15-15-15h-12c-8.3 0-15 6.7-15 15s6.7 15 15 15zM407 42c8.3 0 15-6.7 15-15V15c0-8.3-6.7-15-15-15s-15 6.7-15 15v12c0 8.3 6.7 15 15 15zM470 105c0 8.3 6.7 15 15 15h12c8.3 0 15-6.7 15-15s-6.7-15-15-15h-12c-8.3 0-15 6.7-15 15zM392 183v12c0 8.3 6.7 15 15 15s15-6.7 15-15v-12c0-8.3-6.7-15-15-15s-15 6.7-15 15zM341.3 149.5l-8.5 8.5c-5.9 5.9-5.9 15.4 0 21.2 2.9 2.9 6.8 4.4 10.6 4.4s7.7-1.5 10.6-4.4l8.5-8.5c5.9-5.9 5.9-15.4 0-21.2-5.9-5.8-15.4-5.8-21.2 0zM341.3 60.5c2.9 2.9 6.8 4.4 10.6 4.4s7.7-1.5 10.6-4.4c5.9-5.9 5.9-15.4 0-21.2l-8.5-8.5c-5.9-5.9-15.4-5.9-21.2 0-5.9 5.9-5.9 15.4 0 21.2l8.5 8.5zM462.2 64.9c3.8 0 7.7-1.5 10.6-4.4l8.5-8.5c5.9-5.9 5.9-15.4 0-21.2-5.9-5.9-15.4-5.9-21.2 0l-8.5 8.5c-5.9 5.9-5.9 15.4 0 21.2 2.9 2.9 6.7 4.4 10.6 4.4zM472.8 149.5c-5.9-5.9-15.4-5.9-21.2 0s-5.9 15.4 0 21.2l8.5 8.5c2.9 2.9 6.8 4.4 10.6 4.4s7.7-1.5 10.6-4.4c5.9-5.9 5.9-15.4 0-21.2l-8.5-8.5zM397.3 274a15.053 15.053 0 00-26.6 0l-17.5 33.4s93.7 190.3 99.2 204.6H497c5.2 0 10.1-2.7 12.8-7.2s2.9-10.1.5-14.7L397.3 274z" />
    </StyledSvg>
);
export const Tools = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 512 512"
        version="1.1"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <path d="M429.6 412.6v-47.7c0-8.4 6.8-15.2 15.2-15.2h58.9c-15-33.8-48.9-57.4-88.3-57.4H96.5C43.3 292.2 0 335.5 0 388.7s43.3 96.5 96.5 96.5h318.9c39.3 0 73.2-23.6 88.3-57.4h-58.9c-8.4 0-15.2-6.8-15.2-15.2zm-305-9.6H115v9.6c0 8.4-6.8 15.2-15.2 15.2-8.4 0-15.2-6.8-15.2-15.2V403H75c-8.4 0-15.2-6.8-15.2-15.2s6.8-15.2 15.2-15.2h9.6V363c0-8.4 6.8-15.2 15.2-15.2 8.4 0 15.2 6.8 15.2 15.2v9.6h9.6c8.4 0 15.2 6.8 15.2 15.2S133 403 124.6 403z" />
        <path d="M460.1 397.3h51.5c.3-2.8.4-5.7.4-8.6s-.1-5.8-.4-8.6h-51.5v17.2zM321 261.7h94.5c2.5 0 5 .1 7.5.2l-64.4-116.3c-2-3.6-6-6.5-10-7.3l-40.9-8c-5.4-1.1-11 2-13 7.2l-14.9 38.9c-1.5 3.8-1.1 8.8.9 12.4l40.3 72.9zM187.5 261.7l39-107.3c9-24.9 7.9-51.8-3.3-75.7-11.2-24-31-42.2-55.9-51.2-4-1.4-8.3.6-9.8 4.6L73.2 263.9c7.6-1.4 15.4-2.2 23.4-2.2h90.9z" />
    </StyledSvg>
);

export const PrevArrow = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 512 512"
        version="1.1"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <path d="M235.6 256.5L414.8 87.2c2-1.9 3.2-4.6 3.2-7.4 0-2.8-1.1-5.5-3-7.5L352.6 9.9c-4-4-10.4-4-14.5-.1L88.5 248.9c-2 1.9-3.2 4.7-3.2 7.5s1.2 5.6 3.2 7.5L338 503c2 1.9 4.6 2.9 7.2 2.9 2.6 0 5.4-1 7.3-3l62.4-62.4c1.9-1.9 3.1-4.7 3-7.5 0-2.8-1.3-5.5-3.2-7.4L235.6 256.5z" />
    </StyledSvg>
);
export const DoublePrevArrow = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 811 512"
        version="1.1"
        viewBox="0 0 811 512"
        xmlSpace="preserve"
    >
        <path d="M345.4 256.5L524.5 87.2c2-1.9 3.2-4.6 3.2-7.4s-1.1-5.5-3-7.5L462.4 9.9c-4-4-10.4-4-14.5-.1L198.2 248.9c-2 1.9-3.2 4.7-3.2 7.5s1.2 5.6 3.2 7.5L447.8 503c2 1.9 4.6 2.9 7.2 2.9s5.4-1 7.3-3l62.4-62.4c1.9-1.9 3.1-4.7 3-7.5 0-2.8-1.3-5.5-3.2-7.4L345.4 256.5z" />
        <path d="M596.5 256.5L775.8 87.2c2-1.9 3.2-4.6 3.2-7.4s-1.1-5.5-3-7.5L713.5 9.9c-4-4-10.4-4-14.5-.1L449.5 248.9c-2 1.9-3.2 4.7-3.2 7.5s1.2 5.6 3.2 7.5L699 503c2 1.9 4.6 2.9 7.2 2.9 2.6 0 5.4-1 7.3-3l62.4-62.4c1.9-1.9 3.1-4.7 3-7.5 0-2.8-1.3-5.5-3.2-7.4L596.5 256.5zM146.3 18.1c0-2.8-1.1-5.5-3.2-7.4-2-1.9-4.7-3-7.5-3l-88.2 2.4c-5.7.2-10.1 4.8-10 10.5l1.3 236.8-1.3 236.8c0 5.7 4.4 10.3 10 10.5l88.2 2.4c2.8 0 5.5-1.1 7.5-3s3.2-4.7 3.2-7.4l-.4-239.2.4-239.4z" />
    </StyledSvg>
);
export const NextArrow = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 512 512"
        version="1.1"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <path d="M267.7 256.3L88.5 425.6c-2 1.9-3.2 4.6-3.2 7.4 0 2.8 1.1 5.5 3 7.5l62.4 62.4c4 4 10.4 4 14.5.1l249.6-239.1c2-1.9 3.2-4.7 3.2-7.5s-1.2-5.6-3.2-7.5L165.3 9.8c-2-1.9-4.6-2.9-7.2-2.9s-5.4 1-7.3 3L88.4 72.3c-1.9 1.9-3.1 4.7-3 7.5 0 2.8 1.3 5.5 3.2 7.4l179.1 169.1z" />
    </StyledSvg>
);
export const DoubleNextArrow = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 741.59 500.01"
    >
        <path
            fill="#999"
            d="M471 257.29l-179.2 169.3a10.29 10.29 0 00-3.2 7.4 10.94 10.94 0 003 7.5l62.4 62.4a10.35 10.35 0 0014.5.1l249.6-239.1a10.39 10.39 0 000-15L368.56 10.79a10.48 10.48 0 00-7.2-2.9 10.21 10.21 0 00-7.3 3l-62.4 62.4a10.34 10.34 0 00-3 7.5 10.71 10.71 0 003.2 7.4z"
        />
        <path d="M219.76 257.29l-179.2 169.3a10.29 10.29 0 00-3.2 7.4 10.94 10.94 0 003 7.5l62.4 62.4a10.35 10.35 0 0014.5.1l249.6-239.1a10.39 10.39 0 000-15l-249.5-239.1a10.48 10.48 0 00-7.2-2.9 10.21 10.21 0 00-7.3 3l-62.4 62.4a10.34 10.34 0 00-3 7.5 10.71 10.71 0 003.2 7.4zM670 495.66a10.27 10.27 0 003.17 7.41 10.94 10.94 0 007.51 3l88.21-2.37A10.35 10.35 0 00779 493.23l-1.26-236.76L779 19.71a10.35 10.35 0 00-10.1-10.46l-88.21-2.36a10.89 10.89 0 00-7.51 3 10.29 10.29 0 00-3.18 7.39l.38 239.19z" />
    </StyledSvg>
);
export const Sun = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 45.2 45.2"
        version="1.1"
        viewBox="0 0 45.2 45.2"
        xmlSpace="preserve"
    >
        <path d="M22.6 11.3c-6.2 0-11.3 5.1-11.3 11.3s5.1 11.3 11.3 11.3c6.2 0 11.3-5.1 11.3-11.3s-5.1-11.3-11.3-11.3zM22.6 7.9c-1.2 0-2.2-1-2.2-2.2V2.2c0-1.2 1-2.2 2.2-2.2s2.2 1 2.2 2.2v3.5c0 1.3-1 2.2-2.2 2.2zM22.6 37.2c-1.2 0-2.2 1-2.2 2.2V43c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2v-3.5c0-1.3-1-2.3-2.2-2.3zM32.9 12.2c-.9-.9-.9-2.3 0-3.1l2.5-2.5c.9-.9 2.3-.9 3.1 0 .9.9.9 2.3 0 3.1L36 12.2c-.8.9-2.2.9-3.1 0zM12.2 32.9c-.9-.9-2.3-.9-3.1 0l-2.5 2.5c-.9.9-.9 2.3 0 3.1s2.3.9 3.1 0l2.5-2.5c.9-.8.9-2.2 0-3.1zM37.2 22.6c0-1.2 1-2.2 2.2-2.2H43c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2h-3.5c-1.3 0-2.3-1-2.3-2.2zM7.9 22.6c0-1.2-1-2.2-2.2-2.2H2.2c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h3.5c1.3 0 2.2-1 2.2-2.2zM32.9 32.9c.9-.9 2.3-.9 3.1 0l2.5 2.5c.9.9.9 2.3 0 3.1s-2.3.9-3.1 0L32.9 36c-.8-.8-.8-2.2 0-3.1zM12.2 12.2c.9-.9.9-2.3 0-3.1L9.7 6.6c-.9-.9-2.3-.9-3.1 0-.9.9-.9 2.3 0 3.1l2.5 2.5c.9.9 2.3.9 3.1 0z" />
    </StyledSvg>
);
export const Skybox = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 456.3 456.3"
        version="1.1"
        viewBox="0 0 456.3 456.3"
        xmlSpace="preserve"
    >
        <path
            d="M440 263.6c-4.6-.3-9.6-.4-14.8-.4-9.9 0-21.7.5-35.4 1.9l-1.2-51.7c5.4 1.9 11.1 2.9 17 2.9 28 0 50.7-22.7 50.7-50.7 0-16.1-7.5-31-20.3-40.6.4-2.5.6-5 .6-7.6 0-19-12.2-35.6-29.7-41.6 1.9-5 2.9-10.3 2.9-15.6 0-24.2-19.7-43.9-43.9-43.9S322 36 322 60.2v1.4c-22 2.4-39.2 21-39.2 43.7 0 11.1 4.1 21.6 11.5 29.6-10 9.5-15.7 22.6-15.7 36.6 0 28 22.7 50.7 50.7 50.7 6 0 11.8-1.1 17.3-3.1l-1.2 52.7c-27.3 5.5-59.3 14.1-95.9 27.5-75 27.4-134.4 61-163.2 78.6-19.7 12.1-36.6 23.6-48.9 33.5-18.3 14.7-19.2 19.6-18.6 23 .5 2.7 2.7 5.5 7.6 5.5h410.5c.4 0 1 .1 1.8.1 2.3 0 6.8-.4 10.7-3.4 3.2-2.4 7-7.2 7-16.5V280.5c-.1-7.3-2.9-16.1-16.4-16.9z"
            className="st0"
        />
        <path d="M227.6 274.7c2.7-1 4.6-3.7 4.9-6.6.2-2.5-.8-4.7-2.8-6-23.4-14.6-59.5-27.5-95.8-36.5l-1-43.6c3.7 1.1 7.5 1.7 11.4 1.7 21.6 0 39.2-17.6 39.2-39.2 0-12.2-5.6-23.5-15.1-30.9.3-1.7.4-3.4.4-5.1 0-14.3-8.9-26.9-21.9-31.8 1.2-3.5 1.8-7.2 1.8-10.9 0-18.8-15.3-34.1-34.1-34.1s-34 15.2-34.1 34C64 68 51.3 82.3 51.3 99.4c0 8.2 2.9 16 8.1 22.1-7.2 7.3-11.2 17-11.2 27.5 0 21.6 17.6 39.2 39.2 39.2 4 0 7.9-.6 11.6-1.8l-.7 31.6c-21.4-3.8-41.3-6-56.9-6C14 212 .1 218.3.1 230.8V385c0 6.1 3.7 8.8 7.3 8.8 2.8 0 5.1-1.6 5.9-2.3 1.7-1.4 3.4-2.8 5-4.1 13.3-10.6 31.3-22.9 52.1-35.7 27.5-16.9 84.7-49.4 157.2-77z" />
    </StyledSvg>
);
export const Objects = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 476.2 476.2"
    >
        <g>
            <path
                d="M78.7,249.2h64.7c-12.8-25.1-34.6-57.3-71.6-91.5c-7.2-6.7-16.9-10.5-26.6-10.5c-10.2,0-20.1,3.9-29.6,11.7
                    c-29.6,24.3-9.4,54.2-2.5,62.9c9.1,11.4,18.6,27.4,27,46.5C49.1,256.7,63,249.2,78.7,249.2z"
            />
            <path
                d="M460.5,158.9c-9.5-7.8-19.5-11.7-29.6-11.7c-9.7,0-19.4,3.8-26.6,10.5c-37,34.2-58.8,66.4-71.6,91.5h64.7
                    c15.7,0,29.7,7.5,38.6,19.1c8.4-19.1,17.9-35.1,27-46.5C469.9,213.2,490.1,183.2,460.5,158.9z"
            />
            <path
                d="M426,362.1v-64.3c0-15.8-12.8-28.6-28.6-28.6H78.7c-15.8,0-28.6,12.8-28.6,28.6v64.3
                    c0,15.8,12.8,28.6,28.6,28.6h318.7C413.2,390.8,426,377.9,426,362.1z"
            />
            <polygon
                className="st0"
                points="361.5,410.8 366.2,448.1 390.3,448.1 395,410.8"
            />
            <polygon
                className="st0"
                points="81.2,410.8 85.9,448.1 109.9,448.1 114.7,410.8"
            />
            <path
                d="M310.4,249.2c13.7-29.7,37.8-66.9,80.3-106.2c6-5.5,12.9-9.7,20.4-12.4c-26.9-60-94.3-102.6-173-102.6
                    S91.9,70.7,65,130.6c7.5,2.7,14.4,6.9,20.4,12.4c42.5,39.3,66.6,76.4,80.3,106.2L310.4,249.2L310.4,249.2z"
            />
        </g>
    </StyledSvg>
);
export const Materials = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 182.8 182.8"
    >
        <path
            d="M30.8,0H90L59.5,28.7L30.8,0z M104.2,88.9L8.7,182.8h64l62.7-62.7L104.2,88.9z M64.7,44.5
            c-0.1,0.1-0.3,0.3-0.5,0.4L27.7,79.3l32.1,32.1L173.2,0h-61.2L64.8,44.4C64.8,44.4,64.7,44.4,64.7,44.5z M147.6,182.8l-26.8-26.8
            l-26.8,26.8H147.6z M151.3,125.4l-19.9,19.9l37.4,37.4h6.5c4.1,0,7.5-3.4,7.5-7.5V93.9L151.3,125.4z M11.3,74.2
            c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.5-0.4,0.7-0.6L48.6,39L9.6,0H7.5C3.4,0,0,3.4,0,7.5v77.3L11.3,74.2z M16.8,89.6L0,105.4v64.9
            L49.1,122L16.8,89.6z M182.8,72.7V11.6l-67.9,66.8l31.1,31.1L182.8,72.7z"
        />
    </StyledSvg>
);
export const Map = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 612 612"
    >
        <g>
            <path
                d="M516.3,337.5l94.2,193.6c3.8,7.9-0.2,14.3-9,14.3H10.4c-8.8,0-12.8-6.4-9-14.3l94.2-193.6
                c1.5-3.1,5.5-5.6,9-5.6h80.8c2.5,0,5.9,1.6,7.5,3.4c5.5,6.3,11.1,12.5,16.6,18.6c5.3,5.8,10.6,11.7,15.9,17.7h-95.9
                c-3.4,0-7.5,2.5-9,5.6L58.2,505.6h495.7l-62.4-128.2c-1.5-3.1-5.5-5.6-9-5.6h-96.1c5.3-6,10.6-11.9,15.9-17.7
                c5.6-6.1,11.2-12.3,16.7-18.7c1.6-1.9,5-3.4,7.5-3.4h81C510.8,331.9,514.8,334.4,516.3,337.5z M444.5,205.2
                c0,105.8-88.1,125.6-129.5,227.3c-3.4,8.3-15,8.2-18.4,0c-37.4-91.7-112.6-116.8-127-198.4c-14.2-80.4,41.5-159.1,122.7-166.8
                C375,59.4,444.5,124.2,444.5,205.2z M379.1,205.2c0-40.4-32.8-73.2-73.2-73.2s-73.2,32.8-73.2,73.2c0,40.4,32.8,73.2,73.2,73.2
                S379.1,245.7,379.1,205.2z"
            />
        </g>
    </StyledSvg>
);
export const Perspectives = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 53.5 53.5"
    >
        <g>
            <g>
                <circle cx="42.2" cy="7.3" r="4.2" />
                <path
                    d="M43.6,13.5c-1-0.2-2.2-0.2-3.2,0c-6.9,0.8-9.2,8.8-8.6,15c0.3,2.6,4.4,2.7,4.2,0c-0.2-2.2-0.1-5.6,1.1-8
                    c0,2.9,0,5.8,0,8.7c0,0.1,0,0.2,0,0.3v0.1c0,6.9,0,13.8-0.3,20.8c-0.1,3.1,4.6,3.1,4.8,0c0.2-5.4,0.3-10.8,0.3-16.3
                    c0.2,0,0.5,0,0.7,0c0,5.4,0.1,10.8,0.3,16.3c0.1,3.1,4.9,3.1,4.8,0c-0.3-6.9-0.3-13.8-0.3-20.8c0-0.2,0-0.4-0.1-0.6
                    c0-3-0.1-6-0.1-9c1.4,2.4,1.5,6.1,1.3,8.4c-0.3,2.7,3.9,2.6,4.2,0C53.3,22.2,50.9,14,43.6,13.5z"
                />
            </g>
        </g>
        <g>
            <g>
                <circle cx="7.9" cy="27.7" r="2.3" />
                <path
                    d="M8.7,31.1C8.2,31,7.5,31,7,31.1c-3.8,0.4-5.1,4.9-4.7,8.2c0.2,1.5,2.4,1.5,2.3,0c-0.1-1.2-0.1-3.1,0.6-4.4
                    c0,1.6,0,3.2,0,4.8c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,3.8,0,7.6-0.2,11.4c-0.1,1.7,2.6,1.7,2.6,0c0.1-3,0.2-6,0.2-8.9
                    c0.1,0,0.2,0,0.4,0c0,3,0,6,0.2,8.9c0.1,1.7,2.7,1.7,2.6,0c-0.2-3.8-0.2-7.6-0.2-11.4c0-0.1,0-0.2,0-0.3c0-1.7-0.1-3.3,0-5
                    c0.8,1.3,0.9,3.3,0.7,4.6c-0.2,1.5,2.1,1.5,2.3,0C14,35.9,12.7,31.4,8.7,31.1z"
                />
            </g>
        </g>
        <g>
            <path
                d="M23.6,51.6l4.9-4.9c0.2-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9c-0.2-0.2-0.5-0.4-0.9-0.4h-2.4V25.2h2.4
                c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9l-4.9-4.9c-0.2-0.2-0.5-0.4-0.9-0.4c-0.3,0-0.6,0.1-0.9,0.4
                l-4.8,5c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.6,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4h2.4v19.4h-2.4c-0.3,0-0.6,0.1-0.9,0.4
                c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.6,0.4,0.9l4.9,4.9c0.2,0.2,0.5,0.4,0.9,0.4C23.1,51.9,23.4,51.8,23.6,51.6z"
            />
        </g>
    </StyledSvg>
);
export const Distance = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 503.4 391.8"
    >
        <g>
            <circle cx="81.9" cy="32.9" r="32.9" />
            <path
                d="M97.6,142.2a59,59,0,0,0-25.1.3C17.9,148.9.1,212.1,5.1,260.3c2.2,20.8,35,21.1,32.8,0-1.8-17.6-1-44.1,8.3-62.9v68.7c0,.8.1,1.6.1,2.3,0,.4-.1.7-.1,1.1,0,54.5-.1,109-2.3,163.5-1,24.2,36.6,24.1,37.5,0,1.8-42.7,2.2-85.4,2.3-128.2a46.84,46.84,0,0,0,5.3,0c.1,42.7.5,85.5,2.3,128.1,1,24.1,38.6,24.2,37.5,0-2.3-54.5-2.3-109-2.3-163.5a17.8,17.8,0,0,0-.6-4.7c-.1-23.7-1-47.4-.7-71,11.2,18.9,12.2,47.8,10.3,66.5-2.2,21,30.7,20.8,32.8,0C173.6,211.2,155,146.3,97.6,142.2Z"
                transform="translate(-4.3 -60.8)"
            />
        </g>
        <rect x="447.7" y="1.5" width="55.7" height="390.3" />
        <rect x="221.7" y="190.14" width="165" height="54.25" />
        <path
            d="M386.64,278l-54.89,51.86a2.94,2.94,0,0,0,0,4.44l19.17,19.17a3.17,3.17,0,0,0,4.44,0l76.48-73.25a3.21,3.21,0,0,0,1-2.22,2.87,2.87,0,0,0-1-2.22l-76.68-73.25a3,3,0,0,0-2.22-.81,3.27,3.27,0,0,0-2.22,1l-19.17,19.17a2.94,2.94,0,0,0,0,4.44Z"
            transform="translate(-4.3 -60.8)"
        />
        <path
            d="M229.6,278.11l54.88-51.86a2.94,2.94,0,0,0,0-4.44l-19.17-19.17a3.17,3.17,0,0,0-4.44,0l-76.48,73.25a3.26,3.26,0,0,0-1,2.22,2.89,2.89,0,0,0,1,2.22l76.69,73.25a3,3,0,0,0,2.22.81,3.25,3.25,0,0,0,2.22-1l19.17-19.17a2.94,2.94,0,0,0,0-4.44Z"
            transform="translate(-4.3 -60.8)"
        />
    </StyledSvg>
);
export const TapeMeasure = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 341.14"
    >
        <path
            d="M512,403.87V244.4a15.85,15.85,0,0,0-15.89-15.89H17.58A15.85,15.85,0,0,0,1.68,244.4V403.87a15.85,15.85,0,0,0,15.9,15.89H496A16,16,0,0,0,512,403.87Zm-478.53-16V260.3H81.28V340H113.2V260.3H161v47.81h31.92V260.3h47.94V340h31.79V260.3h47.81v47.81H352.4V260.3h47.81V340h31.92V260.3h47.95V387.84Z"
            transform="translate(0 -78.62)"
        />
        <rect
            x="318.6"
            y="-34.02"
            width="29.84"
            height="325.71"
            transform="translate(204.69 383.74) rotate(-90)"
        />
        <path
            d="M481.75,128.81l-36.06,34.07a2.11,2.11,0,0,0-.65,1.49,2.2,2.2,0,0,0,.61,1.51l12.56,12.56a2.08,2.08,0,0,0,2.91,0l50.24-48.12a2.1,2.1,0,0,0,0-3L461.14,79.2a2.1,2.1,0,0,0-1.44-.58,2,2,0,0,0-1.47.6L445.67,91.78a2.1,2.1,0,0,0-.61,1.51,2.17,2.17,0,0,0,.65,1.49Z"
            transform="translate(0 -78.62)"
        />
        <rect
            x="163.55"
            y="-34.02"
            width="29.84"
            height="325.71"
            transform="translate(307.31 -128.26) rotate(90)"
        />
        <path
            d="M30.25,128.85,66.31,94.78A2.07,2.07,0,0,0,67,93.29a2.24,2.24,0,0,0-.61-1.51L53.79,79.23a2.06,2.06,0,0,0-2.91,0L.64,127.32A2.14,2.14,0,0,0,0,128.83a2.11,2.11,0,0,0,.64,1.51l50.22,48.12a2.11,2.11,0,0,0,1.44.59,2.06,2.06,0,0,0,1.47-.61l12.56-12.56a2.08,2.08,0,0,0,.61-1.5,2.16,2.16,0,0,0-.65-1.49Z"
            transform="translate(0 -78.62)"
        />
    </StyledSvg>
);
export const Measuring = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 341.14"
    >
        <path
            d="M512,403.87V244.4a15.85,15.85,0,0,0-15.89-15.89H17.58A15.85,15.85,0,0,0,1.68,244.4V403.87a15.85,15.85,0,0,0,15.9,15.89H496A16,16,0,0,0,512,403.87Zm-478.53-16V260.3H81.28V340H113.2V260.3H161v47.81h31.92V260.3h47.94V340h31.79V260.3h47.81v47.81H352.4V260.3h47.81V340h31.92V260.3h47.95V387.84Z"
            transform="translate(0 -78.62)"
        />
        <rect
            x="318.6"
            y="-34.02"
            width="29.84"
            height="325.71"
            transform="translate(204.69 383.74) rotate(-90)"
        />
        <path
            d="M481.75,128.81l-36.06,34.07a2.11,2.11,0,0,0-.65,1.49,2.2,2.2,0,0,0,.61,1.51l12.56,12.56a2.08,2.08,0,0,0,2.91,0l50.24-48.12a2.1,2.1,0,0,0,0-3L461.14,79.2a2.1,2.1,0,0,0-1.44-.58,2,2,0,0,0-1.47.6L445.67,91.78a2.1,2.1,0,0,0-.61,1.51,2.17,2.17,0,0,0,.65,1.49Z"
            transform="translate(0 -78.62)"
        />
        <rect
            x="163.55"
            y="-34.02"
            width="29.84"
            height="325.71"
            transform="translate(307.31 -128.26) rotate(90)"
        />
        <path
            d="M30.25,128.85,66.31,94.78A2.07,2.07,0,0,0,67,93.29a2.24,2.24,0,0,0-.61-1.51L53.79,79.23a2.06,2.06,0,0,0-2.91,0L.64,127.32A2.14,2.14,0,0,0,0,128.83a2.11,2.11,0,0,0,.64,1.51l50.22,48.12a2.11,2.11,0,0,0,1.44.59,2.06,2.06,0,0,0,1.47-.61l12.56-12.56a2.08,2.08,0,0,0,.61-1.5,2.16,2.16,0,0,0-.65-1.49Z"
            transform="translate(0 -78.62)"
        />
    </StyledSvg>
);
export const Edit = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 511.57 509.91"
    >
        <g>
            <path
                d="M332.28,175.25A15.67,15.67,0,0,1,343.36,171h.23l.76.05c.32,0,.65,0,1,.1h0a15.63,15.63,0,0,1,8.29,3.82l158.1,140.27V17.1c0-9.08-7.21-16.52-16-16.52H29.53L235.6,266.34ZM274.52,73.75c25.79,0,46.69,21.57,46.69,48.18s-20.9,48.19-46.69,48.19-46.68-21.58-46.68-48.19S248.74,73.75,274.52,73.75Z"
                transform="translate(-0.18 -0.58)"
            />
            <polygon points="201.84 342.13 493.45 342.13 343.26 208.89 201.84 342.13" />
            <path
                d="M16.63,342.72h137.9l57.38-54.06L.67,16.25c0,.28,0,.56,0,.85V326.2C.63,335.28,7.83,342.72,16.63,342.72Z"
                transform="translate(-0.18 -0.58)"
            />
        </g>
        <path
            d="M499.75,442H410.62V429.05a12,12,0,0,0-12-12H375.9a12,12,0,0,0-12,12V442H12.18a12,12,0,0,0-12,12v19.54a12,12,0,0,0,12,12H363.9v12.95a12,12,0,0,0,12,12h22.72a12,12,0,0,0,12-12V485.54h89.13a12,12,0,0,0,12-12V454A12,12,0,0,0,499.75,442Z"
            transform="translate(-0.18 -0.58)"
        />
    </StyledSvg>
);
export const Photo = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 100 100"
        version="1.1"
        viewBox="0 0 100 100"
        xmlSpace="preserve"
    >
        <path d="M50 40c-8.3 0-15 6.7-15 15s6.7 15 15 15 15-6.7 15-15-6.7-15-15-15zm40-15H78c-1.7 0-3.4-1.3-3.9-2.8L71 12.9c-.6-1.6-2.3-2.9-4-2.9H33c-1.6 0-3.4 1.3-3.9 2.8L26 22.1c-.6 1.6-2.4 2.9-4 2.9H10C4.5 25 0 29.5 0 35v45c0 5.5 4.5 10 10 10h80c5.5 0 10-4.5 10-10V35c0-5.5-4.5-10-10-10zM50 80c-13.8 0-25-11.2-25-25s11.2-25 25-25 25 11.2 25 25-11.2 25-25 25zm36.5-38c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5z" />
    </StyledSvg>
);
export const Living = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 473.5 473.5"
    >
        <g>
            <g>
                <path
                    d="M75.2,244.7v-58.6c0-10.5-8.6-19.1-19.1-19.1H19.1C8.7,167,0.3,175.3,0,185.6c1.2,10.4,13.2,115.5,15,152.6
                c0.5,11.2,8.8,19.6,19.1,19.6h11c-3.1-5.7-4.8-12.2-4.8-19.1v-54C40.3,264.2,55.5,247.3,75.2,244.7z"
                />
                <path
                    d="M412.1,338.7v-54c0-10.5-8.6-19.1-19.1-19.1H80.5c-10.5,0-19.1,8.6-19.1,19.1v54c0,10.5,8.6,19.1,19.1,19.1
                H393C403.6,357.7,412.1,349.2,412.1,338.7z"
                />
                <path
                    d="M417.4,167c-10.5,0-19.1,8.6-19.1,19.1v58.6c19.7,2.6,35,19.5,35,39.9v54c0,6.9-1.7,13.4-4.8,19.1h11
                c10.3,0,18.5-8.4,19.1-19.6c1.8-37.1,13.8-142.2,15-152.6c-0.3-10.3-8.7-18.5-19.1-18.5L417.4,167L417.4,167z"
                />
                <path
                    d="M68.3,377c-3.9,1.3-8,1.9-12.2,1.9h-9.5l-15.4,58.8c-1.5,5.7,1.9,11.5,7.6,12.9c0.9,0.2,1.8,0.4,2.7,0.4
                c4.7,0,9-3.1,10.2-7.9l17.3-65.8C68.7,377.1,68.5,377.1,68.3,377z"
                />
                <path
                    d="M427,378.9h-9.5c-4.2,0-8.3-0.6-12.2-1.9c-0.2,0.1-0.4,0.1-0.6,0.2l17.3,65.8c1.3,4.8,5.5,7.9,10.2,7.9
                c0.9,0,1.8-0.1,2.7-0.4c5.7-1.5,9-7.3,7.6-12.9L427,378.9z"
                />
            </g>
            <path
                d="M413.2,29.8c-4.4-4.6-10.5-7.2-16.9-7.2H77.2c-6.3,0-12.5,2.6-16.9,7.2c-4.1,4.2-6.2,9.6-6,15.3
            c0.6,16.8,3.7,58.5,7,101.2c19.7,2.6,35.1,19.5,35.1,39.9v58.3h280.8v-58.3c0-20.2,14.9-36.9,34.3-39.8c3.4-42.8,7-84.4,7.7-101.3
            C419.4,39.4,417.3,34,413.2,29.8z M190.7,182.7h-6.4c-5.9,0-10.6-4.7-10.6-10.6c0-5.9,4.7-10.6,10.6-10.6h6.4
            c5.9,0,10.6,4.7,10.6,10.6C201.3,178,196.5,182.7,190.7,182.7z M190.7,104.3h-6.4c-5.9,0-10.6-4.7-10.6-10.6
            c0-5.9,4.7-10.6,10.6-10.6h6.4c5.9,0,10.6,4.7,10.6,10.6C201.3,99.6,196.5,104.3,190.7,104.3z M289.2,182.7h-6.4
            c-5.9,0-10.6-4.7-10.6-10.6c0-5.9,4.7-10.6,10.6-10.6h6.4c5.9,0,10.6,4.7,10.6,10.6C299.8,178,295.1,182.7,289.2,182.7z
            M289.2,104.3h-6.4c-5.9,0-10.6-4.7-10.6-10.6c0-5.9,4.7-10.6,10.6-10.6h6.4c5.9,0,10.6,4.7,10.6,10.6
            C299.8,99.6,295.1,104.3,289.2,104.3z"
            />
        </g>
    </StyledSvg>
);
export const Dining = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 302.5 302.5"
    >
        <g>
            <g>
                <path
                    d="M163.8,222.9c38.7,0,70.3-31.5,70.3-70.3s-31.5-70.3-70.3-70.3c-38.7,0-70.3,31.5-70.3,70.3
                    C93.6,191.4,125.1,222.9,163.8,222.9z"
                />
                <path
                    d="M163.8,241.8c49.2,0,89.1-39.9,89.1-89.1c0-49.2-39.9-89.1-89.1-89.1c-49.2,0-89.1,39.9-89.1,89.1
                    C74.7,201.9,114.6,241.8,163.8,241.8z M163.8,77.2c41.6,0,75.5,33.9,75.5,75.5c0,41.6-33.9,75.5-75.5,75.5s-75.5-33.9-75.5-75.5
                    C88.4,111,122.2,77.2,163.8,77.2z"
                />
                <path
                    d="M302.5,250.1l-5.2-200c0-4.3-3.5-7.8-7.8-7.8c-1.2,0-2.2,0.3-3.2,0.7v-0.1c0,0-0.2,0.1-0.5,0.3
                    c-0.7,0.4-1.4,0.9-2,1.5c-5.7,4.7-23.9,23.8-25.4,49.6c-2.3,39.4,16.8,55.3,23.3,69.9l-5.2,85.8c0,4.3,8.7,7.8,13,7.8
                    C293.8,257.9,302.5,254.4,302.5,250.1z"
                />
                <path
                    d="M13.6,128.2l13.5,6.7c1.5,0.8,3.2,1.3,5,1.7l-5.2,117c0,3.6,8.7,6.5,13,6.5c4.3,0,13-2.9,13-6.5l-5.2-117.6
                    c1.8-0.6,3.6-1.3,5.1-2.2l10-6.1c7.2-4.4,12.6-14,12.6-22.5V52.9c0-4.3-3.5-7.8-7.8-7.8c-4.3,0-7.8,3.5-7.8,7.8v47.2h-4.3V52.9
                    c0-4.3-3.5-7.8-7.8-7.8c-4.3,0-7.8,3.5-7.8,7.8v47.2h-4.3V52.9c0-4.3-3.5-7.8-7.8-7.8c-4.3,0-7.8,3.5-7.8,7.8v47.2h-4.3V54.6
                    c0-4.3-3.5-7.8-7.8-7.8c-4.3,0-7.8,3.5-7.8,7.8v51.6C0,114.9,5.9,124.3,13.6,128.2z"
                />
            </g>
        </g>
    </StyledSvg>
);
export const Kitchen = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <path
                d="M139.7,39.3c-12.6,14-12.6,35.3,0,49.4c0.3,0.3,0.6,0.5,0.9,0.7c4.8,6.9,4.5,16.2-0.7,22.9
            c-0.3,0.3-0.6,0.6-0.9,1l-0.1,0.1c-2,2.8-2.3,6.4-0.8,9.5s4.7,5,8.1,5c2.5,0,4.9-1,6.7-2.9c12.5-14.1,12.4-35.3-0.1-49.3
            c-0.3-0.3-0.6-0.5-0.9-0.7c-4.6-6.7-4.6-15.4,0-22.1c0.3-0.2,0.6-0.5,0.9-0.8c12.5-13.9,12.6-34.9,0.4-49
            c-3.1-3.7-8.5-4.3-12.4-1.4c-4,3-4.9,8.8-1.8,12.8c0.3,0.4,0.5,0.7,0.9,1c5.2,6.6,5.5,15.9,0.7,22.9C140.3,38.8,140,39,139.7,39.3z
            "
            />
            <path
                d="M187.7,39.3c-12.6,14.1-12.6,35.3,0,49.4c0.3,0.3,0.6,0.5,0.9,0.8c4.8,6.9,4.5,16.2-0.7,22.9
            c-0.3,0.3-0.6,0.6-0.9,1l-0.1,0.1c-2,2.8-2.3,6.4-0.8,9.5s4.7,5,8.1,5c2.5,0,5-1,6.7-2.9c12.5-14.1,12.4-35.3-0.1-49.3
            c-0.3-0.3-0.6-0.5-0.9-0.8c-4.5-6.7-4.5-15.4,0-22.1c0.3-0.2,0.6-0.5,0.9-0.8c12.4-13.9,12.6-34.9,0.4-49
            c-3.1-3.6-8.5-4.2-12.4-1.4c-4,3-4.9,8.8-1.8,12.8c0.3,0.4,0.5,0.7,0.9,1c5.2,6.6,5.5,15.9,0.7,22.9C188.3,38.8,188,39,187.7,39.3
            L187.7,39.3z"
            />
            <path
                d="M233.4,39.3c-12.6,14.1-12.6,35.3,0,49.4c0.3,0.3,0.6,0.5,0.9,0.8c4.8,6.9,4.5,16.2-0.7,22.9
            c-0.3,0.3-0.6,0.6-0.9,1l-0.1,0.1c-2,2.8-2.3,6.4-0.8,9.5s4.7,5,8.1,5c2.5,0,5-1,6.7-2.9c12.5-14.1,12.4-35.3-0.1-49.3
            c-0.3-0.3-0.6-0.5-0.9-0.8c-4.5-6.7-4.5-15.4,0-22.1c0.3-0.2,0.6-0.5,0.9-0.8c12.4-13.9,12.6-34.9,0.4-49
            c-3.1-3.6-8.5-4.2-12.4-1.4c-4,3-4.9,8.8-1.8,12.8c0.3,0.4,0.5,0.7,0.9,1c5.2,6.6,5.5,15.9,0.7,22.9C234,38.8,233.7,39,233.4,39.3
            L233.4,39.3z"
            />
            <path
                d="M410,62.3c10-22.1,0.2-48.1-21.9-58.2c-0.2-0.1-0.5-0.2-0.6-0.3c-10.9-4.8-23.2-4.9-34.2-0.4
            c-11,4.5-19.7,13.3-24.1,24.3l-41.9,137h62.8L410,62.3z M348.8,46.6c6-13.9,18.5-21.9,27.7-17.8c9.2,4,11.9,18.7,5.8,32.5
            s-18.5,21.9-27.8,17.8C345.1,75.1,342.7,60.5,348.8,46.6z"
            />
            <path
                id="Shape_4_"
                d="M411.4,219.4h45.7c10.1,0,18.3-8.2,18.3-18.3c0-10.1-8.2-18.3-18.3-18.3h-45.7V219.4z"
            />
            <path
                id="Shape_5_"
                d="M502.9,429.7c0-10.1-8.2-18.3-18.3-18.3H27.4c-10.1,0-18.3,8.2-18.3,18.3V512h493.7V429.7z
            M310.9,475.4c0,5-4.1,9.1-9.1,9.1h-91.4c-5,0-9.1-4.1-9.1-9.1V448c0-5,4.1-9.1,9.1-9.1h91.4c5,0,9.1,4.1,9.1,9.1V475.4z
            M370.3,484.6c-12.6,0-22.9-10.2-22.9-22.9c0-12.6,10.2-22.9,22.9-22.9c12.6,0,22.9,10.2,22.9,22.9
            C393.1,474.3,382.9,484.6,370.3,484.6z M452.6,484.6c-12.6,0-22.9-10.2-22.9-22.9c0-12.6,10.2-22.9,22.9-22.9
            c12.6,0,22.9,10.2,22.9,22.9C475.4,474.3,465.2,484.6,452.6,484.6z"
            />
            <path
                id="Shape_6_"
                d="M54.9,219.4h45.7v-36.6H54.9c-10.1,0-18.3,8.2-18.3,18.3C36.6,211.2,44.8,219.4,54.9,219.4z"
            />
            <path
                id="Shape_7_"
                d="M164.6,393.1h182.9c25.2,0,45.7-20.5,45.7-45.7V182.9H118.9v164.6
            C118.9,372.7,139.3,393.1,164.6,393.1z M137.1,210.3c0-5,4.1-9.1,9.1-9.1s9.1,4.1,9.1,9.1v9.1c0,5-4.1,9.1-9.1,9.1
            s-9.1-4.1-9.1-9.1V210.3z M137.1,256c0-5,4.1-9.1,9.1-9.1s9.1,4.1,9.1,9.1v91.4c0,5-4.1,9.1-9.1,9.1s-9.1-4.1-9.1-9.1V256z"
            />
        </g>
    </StyledSvg>
);
export const Working = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 388.2 388.2"
    >
        <g>
            <g>
                <g>
                    <path
                        d="M381.3,209.5c-0.6-2-1.8-3.8-3.4-5.1c-14.1-10.9-28.5-16.8-42.9-18.2c0,0-1.9-0.3-6.5-0.3
                        c-4.6,0-6.9,0.3-6.9,0.3c-13.8,1.6-27.6,7.7-41.2,18.2c-1.7,1.3-2.9,3.1-3.4,5.1c-6.7,23.1-8.4,45.2-5.6,69.7
                        c2.5,21.6,8.5,43.3,15.4,67.4c0.6,2,1.8,3.8,3.4,5.1c10.7,8.2,24.5,12.7,38.9,12.7s28.3-4.5,38.9-12.7c1.7-1.3,2.9-3.1,3.4-5.1
                        c7-24.1,12.9-45.8,15.4-67.4C389.7,254.7,388,232.5,381.3,209.5z M337.7,249.6c0,4.8-3.9,8.6-8.6,8.6s-8.6-3.9-8.6-8.6v-35.9
                        c0-4.8,3.9-8.6,8.6-8.6s8.6,3.9,8.6,8.6V249.6z"
                    />
                    <path
                        d="M263.6,341.4h-15.5l-12.8-43.5h26.1c-0.8-4.7-1.5-9.3-2.1-14c-1.6-14.2-1.8-27.7-0.6-41H30v-192h310v123.2
                        c10.1,1,20.1,4,30,8.8v-147c0-8.3-6.7-15-15-15H15c-8.3,0-15,6.7-15,15v247c0,8.3,6.7,15,15,15h119.7l-12.8,43.5h-15.5
                        c-3.9,0-7.1,3.2-7.1,7.1v11.7c0,3.9,3.2,7.1,7.1,7.1h157.3c3.9,0,7.1-3.2,7.1-7.1v-11.7C270.8,344.5,267.6,341.4,263.6,341.4z"
                    />
                </g>
            </g>
        </g>
    </StyledSvg>
);
export const Corridor = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <path
            d="M476.4,331.5l-178-117.5c31.7-15.7,53.6-48.2,53.6-85.9c0-52.9-43.1-96-96-96s-96,43.1-96,96h64
        c0-17.6,14.4-32,32-32s32,14.4,32,32s-14.4,32-32,32c-17.7,0-32,14.3-32,32v15.1L35.6,331.8v0C13.3,346.6,0,372,0,399.8
        c0,21.4,7.9,41.6,22.2,56.7C36.5,471.7,55.5,480,75.7,480h0.1l360.6-0.3c41.7,0,75.7-36,75.7-80.2
        C512,371.6,498.7,346.2,476.4,331.5L476.4,331.5z M436.3,416H75.7c-1.6,0-4.1-0.4-7-3.4c-3.9-4.1-4.7-9.2-4.7-12.8
        c0-6.3,2.6-11.8,6.9-14.6L256,262.7l185.1,122.2c4.3,2.9,6.9,8.3,6.9,14.6C448,408.8,441.8,416,436.3,416L436.3,416z"
        />
    </StyledSvg>
);
export const Sleeping = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <g>
                    <path
                        d="M27.8,299.1c0.2,0,4-0.4,4.2-0.5l449.7,0.2c0.2,0,2.4,0.3,2.6,0.3c0.3,0,0.6,0,0.8,0c0.1,0,0.3,0,0.4,0
                    c5.9,0,10.7-4.8,10.7-10.7c0-2.2-0.6-4.1-1.7-5.8l-25.1-81.6V74.7c0-29.4-23.9-53.3-53.3-53.3H96c-29.4,0-53.3,23.9-53.3,53.3
                    v126.4l-25.9,84.3c-1,3.4-0.4,7.1,1.9,9.8C20.8,297.9,24.2,299.4,27.8,299.1z M74.7,192H93l9.9-39.8C106.5,138,119.3,128,134,128
                    h68.7c17.6,0,32,14.4,32,32v32h42.7v-32c0-17.6,14.4-32,32-32H378c14.7,0,27.5,10,31.1,24.2L419,192h18.3
                    c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7h-18.7c-1.1,3.2-2.6,6.3-4.8,9c-6.1,7.8-15.3,12.3-25.2,12.3h-79.3
                    c-13.9,0-25.6-8.9-30-21.3h-46.6c-4.4,12.4-16.1,21.3-30,21.3h-79.3c-9.9,0-19.1-4.5-25.2-12.3c-2.2-2.8-3.6-5.8-4.8-9H74.7
                    c-5.9,0-10.7-4.8-10.7-10.7S68.8,192,74.7,192z"
                    />
                    <path
                        d="M480,320H32c-17.6,0-32,14.4-32,32v128c0,5.9,4.8,10.7,10.7,10.7h42.7c5.9,0,10.7-4.8,10.7-10.7v-32h384v32
                    c0,5.9,4.8,10.7,10.7,10.7h42.7c5.9,0,10.7-4.8,10.7-10.7V352C512,334.4,497.6,320,480,320z"
                    />
                </g>
            </g>
        </g>
    </StyledSvg>
);
export const Bathroom = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path d="M480,255.1H32c-17.6,0-32,14.4-32,32s14.4,32,32,32h448c17.6,0,32-14.4,32-32S497.6,255.1,480,255.1z" />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M482.9,298.1c-5.8-1.6-11.6,1.7-13.2,7.3l-0.8,2.9H43.1l-0.9-2.9c-1.6-5.7-7.4-8.9-13.2-7.3
                c-5.7,1.6-8.9,7.5-7.3,13.2l29.4,102.9c9.1,31.9,38.6,54.2,71.8,54.2h266.1c33.2,0,62.7-22.3,71.8-54.2l29.4-102.9
                C491.9,305.7,488.6,299.7,482.9,298.1z M122.9,425.7c-14.2,0-26.9-9.5-30.8-23.2L78.3,354c-1.6-5.7,1.7-11.6,7.3-13.2
                c5.7-1.6,11.6,1.7,13.2,7.3l13.8,48.5c1.3,4.5,5.5,7.7,10.3,7.7c5.9,0,10.7,4.8,10.7,10.7C133.6,421,128.8,425.7,122.9,425.7z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M143.4,448.2c-5.3-2.6-11.6-0.5-14.3,4.8l-21.3,42.7c-2.6,5.3-0.5,11.7,4.8,14.3c1.6,0.8,3.2,1.1,4.8,1.1
                c3.9,0,7.7-2.2,9.5-5.9l21.3-42.7C150.8,457.2,148.7,450.8,143.4,448.2z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M404.2,495.6L382.9,453c-2.6-5.3-9-7.4-14.3-4.8c-5.3,2.6-7.4,9-4.8,14.3l21.3,42.7c1.9,3.7,5.6,5.9,9.5,5.9
                c1.6,0,3.2-0.4,4.8-1.1C404.7,507.3,406.8,500.9,404.2,495.6z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M428.5,0.9c-10.9,0-21.1,4.2-28.8,11.9l-33.9,33.9c-4.2,4.2-4.2,10.9,0,15.1s10.9,4.2,15.1,0L414.7,28
                c3.6-3.7,8.5-5.7,13.8-5.7c10.7,0,19.4,8.7,19.4,19.4v224c0,5.9,4.8,10.7,10.7,10.7s10.7-4.8,10.7-10.7v-224
                C469.4,19.2,451.1,0.9,428.5,0.9z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M391.6,57.4l-21.3-21.3c-2.4-2.4-5.8-3.6-9.3-3l-53.3,8.7c-3.9,0.6-7.1,3.4-8.4,7.2
                c-1.3,3.8-0.3,7.9,2.5,10.8l64,65.9c2,2.1,4.8,3.2,7.6,3.2c1,0,2-0.1,3-0.4c3.8-1.1,6.7-4.2,7.4-8.1L394.5,67
                C395.2,63.6,394.1,60,391.6,57.4z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M252.9,130.2c-4.2-4.2-10.9-4.2-15.1,0l-21.3,21.3c-4.2,4.2-4.2,10.9,0,15.1c2.1,2.1,4.8,3.1,7.6,3.1
                s5.5-1,7.5-3.1l21.3-21.3C257,141.1,257,134.3,252.9,130.2z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M295.5,172.9c-4.2-4.2-10.9-4.2-15.1,0l-21.3,21.3c-4.2,4.2-4.2,10.9,0,15.1c2.1,2.1,4.8,3.1,7.6,3.1
                s5.5-1,7.5-3.1l21.3-21.3C299.7,183.8,299.7,177,295.5,172.9z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M316.9,108.9c-4.2-4.2-10.9-4.2-15.1,0l-21.3,21.3c-4.2,4.2-4.2,10.9,0,15.1c2.1,2.1,4.8,3.1,7.6,3.1
                s5.5-1,7.5-3.1l21.3-21.3C321,119.8,321,113,316.9,108.9z"
                />
            </g>
        </g>
    </StyledSvg>
);
export const Children = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <ellipse
                    transform="matrix(0.4552 -0.8904 0.8904 0.4552 -327.9699 332.5427)"
                    cx="107.8"
                    cy="434.3"
                    rx="68.1"
                    ry="84.6"
                />
            </g>
        </g>
        <g>
            <g>
                <ellipse
                    transform="matrix(0.8904 -0.4552 0.4552 0.8904 -153.3831 231.6172)"
                    cx="404.3"
                    cy="434.3"
                    rx="84.6"
                    ry="68.1"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M122.8,261.9c-16.2,15.9-33.8,41.4-37.5,67.4c2.8-0.2,5.6-0.3,8.4-0.3h0h0c13.3,0,26.7,2.2,39.8,6.4
                    c6-17.9,14-33,21.1-44.5C142.8,282.6,132.1,272.8,122.8,261.9z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M389.2,261.9c-9.4,10.9-20.1,20.7-31.8,29c7.2,11.4,15.1,26.6,21.1,44.5c13-4.2,26.5-6.4,39.8-6.4
                    c2.8,0,5.6,0.1,8.4,0.3C423,303.3,405.4,277.8,389.2,261.9z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M348.1,349.5c-5.3-17.1-12.9-31.5-19.6-41.9c-22.1,10.1-46.7,15.7-72.5,15.7s-50.4-5.6-72.5-15.7
                    c-6.7,10.5-14.3,24.8-19.6,41.9c22.1,13.7,39.5,33.1,49.5,55.5c5.9,13.3,9,27,9.2,40.8c0.2,14.9-3.1,29.1-9.8,42.2
                    c-3.1,6.1-6.9,11.7-11.2,16.9c36.3,9.5,72.6,9.5,108.9,0c-4.4-5.2-8.1-10.8-11.2-16.9c-6.7-13.1-10-27.3-9.8-42.2
                    c0.1-13.8,3.2-27.5,9.2-40.8C308.6,382.6,325.9,363.2,348.1,349.5z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M398.9,0c-24.6,0-45.9,14.7-55.4,35.8C319.4,16.9,289,5.6,256,5.6s-63.4,11.3-87.6,30.2
                    C158.9,14.7,137.7,0,113.1,0C79.6,0,52.4,27.2,52.4,60.7s27.2,60.7,60.7,60.7c1.1,0,2.2,0,3.2-0.1c-1.6,8.6-2.5,17.4-2.5,26.5
                    c0,72.7,54.9,132.9,125.5,141.2v-53.5c-20.4-4.5-35.1-17-35.1-31.8c0-18.5,23.2-33.6,51.8-33.6s51.8,15,51.8,33.6
                    c0,14.8-14.7,27.3-35.1,31.8v53.5c70.6-8.3,125.5-68.4,125.5-141.2c0-9-0.9-17.9-2.5-26.5c1.1,0.1,2.1,0.1,3.2,0.1
                    c33.5,0,60.7-27.2,60.7-60.7S432.4,0,398.9,0z M201.5,142.9c-11.5,0-20.8-9.3-20.8-20.8s9.3-20.8,20.8-20.8s20.8,9.3,20.8,20.8
                    C222.4,133.6,213,142.9,201.5,142.9z M310.5,142.9c-11.5,0-20.8-9.3-20.8-20.8s9.3-20.8,20.8-20.8c11.5,0,20.8,9.3,20.8,20.8
                    C331.3,133.6,322,142.9,310.5,142.9z"
                />
            </g>
        </g>
    </StyledSvg>
);
export const Outdoor = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <path d="M301,482h-30V352h-30v130h-30c-8.3,0-15,6.7-15,15s6.7,15,15,15h90c8.3,0,15-6.7,15-15S309.3,482,301,482z" />
        <path d="M116,292c-8.3,0-15,6.7-15,15s6.7,15,15,15h280c8.3,0,15-6.7,15-15s-6.7-15-15-15H116z" />
        <path
            d="M497,242c-8.3,0-15,6.7-15,15v95h-85c-8.3,0-15,6.7-15,15v130c0,8.3,6.7,15,15,15s15-6.7,15-15v-70h70v70
        c0,8.3,6.7,15,15,15s15-6.7,15-15V257C512,248.7,505.3,242,497,242z"
        />
        <path
            d="M115,352H30v-95c0-8.3-6.7-15-15-15s-15,6.7-15,15v240c0,8.3,6.7,15,15,15s15-6.7,15-15v-70h70v70
        c0,8.3,6.7,15,15,15s15-6.7,15-15V367C130,358.7,123.3,352,115,352z"
        />
        <path
            d="M96,230c15.8,0,30-6.7,40-17.3c10,10.6,24.3,17.3,40,17.3s30-6.7,40-17.3c6.7,7.1,15.3,12.5,25,15.2V262h30
        v-34.1c9.7-2.8,18.3-8.1,25-15.2c10,10.6,24.3,17.3,40,17.3s30-6.7,40-17.3c10,10.6,24.3,17.3,40,17.3c30.3,0,55-24.7,55-55
        c0-47.4-22.8-91.7-64.2-124.8C366.4,17.8,312.8,0,256,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c-56.8,0-110.3,17.8-150.8,50.1
        C63.8,83.3,41,127.6,41,175C41,205.3,65.7,230,96,230z"
        />
    </StyledSvg>
);

export const Textiles = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path
                    d="M512,177.3c-0.1-7.5-3.3-14.6-8.8-19.7c-5.6-5-8.8-12.2-8.8-19.7s3.2-14.7,8.8-19.7
                c5.5-5.1,8.7-12.2,8.8-19.7c-0.2-7.5-3.3-14.6-8.8-19.7c-5.5-5.1-8.7-12.2-8.8-19.7c0.2-7.5,3.3-14.6,8.8-19.7
                c7.9-7.1,10.8-18.3,7.2-28.4c-1.6-4.3-5.1-7.8-9.4-9.4c-10-3.6-21.2-0.7-28.4,7.2c-5,5.6-12.2,8.8-19.7,8.8s-14.7-3.2-19.7-8.8
                c-5-5.6-12.2-8.8-19.7-8.8c-7.5,0-14.7,3.2-19.7,8.8c-5.1,5.5-12.3,8.7-19.8,8.8c-7.5-0.2-14.6-3.3-19.7-8.8
                c-5-5.6-12.2-8.8-19.7-8.8c-7.5,0-14.7,3.2-19.7,8.8c-5.1,5.5-12.2,8.7-19.7,8.8c-7.5-0.2-14.6-3.3-19.7-8.8
                C270.7,3.2,263.5,0,256,0c-7.5,0-14.7,3.2-19.7,8.8c-5.1,5.5-12.2,8.7-19.7,8.8c-7.5-0.1-14.6-3.3-19.7-8.8
                c-5-5.6-12.2-8.8-19.7-8.8s-14.7,3.2-19.7,8.8c-5.1,5.5-12.2,8.7-19.7,8.8c-7.5-0.1-14.7-3.3-19.8-8.8C113,3.3,105.9,0.1,98.4,0
                c-7.5,0.2-14.6,3.3-19.7,8.8c-5.1,5.5-12.2,8.7-19.7,8.8c-7.5-0.2-14.6-3.3-19.7-8.8C32.3,0.9,21.1-1.9,11.1,1.6
                c-4.4,1.6-7.8,5.1-9.5,9.5c-3.5,10-0.7,21.2,7.2,28.3c5.6,5,8.8,12.2,8.8,19.7s-3.2,14.7-8.8,19.7C3.3,83.8,0.2,90.9,0,98.4
                c0.1,7.5,3.3,14.6,8.8,19.7c5.5,5.1,8.7,12.3,8.8,19.8c-0.2,7.5-3.3,14.6-8.8,19.7c-5.6,5-8.8,12.2-8.8,19.7s3.2,14.7,8.8,19.7
                c5.5,5.1,8.7,12.2,8.8,19.7c-0.2,7.5-3.3,14.6-8.8,19.7C3.2,241.3,0,248.5,0,256s3.2,14.7,8.8,19.7c5.5,5.1,8.7,12.2,8.8,19.7
                c-0.1,7.5-3.3,14.6-8.8,19.7c-5.6,5-8.8,12.2-8.8,19.7c0,7.5,3.2,14.7,8.8,19.7c5.5,5.1,8.7,12.2,8.8,19.7
                c-0.1,7.5-3.3,14.7-8.8,19.8c-5.6,5-8.8,12.2-8.8,19.7c0,7.5,3.2,14.7,8.8,19.7c5.5,5.1,8.7,12.2,8.8,19.7
                c-0.2,7.5-3.3,14.6-8.8,19.7c-7.4,6.7-10.4,16.9-7.8,26.6c1.6,6.1,6.4,10.9,12.5,12.5c9.5,1.5,19.1-1.7,25.9-8.5
                c12.7-10.4,28-11.7,39.4,0c5.1,5.5,12.2,8.7,19.7,8.8c7.5-0.1,14.6-3.3,19.7-8.8c5-5.6,12.2-8.8,19.7-8.8s14.7,3.2,19.7,8.8
                c5,5.6,12.2,8.8,19.7,8.8s14.7-3.2,19.7-8.8c5-5.6,12.2-8.8,19.7-8.8c7.5,0,14.7,3.2,19.7,8.8c5,5.6,12.2,8.8,19.7,8.8
                c7.5,0,14.7-3.2,19.7-8.8s12.2-8.8,19.7-8.8c7.5,0,14.7,3.2,19.7,8.8s12.2,8.8,19.7,8.8c7.5,0,14.7-3.2,19.7-8.8
                c5-5.6,12.2-8.8,19.7-8.8c7.5,0,14.7,3.2,19.7,8.8c5,5.6,12.2,8.8,19.7,8.8c7.5,0,14.7-3.2,19.7-8.8c5-5.6,12.2-8.8,19.7-8.8
                c7.5,0,14.7,3.2,19.7,8.8c7.1,7.9,18.3,10.8,28.3,7.2c4.4-1.6,7.8-5.1,9.5-9.5c3.5-10,0.7-21.2-7.2-28.3c-5.6-5-8.8-12.2-8.8-19.7
                s3.2-14.7,8.8-19.7c5.6-5,8.8-12.2,8.8-19.7s-3.2-14.7-8.8-19.7c-5.6-5-8.8-12.2-8.8-19.7c0-7.5,3.2-14.7,8.8-19.7
                c5.6-5,8.8-12.2,8.8-19.7c0-7.5-3.2-14.7-8.8-19.7c-5.6-5-8.8-12.2-8.8-19.7c0-7.5,3.2-14.7,8.8-19.7c5.6-5,8.8-12.2,8.8-19.7
                s-3.2-14.7-8.8-19.7c-5.6-5-8.8-12.2-8.8-19.7s3.2-14.7,8.8-19.7C508.7,191.8,511.8,184.7,512,177.3L512,177.3z M406.1,61.8
                c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V61.8z M406.1,379.6c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V379.6z M397.2,264.8h-17.7c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8
                h17.7c4.9,0,8.8,4,8.8,8.8C406.1,260.9,402.1,264.8,397.2,264.8z M414.9,300.1c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7C423.7,296.2,419.8,300.1,414.9,300.1z M414.9,247.2c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7C423.7,243.2,419.8,247.2,414.9,247.2z M414.9,141.2c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7C423.7,137.3,419.8,141.2,414.9,141.2z M379.6,88.3h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7
                c-4.9,0-8.8-4-8.8-8.8S374.7,88.3,379.6,88.3z M353.1,167.7h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8
                S348.2,167.7,353.1,167.7z M326.6,141.2c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V141.2z
                M335.4,220.7c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7C344.3,216.7,340.3,220.7,335.4,220.7z
                M344.3,300.1v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8S344.3,295.3,344.3,300.1z M317.8,167.7
                c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8H317.8z M247.2,379.6c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V379.6z M238.3,264.8h-17.7c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8
                h17.7c4.9,0,8.8,4,8.8,8.8C247.2,260.9,243.2,264.8,238.3,264.8z M256,300.1c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7C264.8,296.2,260.9,300.1,256,300.1z M256,247.2c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7C264.8,243.2,260.9,247.2,256,247.2z M247.2,132.4v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7
                c0,4.9-4,8.8-8.8,8.8S247.2,137.3,247.2,132.4z M273.7,247.2h17.7c4.9,0,8.8,4,8.8,8.8c0,4.9-4,8.8-8.8,8.8h-17.7
                c-4.9,0-8.8-4-8.8-8.8C264.8,251.1,268.8,247.2,273.7,247.2z M273.7,88.3h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7
                c-4.9,0-8.8-4-8.8-8.8S268.8,88.3,273.7,88.3z M247.2,61.8c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8
                s-8.8-4-8.8-8.8V61.8z M220.7,88.3h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8S215.8,88.3,220.7,88.3z
                M194.2,167.7h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8S189.3,167.7,194.2,167.7z M167.7,141.2
                c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V141.2z M176.6,220.7c-4.9,0-8.8-4-8.8-8.8v-17.7
                c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7C185.4,216.7,181.4,220.7,176.6,220.7z M185.4,300.1v17.7c0,4.9-4,8.8-8.8,8.8
                s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8S185.4,295.3,185.4,300.1z M158.9,167.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7
                c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8H158.9z M97.1,300.1c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7
                C105.9,296.2,102,300.1,97.1,300.1z M105.9,379.6v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                S105.9,374.7,105.9,379.6z M97.1,247.2c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7
                C105.9,243.2,102,247.2,97.1,247.2z M88.3,132.4v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8
                S88.3,137.3,88.3,132.4z M114.8,247.2h17.7c4.9,0,8.8,4,8.8,8.8c0,4.9-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8
                C105.9,251.1,109.9,247.2,114.8,247.2z M114.8,88.3h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8
                S109.9,88.3,114.8,88.3z M88.3,61.8c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V61.8z M61.8,88.3
                h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8H61.8c-4.9,0-8.8-4-8.8-8.8S56.9,88.3,61.8,88.3z M61.8,247.2h17.7c4.9,0,8.8,4,8.8,8.8
                c0,4.9-4,8.8-8.8,8.8H61.8c-4.9,0-8.8-4-8.8-8.8C53,251.1,56.9,247.2,61.8,247.2z M79.4,423.7H61.8c-4.9,0-8.8-4-8.8-8.8
                s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8S84.3,423.7,79.4,423.7z M105.9,450.2c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8v-17.7
                c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8V450.2z M132.4,423.7h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8
                S137.3,423.7,132.4,423.7z M158.9,344.3h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8
                S163.8,344.3,158.9,344.3z M185.4,370.8c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8V370.8z
                M194.2,344.3c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8H194.2z M238.3,423.7h-17.7
                c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8S243.2,423.7,238.3,423.7z M264.8,450.2c0,4.9-4,8.8-8.8,8.8
                s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8V450.2z M291.3,423.7h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7
                c4.9,0,8.8,4,8.8,8.8S296.2,423.7,291.3,423.7z M317.8,344.3h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8
                S322.7,344.3,317.8,344.3z M344.3,370.8c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8V370.8z
                M353.1,344.3c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8H353.1z M397.2,423.7h-17.7
                c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8S402.1,423.7,397.2,423.7z M423.7,450.2c0,4.9-4,8.8-8.8,8.8
                s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8V450.2z M450.2,423.7h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7
                c4.9,0,8.8,4,8.8,8.8S455.1,423.7,450.2,423.7z M450.2,264.8h-17.7c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8h17.7
                c4.9,0,8.8,4,8.8,8.8C459,260.9,455.1,264.8,450.2,264.8z M450.2,105.9h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7
                c4.9,0,8.8,4,8.8,8.8S455.1,105.9,450.2,105.9z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const Carpet = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path
                    d="M512,177.3c-0.1-7.5-3.3-14.6-8.8-19.7c-5.6-5-8.8-12.2-8.8-19.7s3.2-14.7,8.8-19.7
                c5.5-5.1,8.7-12.2,8.8-19.7c-0.2-7.5-3.3-14.6-8.8-19.7c-5.5-5.1-8.7-12.2-8.8-19.7c0.2-7.5,3.3-14.6,8.8-19.7
                c7.9-7.1,10.8-18.3,7.2-28.4c-1.6-4.3-5.1-7.8-9.4-9.4c-10-3.6-21.2-0.7-28.4,7.2c-5,5.6-12.2,8.8-19.7,8.8s-14.7-3.2-19.7-8.8
                c-5-5.6-12.2-8.8-19.7-8.8c-7.5,0-14.7,3.2-19.7,8.8c-5.1,5.5-12.3,8.7-19.8,8.8c-7.5-0.2-14.6-3.3-19.7-8.8
                c-5-5.6-12.2-8.8-19.7-8.8c-7.5,0-14.7,3.2-19.7,8.8c-5.1,5.5-12.2,8.7-19.7,8.8c-7.5-0.2-14.6-3.3-19.7-8.8
                C270.7,3.2,263.5,0,256,0c-7.5,0-14.7,3.2-19.7,8.8c-5.1,5.5-12.2,8.7-19.7,8.8c-7.5-0.1-14.6-3.3-19.7-8.8
                c-5-5.6-12.2-8.8-19.7-8.8s-14.7,3.2-19.7,8.8c-5.1,5.5-12.2,8.7-19.7,8.8c-7.5-0.1-14.7-3.3-19.8-8.8C113,3.3,105.9,0.1,98.4,0
                c-7.5,0.2-14.6,3.3-19.7,8.8c-5.1,5.5-12.2,8.7-19.7,8.8c-7.5-0.2-14.6-3.3-19.7-8.8C32.3,0.9,21.1-1.9,11.1,1.6
                c-4.4,1.6-7.8,5.1-9.5,9.5c-3.5,10-0.7,21.2,7.2,28.3c5.6,5,8.8,12.2,8.8,19.7s-3.2,14.7-8.8,19.7C3.3,83.8,0.2,90.9,0,98.4
                c0.1,7.5,3.3,14.6,8.8,19.7c5.5,5.1,8.7,12.3,8.8,19.8c-0.2,7.5-3.3,14.6-8.8,19.7c-5.6,5-8.8,12.2-8.8,19.7s3.2,14.7,8.8,19.7
                c5.5,5.1,8.7,12.2,8.8,19.7c-0.2,7.5-3.3,14.6-8.8,19.7C3.2,241.3,0,248.5,0,256s3.2,14.7,8.8,19.7c5.5,5.1,8.7,12.2,8.8,19.7
                c-0.1,7.5-3.3,14.6-8.8,19.7c-5.6,5-8.8,12.2-8.8,19.7c0,7.5,3.2,14.7,8.8,19.7c5.5,5.1,8.7,12.2,8.8,19.7
                c-0.1,7.5-3.3,14.7-8.8,19.8c-5.6,5-8.8,12.2-8.8,19.7c0,7.5,3.2,14.7,8.8,19.7c5.5,5.1,8.7,12.2,8.8,19.7
                c-0.2,7.5-3.3,14.6-8.8,19.7c-7.4,6.7-10.4,16.9-7.8,26.6c1.6,6.1,6.4,10.9,12.5,12.5c9.5,1.5,19.1-1.7,25.9-8.5
                c12.7-10.4,28-11.7,39.4,0c5.1,5.5,12.2,8.7,19.7,8.8c7.5-0.1,14.6-3.3,19.7-8.8c5-5.6,12.2-8.8,19.7-8.8s14.7,3.2,19.7,8.8
                c5,5.6,12.2,8.8,19.7,8.8s14.7-3.2,19.7-8.8c5-5.6,12.2-8.8,19.7-8.8c7.5,0,14.7,3.2,19.7,8.8c5,5.6,12.2,8.8,19.7,8.8
                c7.5,0,14.7-3.2,19.7-8.8s12.2-8.8,19.7-8.8c7.5,0,14.7,3.2,19.7,8.8s12.2,8.8,19.7,8.8c7.5,0,14.7-3.2,19.7-8.8
                c5-5.6,12.2-8.8,19.7-8.8c7.5,0,14.7,3.2,19.7,8.8c5,5.6,12.2,8.8,19.7,8.8c7.5,0,14.7-3.2,19.7-8.8c5-5.6,12.2-8.8,19.7-8.8
                c7.5,0,14.7,3.2,19.7,8.8c7.1,7.9,18.3,10.8,28.3,7.2c4.4-1.6,7.8-5.1,9.5-9.5c3.5-10,0.7-21.2-7.2-28.3c-5.6-5-8.8-12.2-8.8-19.7
                s3.2-14.7,8.8-19.7c5.6-5,8.8-12.2,8.8-19.7s-3.2-14.7-8.8-19.7c-5.6-5-8.8-12.2-8.8-19.7c0-7.5,3.2-14.7,8.8-19.7
                c5.6-5,8.8-12.2,8.8-19.7c0-7.5-3.2-14.7-8.8-19.7c-5.6-5-8.8-12.2-8.8-19.7c0-7.5,3.2-14.7,8.8-19.7c5.6-5,8.8-12.2,8.8-19.7
                s-3.2-14.7-8.8-19.7c-5.6-5-8.8-12.2-8.8-19.7s3.2-14.7,8.8-19.7C508.7,191.8,511.8,184.7,512,177.3L512,177.3z M406.1,61.8
                c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V61.8z M406.1,379.6c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V379.6z M397.2,264.8h-17.7c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8
                h17.7c4.9,0,8.8,4,8.8,8.8C406.1,260.9,402.1,264.8,397.2,264.8z M414.9,300.1c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7C423.7,296.2,419.8,300.1,414.9,300.1z M414.9,247.2c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7C423.7,243.2,419.8,247.2,414.9,247.2z M414.9,141.2c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7C423.7,137.3,419.8,141.2,414.9,141.2z M379.6,88.3h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7
                c-4.9,0-8.8-4-8.8-8.8S374.7,88.3,379.6,88.3z M353.1,167.7h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8
                S348.2,167.7,353.1,167.7z M326.6,141.2c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V141.2z
                M335.4,220.7c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7C344.3,216.7,340.3,220.7,335.4,220.7z
                M344.3,300.1v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8S344.3,295.3,344.3,300.1z M317.8,167.7
                c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8H317.8z M247.2,379.6c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V379.6z M238.3,264.8h-17.7c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8
                h17.7c4.9,0,8.8,4,8.8,8.8C247.2,260.9,243.2,264.8,238.3,264.8z M256,300.1c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7C264.8,296.2,260.9,300.1,256,300.1z M256,247.2c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                s8.8,4,8.8,8.8v17.7C264.8,243.2,260.9,247.2,256,247.2z M247.2,132.4v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7
                c0,4.9-4,8.8-8.8,8.8S247.2,137.3,247.2,132.4z M273.7,247.2h17.7c4.9,0,8.8,4,8.8,8.8c0,4.9-4,8.8-8.8,8.8h-17.7
                c-4.9,0-8.8-4-8.8-8.8C264.8,251.1,268.8,247.2,273.7,247.2z M273.7,88.3h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7
                c-4.9,0-8.8-4-8.8-8.8S268.8,88.3,273.7,88.3z M247.2,61.8c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8
                s-8.8-4-8.8-8.8V61.8z M220.7,88.3h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8S215.8,88.3,220.7,88.3z
                M194.2,167.7h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8S189.3,167.7,194.2,167.7z M167.7,141.2
                c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V141.2z M176.6,220.7c-4.9,0-8.8-4-8.8-8.8v-17.7
                c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7C185.4,216.7,181.4,220.7,176.6,220.7z M185.4,300.1v17.7c0,4.9-4,8.8-8.8,8.8
                s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8S185.4,295.3,185.4,300.1z M158.9,167.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7
                c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8H158.9z M97.1,300.1c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7
                C105.9,296.2,102,300.1,97.1,300.1z M105.9,379.6v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8
                S105.9,374.7,105.9,379.6z M97.1,247.2c-4.9,0-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7
                C105.9,243.2,102,247.2,97.1,247.2z M88.3,132.4v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8
                S88.3,137.3,88.3,132.4z M114.8,247.2h17.7c4.9,0,8.8,4,8.8,8.8c0,4.9-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8
                C105.9,251.1,109.9,247.2,114.8,247.2z M114.8,88.3h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8h-17.7c-4.9,0-8.8-4-8.8-8.8
                S109.9,88.3,114.8,88.3z M88.3,61.8c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8v17.7c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8V61.8z M61.8,88.3
                h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8H61.8c-4.9,0-8.8-4-8.8-8.8S56.9,88.3,61.8,88.3z M61.8,247.2h17.7c4.9,0,8.8,4,8.8,8.8
                c0,4.9-4,8.8-8.8,8.8H61.8c-4.9,0-8.8-4-8.8-8.8C53,251.1,56.9,247.2,61.8,247.2z M79.4,423.7H61.8c-4.9,0-8.8-4-8.8-8.8
                s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8S84.3,423.7,79.4,423.7z M105.9,450.2c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8v-17.7
                c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8V450.2z M132.4,423.7h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8
                S137.3,423.7,132.4,423.7z M158.9,344.3h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8
                S163.8,344.3,158.9,344.3z M185.4,370.8c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8V370.8z
                M194.2,344.3c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8H194.2z M238.3,423.7h-17.7
                c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8S243.2,423.7,238.3,423.7z M264.8,450.2c0,4.9-4,8.8-8.8,8.8
                s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8V450.2z M291.3,423.7h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7
                c4.9,0,8.8,4,8.8,8.8S296.2,423.7,291.3,423.7z M317.8,344.3h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8
                S322.7,344.3,317.8,344.3z M344.3,370.8c0,4.9-4,8.8-8.8,8.8s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8V370.8z
                M353.1,344.3c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8H353.1z M397.2,423.7h-17.7
                c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7c4.9,0,8.8,4,8.8,8.8S402.1,423.7,397.2,423.7z M423.7,450.2c0,4.9-4,8.8-8.8,8.8
                s-8.8-4-8.8-8.8v-17.7c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8V450.2z M450.2,423.7h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7
                c4.9,0,8.8,4,8.8,8.8S455.1,423.7,450.2,423.7z M450.2,264.8h-17.7c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8h17.7
                c4.9,0,8.8,4,8.8,8.8C459,260.9,455.1,264.8,450.2,264.8z M450.2,105.9h-17.7c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8h17.7
                c4.9,0,8.8,4,8.8,8.8S455.1,105.9,450.2,105.9z"
                />
            </g>
        </g>
    </StyledSvg>
);
export const Plants = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path
                    d="M466,392H46c-8.3,0-15,6.7-15,15c0,8.3,6.7,15,15,15h19.2l26.6,79.7c2.1,6.1,7.8,10.3,14.2,10.3h300
                c6.4,0,12.2-4.1,14.2-10.3l26.6-79.7H466c8.3,0,15-6.7,15-15S474.3,392,466,392z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M474.1,182.6C463.6,163.9,443.7,152,422,152s-41.6,11.9-52.1,30.6c-21.4,3.4-37.9,22-37.9,44.4
                c0,24.8,20.2,45,45,45h27.2c-6.2,17.4-22.7,30-42.2,30h-76h-90c-8.3,0-15-6.7-15-15s6.7-15,15-15h8.4c53.2,0,96.6-43.3,96.6-96.6
                V150h45c24.8,0,45-20.2,45-45c0-22.4-16.5-41-37.9-44.4c-12.8-22.7-40.8-34.9-65.9-29C273.3,12.1,250.5,0,226,0
                c-37,0-67.8,26.9-73.9,62.2C134.1,68.1,121,85,121,105c0,24.8,20.2,45,45,45h45v25.4c0,3.6-2.9,6.6-6.6,6.6H196
                c-51.6,0-94.4,37.5-103.1,86.6C83,263.7,75,253.8,75,242v-30c24.8,0,45-20.2,45-45s-20.2-45-45-45H45c-24.8,0-45,20.2-45,45
                s20.2,45,45,45v30c0,28.4,20.8,52.1,47.3,58.3c3.1,24.1,14.5,45.5,31,61.7h256.9c-5.3-11.2-12.3-21.4-21.1-30h1.7
                c36.2,0,67.5-25.8,74.5-60H467c24.8,0,45-20.2,45-45C512,204.6,495.5,186,474.1,182.6z"
                />
            </g>
        </g>
    </StyledSvg>
);
export const Lamp = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 852 852"
    >
        <path d="M305.4,740.4h220.3c45.6,0,82.6,37,82.6,82.6H222.7C222.7,777.4,259.7,740.4,305.4,740.4z" />
        <path
            d="M502.6,712.8H328.5c11.7-24.7,33.3-43.3,59.5-51.1c18-5.3,37.1-5.3,55.1,0C469.3,669.6,490.9,688.1,502.6,712.8
        z"
        />
        <path d="M443.1,530.1v103.3c-9-2.1-18.3-3.2-27.5-3.2c-9.3,0-18.5,1.1-27.5,3V530.1C406,534.8,425,534.8,443.1,530.1z" />
        <path d="M493.4,451c-11.6,33-42.8,55.1-77.8,55.1c-35,0-66.2-22.1-77.8-55.1H493.4z" />
        <path
            d="M671.7,405.6L556.3,33.9c-1.9-5.7-7.2-9.6-13.2-9.6H288c-6,0-11.4,3.9-13.2,9.6L159.4,405.6
        c-1.3,4.2-0.6,8.8,2.1,12.3c2.6,3.5,6.8,5.6,11.2,5.6h485.9c4.4,0,8.5-2,11.2-5.6C672.2,414.3,673,409.8,671.7,405.6z M250,371.1
        c-0.2,0.9-0.4,1.7-0.8,2.5c-0.3,0.9-0.7,1.7-1.2,2.5l-1.7,2.1c-5.4,5.3-14.1,5.3-19.6,0l-1.7-2.1c-0.5-0.8-0.9-1.6-1.2-2.5
        c-0.4-0.8-0.7-1.6-0.8-2.5c-0.2-0.9-0.2-1.8-0.3-2.8c0-7.6,6.2-13.8,13.8-13.8c7.6,0,13.8,6.2,13.8,13.8
        C250.3,369.3,250.2,370.2,250,371.1z M567,382.2H291.6c-7.6,0-13.8-6.2-13.8-13.8c0-7.6,6.2-13.8,13.8-13.8H567
        c7.6,0,13.8,6.2,13.8,13.8C580.8,376,574.6,382.2,567,382.2z"
        />
        <path
            d="M580.8,616.4c-7.6,0-13.8-6.2-13.8-13.8v-179c0-7.6,6.2-13.8,13.8-13.8s13.8,6.2,13.8,13.8v179
        C594.6,610.2,588.4,616.4,580.8,616.4z"
        />
        <path
            d="M580.8,671.5c-22.8,0-41.3-18.5-41.3-41.3c0-22.8,18.5-41.3,41.3-41.3c22.8,0,41.3,18.5,41.3,41.3
        C622.1,653,603.6,671.5,580.8,671.5z"
        />
    </StyledSvg>
);
export const Accessoires = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <g>
                    <path
                        d="M512,150.9v-46c0-22.5-18.3-40.8-40.8-40.8c-10.7,0-21.3,4.4-28.9,12l-8.9,8.9l14.4-72.1
                    c0.6-3.1-0.2-6.4-2.2-8.9c-2-2.5-5.1-3.9-8.3-3.9H266.7c-3.2,0-6.2,1.4-8.3,3.9c-2,2.5-2.8,5.7-2.2,8.9l14.4,72.1l-8.9-8.9
                    c-7.6-7.6-18.1-12-28.9-12c-22.5,0-40.8,18.3-40.8,40.8v46c0,8.1,2.4,15.9,6.9,22.6l33.6,50.5l-21.8,32.8
                    c-1.5,2.2-2.7,4.5-4.9,8.6c-1.9,3.6-1.7,8,0.7,11.3l76.9,107.8c10,13.9,15.2,30.4,15.2,47.6c0,0.9-0.3,4.6-0.3,4.7
                    c-0.2,2.9,0.9,5.8,2.9,7.9c2,2.1,4.8,3.4,7.8,3.4h128.3c3.4,0,6.5-1.6,8.5-4.3l43.9-58.5c14.4-19.1,22.3-42.9,22.3-66.8
                    c0-22-6.5-43.4-18.7-61.7L471.5,224l33.7-50.5C509.6,166.8,512,159,512,150.9z M261.3,180.8l-16,23.9l-28.7-43.1
                    c-2.1-3.2-3.3-7-3.3-10.8v-46c0-10.8,8.8-19.5,19.5-19.5c5.1,0,10.2,2.1,13.8,5.7l30,30c0.2,2.3,0.7,4.5,0.7,6.8
                    C277.3,146.8,271.8,165.1,261.3,180.8z M490.7,150.9c0,3.9-1.1,7.6-3.3,10.8l-28.7,43.1l-16-23.9c-10.5-15.8-16-34.1-16-53
                    c0-2.3,0.5-4.5,0.7-6.8l30-30c3.6-3.6,8.7-5.7,13.8-5.7c10.8,0,19.5,8.8,19.5,19.5L490.7,150.9L490.7,150.9z"
                    />
                    <path
                        d="M180.6,277.1c-6.5-9.1-9.9-19.8-9.9-31v-64.8c0-5.9-4.8-10.7-10.7-10.7h-42.7c-5.9,0-10.7,4.8-10.7,10.7
                    v64.8c0,11.2-3.4,21.9-9.9,31L11.3,396.8C3.9,407.1,0,419.3,0,432c0,20.3,10.1,39.1,27,50.4l41.8,27.9c1.8,1.2,3.8,1.8,5.9,1.8
                    h128c2.1,0,4.2-0.6,5.9-1.8l41.8-27.9c16.9-11.3,26.9-30.1,26.9-50.4c0-12.7-3.9-24.9-11.3-35.2L180.6,277.1z"
                    />
                </g>
            </g>
        </g>
    </StyledSvg>
);
export const Private = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path
                    d="M437.3,192h-32v-42.7C405.3,67,338.3,0,256,0S106.7,67,106.7,149.3V192h-32c-5.9,0-10.7,4.8-10.7,10.7v266.7
                c0,23.5,19.1,42.7,42.7,42.7h298.7c23.5,0,42.7-19.1,42.7-42.7V202.7C448,196.8,443.2,192,437.3,192z M287.9,414.8
                c0.3,3-0.6,6-2.7,8.3c-2,2.3-4.9,3.6-7.9,3.6h-42.7c-3,0-5.9-1.3-7.9-3.6s-3-5.3-2.7-8.3l6.7-60.5c-10.9-7.9-17.5-20.5-17.5-34.3
                c0-23.5,19.1-42.7,42.7-42.7s42.7,19.1,42.7,42.7c0,13.8-6.5,26.4-17.5,34.3L287.9,414.8z M341.3,192H170.7v-42.7
                c0-47.1,38.3-85.3,85.3-85.3s85.3,38.3,85.3,85.3V192z"
                />
            </g>
        </g>
    </StyledSvg>
);
export const Wood = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 182.8 182.8"
    >
        <path
            d="M30.8,0H90L59.5,28.7L30.8,0z M104.2,88.9L8.7,182.8h64l62.7-62.7L104.2,88.9z M64.7,44.5
        c-0.1,0.1-0.3,0.3-0.5,0.4L27.7,79.3l32.1,32.1L173.2,0h-61.2L64.8,44.4C64.8,44.4,64.7,44.4,64.7,44.5z M147.6,182.8l-26.8-26.8
        l-26.8,26.8H147.6z M151.3,125.4l-19.9,19.9l37.4,37.4h6.5c4.1,0,7.5-3.4,7.5-7.5V93.9L151.3,125.4z M11.3,74.2
        c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.5-0.4,0.7-0.6L48.6,39L9.6,0H7.5C3.4,0,0,3.4,0,7.5v77.3L11.3,74.2z M16.8,89.6L0,105.4v64.9
        L49.1,122L16.8,89.6z M182.8,72.7V11.6l-67.9,66.8l31.1,31.1L182.8,72.7z"
        />
    </StyledSvg>
);
export const Stone = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path d="M361.7,155.8H244.9v83.5h133.6v-66.8C378.4,163.3,371,155.8,361.7,155.8z" />
            </g>
        </g>
        <g>
            <g>
                <rect x="144.7" y="272.7" width="133.6" height="83.5" />
            </g>
        </g>
        <g>
            <g>
                <rect y="155.8" width="211.5" height="83.5" />
            </g>
        </g>
        <g>
            <g>
                <rect x="211.5" y="389.6" width="167" height="83.5" />
            </g>
        </g>
        <g>
            <g>
                <rect y="272.7" width="111.3" height="83.5" />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M295,39c-85.8,0-111,0-117.4,0c-1.5,0-1.9,0-1.9,0c-3.3,0-11.7,0-31,0v83.5h167V55.7
                C311.7,46.4,304.2,39,295,39z"
                />
            </g>
        </g>
        <g>
            <g>
                <path d="M411.8,389.6V473h83.5c9.2,0,16.7-7.5,16.7-16.7v-66.8L411.8,389.6L411.8,389.6z" />
            </g>
        </g>
        <g>
            <g>
                <path d="M0,389.6v66.8c0,9.2,7.5,16.7,16.7,16.7h161.4v-83.5L0,389.6L0,389.6z" />
            </g>
        </g>
        <g>
            <g>
                <path d="M16.7,39C7.5,39,0,46.4,0,55.7v66.8h111.3V39H16.7z" />
            </g>
        </g>
        <g>
            <g>
                <path d="M495.3,272.7H311.7v83.5H512v-66.8C512,280.2,504.5,272.7,495.3,272.7z" />
            </g>
        </g>
    </StyledSvg>
);
export const Metal = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path d="M497,104.9H392.3v148.9c67.3-7.5,119.7-64.7,119.7-133.9C512,111.6,505.3,104.9,497,104.9z" />
            </g>
        </g>
        <g>
            <g>
                <path d="M15,104.9c-8.3,0-15,6.7-15,15c0,69.2,52.5,126.4,119.7,133.9V104.9H15z" />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M382.4,300.6c-5.9-8.7-9.3-19.2-9.3-30.5c0-6.8-4.5-12.6-10.8-14.4v-0.6V89.7H149.7v165.4v0
                    c-0.2,0-0.4,0-0.6,0c-8.3,0-15,6.7-15,15c0,10.5-3,20.2-8.1,28.5c-4,6.5-9.4,12.1-15.7,16.4h286.5
                    C391.1,311.2,386.2,306.3,382.4,300.6z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M497,345.1H16.4c-8.3,0-15,6.7-15,15v47.1c0,8.3,6.7,15,15,15H497c8.3,0,15-6.7,15-15v-47.1
                    C512,351.9,505.3,345.1,497,345.1z"
                />
            </g>
        </g>
    </StyledSvg>
);

export const Colorful = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 432 432"
    >
        <g>
            <path
                d="M423.3,51.8c-0.8-2-2.7-3.5-4.9-3.8c-2.2-0.3-4.3,0.7-5.6,2.5c-7.6,10.6-17.5,14.2-29.1,18.3
            c-9.3,3.3-18.9,6.7-29.3,14.2c-14.7,10.5-21.4,24.9-18.1,38.5L336,122c-6.1-8.9-12.8-17.1-19.8-24.5c-32.9-34.4-79-55-126.3-56.7
            c-2.1-0.1-4.2-0.1-6.3-0.1c-52.8,0-103.4,23.6-138.8,64.8c-35.2,41-50.7,94.3-42.8,146.3c0.7,4.3,1.5,8.7,2.5,13.1
            c13,57.2,46.2,100.7,91.1,119.4c11.1,4.6,22.2,6.9,33.2,6.9c27.2,0,50.9-14.5,65.1-39.9c5.4-9.6,8.9-19.6,10.4-29.7
            c0.9-5.9,0.3-11.8-0.3-17.6c-0.6-5.9-1.1-11.5-0.3-17.1c2-12.9,11-20.6,24-20.6c3.7,0,7.5,0.6,11.3,1.8c-4.4,8.1-8.4,15.9-11.9,23
            c-7.4,15.2-12.6,27.8-15.3,37.4c-1.7,5.9-6.1,21.7,4.7,29.2c3.4,2.4,7.7,3.2,12.2,2.4c4-0.8,16.4-3,51.4-42.9
            c9.4-10.7,19.5-23.1,30.1-36.8c27.2-9.1,48.3-31.9,53.9-58.1c1.4-6.5,2-13.5,1.7-20.8c10.2-15.7,19.1-30.4,28.1-46.2
            c0.2-0.3,0.3-0.6,0.5-1c13-3.6,24.3-13.7,30.8-27.9C434.9,106,434.2,79.4,423.3,51.8z M276.4,149.8c-4.7,7.5-12.8,12-21.7,12
            c-4.8,0-9.5-1.4-13.6-3.9c-5.8-3.6-9.8-9.3-11.3-16s-0.3-13.5,3.3-19.3c4.7-7.5,12.8-12,21.7-12c4.8,0,9.5,1.4,13.6,3.9
            c5.8,3.6,9.8,9.3,11.3,16C281.2,137.2,280,144.1,276.4,149.8z M309.6,196.2c12.9-19.1,26.6-38,39.6-54.8c2.1,1.9,4.5,3.8,7,5.5
            c4.2,2.9,8.6,5.2,13.2,6.8c-11,18.8-22.8,37.3-37,58.4c-23.5,34.6-47.9,66.7-68.7,90.4c-11.6,13.3-21.8,23.5-29.7,29.8
            c3-9.7,8.8-23,16.9-38.7C265.2,265.3,286.1,230.8,309.6,196.2z M82.5,196c-4.8,0-9.5-1.4-13.6-3.9c-5.8-3.6-9.8-9.3-11.3-16
            c-1.5-6.7-0.3-13.5,3.3-19.3c4.7-7.5,12.8-12,21.7-12c4.8,0,9.5,1.4,13.6,3.9c11.9,7.5,15.5,23.3,8,35.3
            C99.5,191.6,91.4,196,82.5,196z M55.7,252.4c4.7-7.5,12.8-12,21.7-12c4.8,0,9.5,1.4,13.6,3.9c5.8,3.6,9.8,9.3,11.3,16
            c1.5,6.7,0.3,13.5-3.3,19.3c-4.7,7.5-12.8,12-21.7,12c-4.8,0-9.5-1.4-13.6-3.9c-5.8-3.6-9.8-9.3-11.3-16
            C50.9,265,52,258.1,55.7,252.4z M137.6,100.4c4.7-7.5,12.8-12,21.7-12c4.8,0,9.5,1.4,13.6,3.9c11.9,7.5,15.5,23.3,8,35.3
            c-4.7,7.5-12.8,12-21.7,12c-4.8,0-9.5-1.4-13.6-3.9c-5.8-3.6-9.8-9.3-11.3-16C132.8,113.1,134,106.2,137.6,100.4z"
            />
        </g>
    </StyledSvg>
);
export const Wall = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 948.3 948.3"
    >
        <g>
            <g>
                <path
                    d="M490.6,9.1L490.6,9.1L474.2,0l-16.5,9.1l0,0l-247,136.8L52.6,233.5v20v480v21.8l19.8,9.1l47.1,21.6
                l354.6,162.3L828.8,786l47.1-21.6l19.8-9.1v-21.8v-480v-20l-158.2-87.6L490.6,9.1z M362,619.9l112.2-62.1l112.2,62.1l200,110.8
                L474.2,873.6L162,730.7L362,619.9z M440.2,96.6v402.3l-319.6,177V273.6L440.2,96.6z M508.2,498.9V96.6l319.6,177v402.3
                L508.2,498.9z"
                />
            </g>
        </g>
        <polygon points="794.2,615.1 541.7,475.2 541.7,157.4 794.2,297.2 " />
    </StyledSvg>
);
export const Floor = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 948.3 948.3"
    >
        <g>
            <g>
                <path
                    d="M490.6,9.1L490.6,9.1L474.2,0l-16.5,9.1l0,0l-247,136.8L52.6,233.5v20v480v21.8l19.8,9.1l47.1,21.6
                l354.6,162.3L828.8,786l47.1-21.6l19.8-9.1v-21.8v-480v-20l-158.2-87.6L490.6,9.1z M362,619.9l112.2-62.1l112.2,62.1l200,110.8
                L474.2,873.6L162,730.7L362,619.9z M440.2,96.6v402.3l-319.6,177V273.6L440.2,96.6z M508.2,498.9V96.6l319.6,177v402.3
                L508.2,498.9z"
                />
            </g>
        </g>
        <polygon points="721.8,728.6 475.1,841.5 228.4,728.6 475.1,592 " />
    </StyledSvg>
);
export const Prototype = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path d="M236.8,0L44.4,96.2l190.7,95.1l189.5-97.4L236.8,0z" />
            </g>
        </g>
        <g>
            <g>
                <path d="M23.2,373.4L220.3,472V217.2L23.2,118.9V373.4z" />
            </g>
        </g>
        <g>
            <g>
                <path d="M250.1,473.5l200.1-100.1V114.3L250.1,217.1V473.5z" />
            </g>
        </g>
    </StyledSvg>
);
export const Technical = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path
                    d="M309.7,212.8c19.8-56.1,7.8-121.3-37.2-166.4c-51-51-128.2-59.8-188.4-27l108,108l-64.9,64.9l-108-108
			c-32.5,60.8-23.9,137.4,27,188.4c45.1,45.1,110.4,57,166.4,37.2l156.8,156.8c9.2,9.2,23.5,9.2,32.5,0l64.3-64.3
			c9.2-9.2,9.2-23.5,0-32.5L309.7,212.8z"
                />
            </g>
        </g>
    </StyledSvg>
);
export const Roof = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path
                    d="M3,300.9v169.9c24.4-54.8,140.2-54.6,164.4,0V300.9C167.4,217,3,216.6,3,300.9z M154.5,425.4
		c0,3-2.3,5.3-5.2,5.3c-2.9,0-5.4-2.3-5.4-5.3V350c0-3,2.5-5.3,5.4-5.3c2.9,0,5.2,2.3,5.2,5.3V425.4z M154.5,297.5v30.1
		c0,3-2.3,5.5-5.2,5.5c-2.9,0-5.4-2.5-5.4-5.5v-30.1c0-3,2.5-5.3,5.4-5.3C152.2,292.2,154.5,294.5,154.5,297.5z"
                />
                <path
                    d="M344.6,300.9v169.9c24.4-54.8,140-54.6,164.4,0V300.9C509.1,217,344.6,216.6,344.6,300.9z M495.9,425.4
		c0,3-2.3,5.3-5.2,5.3c-2.9,0-5.2-2.3-5.2-5.3V350c0-3,2.3-5.3,5.2-5.3c2.9,0,5.2,2.3,5.2,5.3V425.4z M495.9,297.5v30.1
		c0,3-2.3,5.5-5.2,5.5c-2.9,0-5.2-2.5-5.2-5.5v-30.1c0-3,2.3-5.3,5.2-5.3C493.6,292.2,495.9,294.5,495.9,297.5z"
                />
                <path
                    d="M344.6,95.1V265c24.4-54.8,140-54.6,164.4,0V95.1C509.1,11.3,344.6,11,344.6,95.1z M495.9,219.7
		c0,3-2.3,5.5-5.2,5.5c-2.9,0-5.2-2.5-5.2-5.5v-75.5c0-2.7,2.3-5.3,5.2-5.3c2.9,0,5.2,2.5,5.2,5.3V219.7z M495.9,122.1
		c0,3-2.3,5.3-5.2,5.3c-2.9,0-5.2-2.3-5.2-5.3V91.8c0-3,2.3-5.3,5.2-5.3c2.9,0,5.2,2.3,5.2,5.3V122.1z"
                />
                <path
                    d="M173.7,95.1V265c24.4-54.8,140.2-54.6,164.7,0V95.1C338.4,11.3,173.7,11,173.7,95.1z M325.2,219.7
		c0,3-2.3,5.5-5.2,5.5c-2.9,0-5.4-2.5-5.4-5.5v-75.5c0-2.7,2.5-5.3,5.4-5.3c2.9,0,5.2,2.5,5.2,5.3V219.7z M325.2,91.8v30.4
		c0,3-2.3,5.3-5.2,5.3c-2.9,0-5.4-2.3-5.4-5.3V91.8c0-3,2.5-5.3,5.4-5.3C322.9,86.5,325.2,88.8,325.2,91.8z"
                />
                <path
                    d="M173.7,300.9v169.9c24.4-54.8,140.2-54.6,164.7,0V300.9C338.4,217,173.7,216.6,173.7,300.9z M325.2,425.4
		c0,3-2.3,5.3-5.2,5.3c-2.9,0-5.4-2.3-5.4-5.3V350c0-3,2.5-5.3,5.4-5.3c2.9,0,5.2,2.3,5.2,5.3V425.4z M325.2,297.5v30.1
		c0,3-2.3,5.5-5.2,5.5c-2.9,0-5.4-2.5-5.4-5.5v-30.1c0-3,2.5-5.3,5.4-5.3C322.9,292.2,325.2,294.5,325.2,297.5z"
                />
                <path
                    d="M3,95.1V265c24.4-54.8,140.2-54.6,164.4,0V95.1C167.4,11.3,3,11,3,95.1z M154.5,219.7c0,3-2.3,5.5-5.2,5.5
		c-2.9,0-5.4-2.5-5.4-5.5v-75.5c0-2.7,2.5-5.3,5.4-5.3c2.9,0,5.2,2.5,5.2,5.3V219.7z M154.5,91.8v30.4c0,3-2.3,5.3-5.2,5.3
		c-2.9,0-5.4-2.3-5.4-5.3V91.8c0-3,2.5-5.3,5.4-5.3C152.2,86.5,154.5,88.8,154.5,91.8z"
                />
            </g>
        </g>
    </StyledSvg>
);
export const Tiles = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <rect
                    x="177.1"
                    y="33.2"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.3344 213.883)"
                    width="157.9"
                    height="157.9"
                />
            </g>
        </g>
        <g>
            <g>
                <rect
                    x="321.4"
                    y="177.6"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -64.1266 358.2066)"
                    width="157.9"
                    height="157.9"
                />
            </g>
        </g>
        <g>
            <g>
                <rect
                    x="32.7"
                    y="177.6"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -148.6764 154.0939)"
                    width="157.9"
                    height="157.9"
                />
            </g>
        </g>
        <g>
            <g>
                <rect
                    x="177"
                    y="321.9"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -208.4445 298.4233)"
                    width="157.9"
                    height="157.9"
                />
            </g>
        </g>
    </StyledSvg>
);
export const Facade = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 302.4 302.4"
    >
        <g>
            <rect x="164.8" y="34.3" width="33.7" height="435.8" />
            <path
                d="M291.8,15.5h-68.2c-10.3,0-18.7,8.7-18.7,19.1v435.4c0,10.4,8.4,19.1,18.7,19.1h68.2
		c10.3,0,18.9-8.7,18.9-19.1V34.5C310.6,24.1,302.1,15.5,291.8,15.5z"
            />
            <path
                d="M139.5,15.5H71.3c-10.3,0-18.9,8.7-18.9,19.1v435.4c0,10.4,8.6,19.1,18.9,19.1h68.2c10.3,0,18.9-8.7,18.9-19.1
		V34.5C158.4,24.1,149.8,15.5,139.5,15.5z"
            />
            <rect x="12.3" y="34.3" width="33.7" height="435.8" />
            <rect x="469.3" y="34.3" width="33.7" height="435.8" />
            <path
                d="M444,15.5h-68.2c-10.3,0-18.7,8.7-18.7,19.1v435.4c0,10.4,8.4,19.1,18.7,19.1H444c10.3,0,18.9-8.7,18.9-19.1
		V34.5C462.9,24.1,454.3,15.5,444,15.5z"
            />
            <rect x="317.1" y="34.3" width="33.7" height="435.8" />
        </g>
    </StyledSvg>
);

export const Device = (props: IconProps) => (
    <svg
        width="50"
        height="50"
        viewBox="0 0 28 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 4C1 2.34315 2.2934 1 3.88889 1H24.1111C25.7066 1 27 2.34315 27 4V10C27 11.6569 25.7066 13 24.1111 13H19.294C19.0161 13 18.7397 12.9584 18.4733 12.8764L14.8207 11.7525C14.2851 11.5877 13.7149 11.5877 13.1793 11.7525L9.52672 12.8764C9.2603 12.9584 8.98389 13 8.70602 13H3.88889C2.2934 13 1 11.6569 1 10V4Z"
            stroke="#494949"
            strokeWidth="1.4"
            strokeLinecap="round"
        />
        <path
            d="M9.66667 7C9.66667 8.24264 8.69662 9.25 7.5 9.25C6.30338 9.25 5.33333 8.24264 5.33333 7C5.33333 5.75736 6.30338 4.75 7.5 4.75C8.69662 4.75 9.66667 5.75736 9.66667 7Z"
            stroke="#494949"
            strokeWidth="1.4"
            strokeLinecap="round"
        />
        <path
            d="M22.6667 7C22.6667 8.24264 21.6966 9.25 20.5 9.25C19.3034 9.25 18.3333 8.24264 18.3333 7C18.3333 5.75736 19.3034 4.75 20.5 4.75C21.6966 4.75 22.6667 5.75736 22.6667 7Z"
            stroke="#494949"
            strokeWidth="1.4"
            strokeLinecap="round"
        />
    </svg>
);

export const Wallpaper = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 612 612"
    >
        <g>
            <path
                d="M499.4,5.5H256H12.6c-4.6,0-8.4,3.8-8.4,8.4v486.8c0,4.6,3.8,8.4,8.4,8.4H256c4.6,0,8.4-3.8,8.4-8.4V400
				h201.4c23.5,0,42-25.8,42-58.8V13.9C507.8,9.2,504.1,5.5,499.4,5.5z M424.7,353.3c0,0,0,0.1,0,0.1c0.6,3.8,1.4,7.5,2.4,11
				c0,0.1,0,0.1,0.1,0.2c1,3.5,2.3,6.8,3.8,9.8c0,0.1,0.1,0.2,0.1,0.2c1.5,3,3.1,5.9,5,8.4c0,0,0,0.1,0.1,0.1H256h-33.6
				c-13.6,0-25.2-19.2-25.2-42s11.5-42,25.2-42H256h180.2c0,0,0,0.1-0.1,0.1c-1.9,2.6-3.5,5.4-5,8.5c0,0.1-0.1,0.1-0.1,0.2
				c-1.5,3.1-2.8,6.4-3.8,9.9c0,0.1,0,0.1-0.1,0.2c-1,3.5-1.9,7.2-2.4,11c0,0,0,0.1,0,0.1c-0.6,3.9-0.8,7.9-0.8,12
				S424.2,349.4,424.7,353.3z M465.8,383.2c-13.6,0-25.2-19.2-25.2-42s11.5-42,25.2-42c13.6,0,25.2,19.2,25.2,42
				S479.5,383.2,465.8,383.2z M491,39.1c-4.6,0-8.4,3.8-8.4,8.4c0,4.6,3.8,8.4,8.4,8.4v33.6c-4.6,0-8.4,3.8-8.4,8.4
				c0,4.6,3.8,8.4,8.4,8.4v33.6c-4.6,0-8.4,3.8-8.4,8.4c0,4.6,3.8,8.4,8.4,8.4v33.6c-4.6,0-8.4,3.8-8.4,8.4c0,4.6,3.8,8.4,8.4,8.4
				v33.6c-4.6,0-8.4,3.8-8.4,8.4c0,4.6,3.8,8.4,8.4,8.4v36.6c-0.2-0.2-0.3-0.3-0.5-0.4c-1.5-1.5-3.2-3-4.9-4.2
				c-0.2-0.2-0.5-0.3-0.7-0.5c-1.6-1.1-3.3-2.1-5-2.9c-0.3-0.2-0.6-0.3-1-0.5c-1.8-0.8-3.8-1.5-5.7-1.9c-0.4-0.1-0.8-0.2-1.3-0.3
				c-2-0.4-4.1-0.7-6.3-0.7h-50.4h-50.4h-50.4h-50.4v-50.4c4.6,0,8.4-3.8,8.4-8.4c0-4.6-3.8-8.4-8.4-8.4v-33.6
				c4.6,0,8.4-3.8,8.4-8.4c0-4.6-3.8-8.4-8.4-8.4v-33.6c4.6,0,8.4-3.8,8.4-8.4c0-4.6-3.8-8.4-8.4-8.4V81c4.6,0,8.4-3.8,8.4-8.4
				c0-4.6-3.8-8.4-8.4-8.4V30.7c4.6,0,8.4-3.8,8.4-8.4h33.6c0,4.6,3.8,8.4,8.4,8.4s8.4-3.8,8.4-8.4h33.6c0,4.6,3.8,8.4,8.4,8.4
				s8.4-3.8,8.4-8.4h33.6c0,4.6,3.8,8.4,8.4,8.4s8.4-3.8,8.4-8.4h33.6c0,4.6,3.8,8.4,8.4,8.4s8.4-3.8,8.4-8.4H491L491,39.1L491,39.1
				z"
            />
            <circle cx="289.6" cy="47.4" r="8.4" />
            <circle cx="339.9" cy="47.4" r="8.4" />
            <circle cx="390.3" cy="47.4" r="8.4" />
            <circle cx="440.7" cy="47.4" r="8.4" />
            <circle cx="314.8" cy="72.6" r="8.4" />
            <circle cx="289.6" cy="97.8" r="8.4" />
            <circle cx="339.9" cy="97.8" r="8.4" />
            <circle cx="365.1" cy="72.6" r="8.4" />
            <circle cx="415.5" cy="72.6" r="8.4" />
            <circle cx="465.8" cy="72.6" r="8.4" />
            <circle cx="390.3" cy="97.8" r="8.4" />
            <circle cx="440.7" cy="97.8" r="8.4" />
            <circle cx="314.8" cy="123" r="8.4" />
            <circle cx="289.6" cy="148.2" r="8.4" />
            <circle cx="339.9" cy="148.2" r="8.4" />
            <circle cx="365.1" cy="123" r="8.4" />
            <circle cx="415.5" cy="123" r="8.4" />
            <circle cx="465.8" cy="123" r="8.4" />
            <circle cx="390.3" cy="148.2" r="8.4" />
            <circle cx="440.7" cy="148.2" r="8.4" />
            <circle cx="314.8" cy="173.3" r="8.4" />
            <circle cx="289.6" cy="198.5" r="8.4" />
            <circle cx="339.9" cy="198.5" r="8.4" />
            <circle cx="365.1" cy="173.3" r="8.4" />
            <circle cx="415.5" cy="173.3" r="8.4" />
            <circle cx="465.8" cy="173.3" r="8.4" />
            <circle cx="390.3" cy="198.5" r="8.4" />
            <circle cx="440.7" cy="198.5" r="8.4" />
            <circle cx="314.8" cy="223.7" r="8.4" />
            <circle cx="289.6" cy="248.9" r="8.4" />
            <circle cx="339.9" cy="248.9" r="8.4" />
            <circle cx="365.1" cy="223.7" r="8.4" />
            <circle cx="415.5" cy="223.7" r="8.4" />
            <circle cx="465.8" cy="223.7" r="8.4" />
            <circle cx="314.8" cy="274.1" r="8.4" />
            <circle cx="365.1" cy="274.1" r="8.4" />
            <circle cx="415.5" cy="274.1" r="8.4" />
            <circle cx="465.8" cy="274.1" r="8.4" />
            <circle cx="390.3" cy="248.9" r="8.4" />
            <circle cx="440.7" cy="248.9" r="8.4" />
        </g>
    </StyledSvg>
);
export const Glass = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 612 612"
    >
        <g>
            <g>
                <rect x="329.7" y="329.7" width="182.9" height="182.9" />
                <rect x="99.5" y="99.5" width="182.9" height="182.9" />
                <rect x="329.7" y="99.5" width="182.9" height="182.9" />
                <path
                    d="M595.1,0H16.9C7.6,0,0,7.6,0,16.9v578.2c0,9.3,7.6,16.9,16.9,16.9h578.2c9.3,0,16.9-7.6,16.9-16.9V16.9
                    C612,7.6,604.4,0,595.1,0z M559.9,559.9H52.1V52H560v507.8h-0.1V559.9z"
                />
                <rect x="99.5" y="329.7" width="182.9" height="182.9" />
            </g>
        </g>
    </StyledSvg>
);
export const SceneSwitch = (props: IconProps) => (
    <StyledSvg
        {...props}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <path
                d="M431.3,40.2H242.5c-22.3,0-40.5,18.1-40.5,40.5v152.8c0,22.3,18.2,40.5,40.5,40.5h188.8c22.3,0,40.5-18.1,40.5-40.5V80.7
            C471.8,58.4,453.6,40.2,431.3,40.2z M444.8,233.5c0,7.4-6.1,13.5-13.5,13.5H242.5c-7.4,0-13.5-6-13.5-13.5V130.1h215.8V233.5z
            M444.8,103.2H229V80.7c0-7.4,6.1-13.5,13.5-13.5h188.8c7.4,0,13.5,6,13.5,13.5V103.2z"
            />
            <path
                d="M296.5,294.2c-5,0-9.3,2.7-11.7,6.7H67.2v-22.5c0-7.4,6.1-13.5,13.5-13.5h87.7c7.4,0,13.5-6,13.5-13.5
            c0-7.4-6-13.5-13.5-13.5H80.7c-22.3,0-40.5,18.1-40.5,40.5v152.8c0,22.3,18.2,40.5,40.5,40.5h188.8c22.3,0,40.5-18.1,40.5-40.5
            V307.7C309.9,300.3,303.9,294.2,296.5,294.2z M283,431.3c0,7.4-6.1,13.5-13.5,13.5H80.7c-7.4,0-13.5-6-13.5-13.5V327.9H283V431.3z"
            />
            <path
                d="M102.9,197.4c3,3.1,7,4.6,11,4.6v0c4,0,8-1.5,11-4.6l31.1-31.1c6.1-6.1,6.1-15.9,0-22c-6.1-6.1-15.9-6.1-22,0l-4.6,4.6
            v-3.9c0-2.3,0.3-52.1,0.9-54.3c0.9-3.3,2.4-6.3,4.4-8.9c4.7-6.3,12.3-10.3,20.7-10.3h5.2c8.6,0,15.6-7,15.6-15.6s-7-15.6-15.6-15.6
            h-5.2c-20.8,0-39,11.2-49,27.9c-4.4,7.3-7.1,15.7-7.9,24.6c-0.1,1.5-0.2,50.7-0.2,52.2v3.9l-4.5-4.6c-6.1-6.1-15.9-6.1-22,0
            c-6.1,6.1-6.1,15.9,0,22L102.9,197.4z"
            />
            <path
                d="M406,314.6c-3-3.1-7-4.6-11-4.6v0c-4,0-8,1.5-11,4.6l-31.1,31.1c-6.1,6.1-6.1,15.9,0,22c6.1,6.1,15.9,6.1,22,0l4.6-4.6v3.9
            c0,2.3-0.3,52.1-0.9,54.3c-0.9,3.3-2.4,6.3-4.4,8.9c-4.7,6.3-12.3,10.3-20.7,10.3h-5.2c-8.6,0-15.6,7-15.6,15.6
            c0,8.6,7,15.6,15.6,15.6h5.2c20.8,0,39-11.2,49-27.9c4.4-7.3,7.1-15.7,7.9-24.6c0.1-1.5,0.2-50.7,0.2-52.2v-3.9l4.5,4.6
            c6.1,6.1,15.9,6.1,22,0c6.1-6.1,6.1-15.9,0-22L406,314.6z"
            />
        </g>
    </StyledSvg>
);
export const Position = (props: IconProps) => (
    <StyledSvg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
    >
        <path d="M220.8,184.1c-2.3,2.3-3.6,5.5-3.6,8.8l0.2,165.4h-23.1c-5,0-9.5,3.3-11.5,8c-1.9,4.6-0.8,10,2.8,13.5 l55.2,54.7c6.4,6.4,16.9,6.4,23.3-0.1l55-55c3.6-3.6,4.6-8.9,2.7-13.5s-6.5-7.6-11.5-7.6h-23.1L287,192.7c0-6.9-5.6-12.4-12.4-12.4 l-45,0.1C226.3,180.4,223.1,181.7,220.8,184.1z"></path>
        <path d="M283.9,327.9c2.3-2.3,3.6-5.5,3.6-8.8l-0.2-165.4h23.1c5,0,9.5-3.3,11.5-8c1.9-4.6,0.8-10-2.8-13.5l-55.2-54.7 c-6.4-6.4-16.9-6.4-23.3,0.1l-55,55c-3.6,3.6-4.6,8.9-2.7,13.5c1.9,4.6,6.5,7.6,11.5,7.6h23.1l0.2,165.6c0,6.9,5.6,12.4,12.4,12.4 l45-0.1C278.4,331.6,281.6,330.3,283.9,327.9z"></path>
        <path
            d="M324.3,224.4c-2.3-2.3-5.5-3.6-8.8-3.6L150.1,221v-23c0-5-3.3-9.5-8-11.5c-4.6-1.9-10-0.8-13.5,2.8l-54.7,55.2
            c-6.4,6.4-6.4,16.9,0.1,23.3l55,55c3.6,3.6,8.9,4.6,13.5,2.7s7.6-6.5,7.6-11.5v-23.1l165.6-0.2c6.9,0,12.4-5.6,12.4-12.4l-0.1-45
            C327.9,229.9,326.6,226.8,324.3,224.4z"
        ></path>
        <path
            d="M180.4,287.6c2.3,2.3,5.5,3.6,8.8,3.6l165.4-0.2v23c0,5,3.3,9.5,8,11.5c4.6,1.9,10,0.8,13.5-2.8l54.7-55.2
            c6.4-6.4,6.4-16.9-0.1-23.3l-55-55c-3.6-3.6-8.9-4.6-13.5-2.7c-4.6,1.9-7.6,6.5-7.6,11.5v23.1L189,221.3c-6.9,0-12.4,5.6-12.4,12.4
            l0.1,45C176.8,282.1,178.1,285.2,180.4,287.6z"
        ></path>
    </StyledSvg>
);

export const iconsMap = {
    HegiasLogoIcon: HegiasLogo,
    LogoVerticalIcon: LogoVertical,
    LogoVerticalSloganIcon: LogoVerticalSlogan,
    DocumentIcon: Document,
    WarningIcon: Warning,
    WarningV2Icon: WarningV2,
    ExclamationIcon: Exclamation,
    PlusIcon: Plus,
    SettingIcon: Setting,
    ArrowToTopIcon: ArrowToTop,
    ArrowToBottomIcon: ArrowToBottom,
    ArrowToRightIcon: ArrowToRight,
    ArrowToLeftIcon: ArrowToLeft,
    ArrowLeftIcon: ArrowLeft,
    ArrowRightIcon: ArrowRight,
    ArrowTopIcon: ArrowTop,
    ArrowBottomIcon: ArrowBottom,
    ListIcon: List,
    ListThumbIcon: ListThumb,
    AddIcon: Add,
    CopyIcon: Copy,
    PublicIcon: Public,
    DeleteIcon: Delete,
    SaveIcon: Save,
    SaveAsIcon: SaveAs,
    CameraIcon: Camera,
    FilterIcon: Filter,
    EllipsisIcon: Ellipsis,
    EllipsisVerticalIcon: EllipsisVertical,
    PencilIcon: Pencil,
    ShareIcon: Share,
    SearchIcon: Search,
    EmptyFavouriteIcon: EmptyFavourite,
    FullFavouriteIcon: FullFavourite,
    LockIcon: Lock,
    LockOpenIcon: LockOpen,
    VerifiedIcon: Verified,
    CloseIcon: Close,
    TransferIcon: Transfer,
    UserIcon: User,
    KeyIcon: Key,
    AddUserIcon: AddUser,
    SwitchIcon: Switch,
    LeaveIcon: Leave,
    CorrectIcon: Correct,
    RestoreIcon: Restore,
    OrganizationIcon: Organization,
    UsageIcon: Usage,
    HegiasWarningIcon: HegiasWarning,
    SendIcon: Send,
    MultiUserIcon: MultiUser,
    RefreshIcon: Refresh,
    WalkingIcon: Walking,
    BirdIcon: Bird,
    MoveIcon: Move,
    ScaleIcon: Scale,
    RotateIcon: Rotate,
    QuestionIcon: Question,
    FolderIcon: Folder,
    ArrowTop2Icon: ArrowTop2,
    ArrowBottom2Icon: ArrowBottom2,
    Camera2Icon: Camera2,
    ArrowDownFilledIcon: ArrowDownFilled,
    CheckIcon: Check,
    EmbedIcon: Embed,
    CrossSectionIcon: CrossSection,
    HandIcon: Hand,
    ModelViewIcon: ModelView,
    StatureIcon: ModelView,
    LibraryIcon: Library,
    EnvironmentIcon: Environment,
    ToolsIcon: Tools,
    PrevArrowIcon: PrevArrow,
    DoublePrevArrowIcon: DoublePrevArrow,
    NextArrowIcon: NextArrow,
    DoubleNextArrowIcon: DoubleNextArrow,
    SunIcon: Sun,
    SkyboxIcon: Skybox,
    ObjecstIcon: Objects,
    MaterialsIcon: Materials,
    MapIcon: Map,
    PerspectivesIcon: Perspectives,
    DistanceIcon: Distance,
    TapeMeasureIcon: TapeMeasure,
    MeasuringIcon: Measuring,
    EditIcon: Edit,
    PhotoIcon: Photo,
    LivingIcon: Living,
    DiningIcon: Dining,
    KitchenIcon: Kitchen,
    WorkingIcon: Working,
    CorridorIcon: Corridor,
    SleepingIcon: Sleeping,
    BathroomIcon: Bathroom,
    ChildrenIcon: Children,
    OutdoorIcon: Outdoor,
    CarpetIcon: Carpet,
    PlantsIcon: Plants,
    LampIcon: Lamp,
    AccessoiresIcon: Accessoires,
    PrivateIcon: Private,
    WoodIcon: Wood,
    StoneIcon: Stone,
    MetalIcon: Metal,
    ColorfulIcon: Colorful,
    WallIcon: Wall,
    FloorIcon: Floor,
    FacadeIcon: Facade,
    WallpaperIcon: Wallpaper,
    GlassIcon: Glass,
    SceneSwitch,
    PositionIcon: Position,
    PrototypeIcon: Prototype,
    TechnicalIcon: Technical,
    TextilesIcon: Textiles,
    RoofIcon: Roof,
    TilesIcon: Tiles,
    DeviceIcon: Device,
};

export type IconName = keyof typeof iconsMap;
