import React from "react";
import styled from "styled-components";
import { Avatar } from "../Avatar";
import { Typography } from "../Typography";
import { ArrowBottom } from "../Icons";
import { AvatarProps } from "../Avatar/Avatar";

export interface DropdownMenuButtonProps
    extends React.HTMLAttributes<HTMLDivElement> {
    name: string;
    title: string;
    maxWidth?: string;
    avatarProps?: AvatarProps;
}

const DropdownMenuButton = ({
    name,
    title,
    maxWidth,
    avatarProps,
}: DropdownMenuButtonProps) => {
    return (
        <StyledDropdownMenuButton>
            <Avatar {...avatarProps} />
            <StyledDropdownMenuText maxWidth={maxWidth}>
                <Typography variant="h4" noWrap={true}>
                    <strong>{name}</strong>
                </Typography>
                <Typography color="primary" noWrap={true}>
                    {title + " "}
                    <ArrowBottom size="10px" color="primary" />
                </Typography>
            </StyledDropdownMenuText>
        </StyledDropdownMenuButton>
    );
};

const StyledDropdownMenuButton = styled.div`
    width: 100%;
    overflow: hidden;
    overflow: auto;
    display: flex;
    flex-direction: row;
`;

const StyledDropdownMenuText = styled.div<{ maxWidth: string }>`
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    max-width: ${({ maxWidth }) => maxWidth ?? "100%"};
`;

export default DropdownMenuButton;
