import styled, { keyframes } from "styled-components";

const variants = {
    default: {
        size: "20px",
    },
    medium: {
        size: "90px",
        iconSize: "40px",
    },
    small: {
        size: "20px",
        iconSize: "10px",
    },
};

export const AnimShrinkBounce = keyframes`
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);    
    }
`;

export const AnimShrinkBounceAfter = keyframes`
    0%{
        transform: scale(2);
    }
    50%{    
        transform: scale(0);
    }
    100%{
        transform: scale(1);    
    }
`;
export interface RadioContainerProps
    extends React.HTMLAttributes<HTMLDivElement> {
    icon?: React.ReactNode;
    color?: string;
    variant?: string;
}

export const StyledRadioContainer = styled.div<RadioContainerProps>`
    display: flex;
    span {
        transition: ${(props) => props.theme.transition};
        border-radius: 50%;
        svg {
            display: none;
        }
        &.checked {
            display: block;
            border: 2px solid ${(props) => props.theme.border.color};
            height: ${variants.default.size};
            width: ${variants.default.size};
            min-width: ${variants.default.size};
            min-height: ${variants.default.size};
            transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
            background: transparent;
        }
        &:not(.cheched) {
            position: relative;
            display: inline-block;
            color: inherit;
        }
    }

    input {
        &:checked:disabled + label {
            span {
                &.checked {
                    border-color: ${(props) =>
                        props.theme.colors.grey400} !important;
                }
            }
        }

        &:disabled + label {
            color: ${(props) => props.theme.colors.grey600} !important;
            &:hover {
                cursor: not-allowed;
            }
            span.checked {
                background-color: ${(props) =>
                    props.theme.colors.grey400} !important;
            }
        }

        &:checked + label {
            span {
                &:not(.checked):not(.icon-checked) {
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -24px;
                        z-index: 1;
                        background-color: ${(props) =>
                            props.theme.colors[props.color]};
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        bottom: 0;
                        margin: auto;
                        animation: ${AnimShrinkBounce} 200ms
                            cubic-bezier(0.4, 0, 0.23, 1);
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -19px;
                        z-index: 1;
                        background-color: ${(props) =>
                            props.theme.colors.white};
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        bottom: 0;
                        margin: auto;
                        animation: ${AnimShrinkBounceAfter} 300ms
                            cubic-bezier(0.4, 0, 0.23, 1);
                    }
                }
            }
        }
    }
    // icon variant
    ${({ icon, color, theme, variant }) =>
        `
        // Variant small & medium
        ${
            icon &&
            (variant === "medium" || variant === "small") &&
            `
                background-color: transparent;
                border: none;
                outline: none;
                border: 2px solid ${
                    variant === "small" ? theme.border.color : "transparent"
                }; 
                padding: ${variant === "small" ? 0 : "5px"};
                max-width: ${
                    variant === "small"
                        ? variants.small.size
                        : variants.medium.size
                };
                min-width: ${
                    variant === "small"
                        ? variants.small.size
                        : variants.medium.size
                };
                max-height: ${
                    variant === "small"
                        ? variants.small.size
                        : variants.medium.size
                };
                width: ${
                    variant === "small"
                        ? variants.small.size
                        : variants.medium.size
                };
                height: ${
                    variant === "small"
                        ? variants.small.size
                        : variants.medium.size
                };
                align-items: center;
                justify-content: center;
                ${variant === "small" && `border-radius: 50%;`};
                span {
                    border-radius: 0 !important;
                    &:before, &:after{
                        display: none;
                        content: initial;
                    }
                }

                input{
                    + label {
                        flex-wrap: wrap;
                        justify-content: center;
                        span {
                            &.icon-checked {
                                position: relative;
                                ${
                                    variant === "small" &&
                                    `
                                    &:before{
                                        content: " ";
                                        position: absolute;
                                        border: 0 solid ${theme.colors[color]};
                                        display: block;
                                        top: -5px;
                                        left: -5px;
                                        border-radius: 50%;
                                        width: ${variants.small.size};
                                        height: ${variants.small.size};
                                        transition: .3s;
                                    }
                                `
                                };
                                > svg{
                                    z-index: 1;
                                    position: relative;
                                    &:first-of-type{
                                        display:none;
                                    }
                                    &:last-of-type{
                                        display:block;
                                    }
                                }
                            }
                            &:not(.icon-checked) {
                                font-size: ${theme.typography.text.small};
                                color: ${theme.colors.grey700};
                                max-width: calc(${variants.medium.size} - 10px);
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                ${variant === "small" && `display:none`};
                            }
                        }
                    }
                    &:disabled + label{
                        svg{
                            rect,polygon,path{
                                fill: ${theme.colors.grey700};
                            }
                        }
                    }
                    &:checked + label {
                        span {
                            &.icon-checked {
                                &:before{
                                    border-width: 10px;
                                }
                                > svg{
                                    rect,polygon,path{
                                        fill: ${
                                            variant === "small"
                                                ? theme.colors.white
                                                : theme.colors[color]
                                        }; 
                                    }
                                    &:first-of-type{
                                        display:block;
                                    }
                                    &:last-of-type{
                                        display:none;
                                    }
                                }
                            }
                            &:not(.icon-checked) {
                                color: ${theme.colors[color]};
                            }
                        }
                    }
                }
                &:hover{
                    input{
                        &:not(:disabled):not(:checked) + label span {
                            color: ${theme.colors[color]} !important;
                            svg {
                                path, react, polygon{
                                    fill: ${theme.colors[color]};
                                }
                            }
                        }
                        &:disabled + label {
                                cursor: not-allowed;
                        }
                    }
                }
            `
        }
    `}
`;

export const StyledInput = styled.input`
    display: inline-block;
    height: 0;
    width: 0;
    display: none;
`;

export const StyledLabel = styled.label`
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    span {
        &.checked {
            &:first-child {
                margin-right: 5px;
            }
            &:last-child {
                margin-left: 5px;
            }
        }
        &:after {
            width: 0;
            height: 0;
            transition: 1s;
        }
    }
`;

export const StyledIconContainer = styled.span<RadioContainerProps>`
    display: block;
    > svg,
    > img {
        ${({ variant }) => `
            width: ${
                variant === "small"
                    ? variants.small.iconSize
                    : variants.medium.iconSize
            };
            max-width: ${
                variant === "small"
                    ? variants.small.iconSize
                    : variants.medium.iconSize
            };
            height: ${
                variant === "small"
                    ? variants.small.iconSize
                    : variants.medium.iconSize
            };
            max-height: ${
                variant === "small"
                    ? variants.small.iconSize
                    : variants.medium.iconSize
            };
            &:not(:only-child) {
                margin-bottom: ${variant === "small" ? 0 : "5px"};
            }
        `}
    }
`;
