import React from "react";

import styled from "styled-components";
import { Theme } from "../../theme/theme.model";
import { Typography } from "../../components/Typography";

export interface AvatarProps {
    children?: React.ReactNode;
    /* Source link or DataURL for image in Avatar */
    src?: string;
    /* Text to show inside Avatar */
    text?: string;
    /* Icon to show inside Avatar */
    icon?: React.ReactNode;
}

interface StyledAvatarProps extends AvatarProps {
    theme: Theme;
}

export const StyledAvatar = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-align: center;
    background-color: ${({ theme }: StyledAvatarProps) => theme.colors.grey400};
    color: ${({ theme }: StyledAvatarProps) => theme.colors.primary};
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    svg {
        width: 20px;
        height: 20px;
    }
`;

const Avatar = ({ children, icon, src, text, ...rest }: AvatarProps) => {
    if (src) {
        return (
            <StyledAvatar {...rest}>
                <img src={src} />
            </StyledAvatar>
        );
    }
    if (icon) {
        return <StyledAvatar {...rest}>{icon}</StyledAvatar>;
    }
    if (text) {
        return (
            <StyledAvatar {...rest}>
                <Typography variant="h4" color="primary">
                    {text}
                </Typography>
            </StyledAvatar>
        );
    }

    return <StyledAvatar {...rest}>{children}</StyledAvatar>;
};

export default Avatar;
