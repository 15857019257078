import React, { useState } from "react";
import styled from "styled-components";
import { Theme } from "../../theme/theme.model";
import { StyledLabel } from "../Input/Input";

export interface SelectProps
    extends React.SelectHTMLAttributes<HTMLSelectElement> {
    /* Array of strings representing the options that will be available inside the Select component.  */
    options?: Array<{ value: string; label: string }>;
    type?: "default" | "simple";
    label?: string;
    fullWidth?: boolean;
}

interface StyledSelectProps extends SelectProps {
    theme: Theme;
}
interface StyledSelectContainerProps
    extends React.HTMLAttributes<HTMLSpanElement> {
    minWidth?: number;
    fullWidth?: boolean;
}

const StyledSelect = styled.select<StyledSelectProps>`
    height: ${({ theme }) => `${theme.size.form.input}px`};
    max-width: 100%;
    background-color: ${({ theme }) => theme.colors.grey400};
    border-radius: 4px;
    border: 0;
    content: "${({ label }) => label}";
    padding: ${({ label }) =>
        label ? "19px 45px 4px 11px" : "4px 45px 4px 11px"};
    cursor: pointer;
    appearance: none;
    z-index: 3;
    font-size: 14px;
    text-shadow: 0px 1px 0px #ebebeb;
    color: ${({ theme, label }) =>
        label ? theme.colors.grey900 : theme.colors.grey800};
    font-weight: 700;

    background-image: linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 2.5em) 15px;
    background-size: 1px 1.5em;
    background-repeat: no-repeat;
    background-color: ${({ theme, type }) =>
        type === "simple"
            ? `${theme.colors.white}`
            : `${theme.colors.grey400}`};
    &:focus {
        outline: 0;
    }
`;

const StyledSelectContainer = styled.span<StyledSelectContainerProps>`
    position: relative;
    height: 50px;
    display: inline-flex;
    min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : "0")};
    > select {
        width: 100%;
    }
    ${({ fullWidth }) =>
        fullWidth &&
        `
        width: 100%;
        > select, > div, > ul{
            width: 100%;
        }
    `};
    /* overflow: hidden; */
    &:after {
        position: absolute;
        content: " ";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: ${({ theme }) => theme.colors.grey800} transparent
            transparent transparent;
        right: 14px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 4;
    }
    &.open:after {
        width: 0px;
        height: 0px;
        transform: rotate(360deg);
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent
            ${({ theme }) => theme.colors.grey800} transparent;
    }
`;

const StyledTextareaLabel = styled(StyledLabel)`
    font-size: ${({ theme }) => theme.typography.text.small};
    top: 10px;
    line-height: 15px;
    margin-bottom: 0;
    z-index: 4;
`;

const Select = ({
    placeholder,
    options = [],
    label,
    fullWidth = false,
    ...props
}: SelectProps) => {
    const inputRef = React.createRef<HTMLSelectElement>();
    const [openSelect, setOpenSelect] = useState(false);

    return (
        <StyledSelectContainer
            className={openSelect ? `open` : `close`}
            minWidth={label?.length * 8 + 30}
            fullWidth={fullWidth}
        >
            {label && <StyledTextareaLabel>{label}</StyledTextareaLabel>}
            <StyledSelect
                {...props}
                label={label}
                ref={inputRef}
                style={{ minWidth: `${label?.length * 8 + 30}px` }}
            >
                {placeholder ? (
                    <option disabled selected hidden>
                        {placeholder}
                    </option>
                ) : null}
                {options.map(
                    ({ value, label }: { value: string; label: string }) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ),
                )}
            </StyledSelect>
        </StyledSelectContainer>
    );
};

export default Select;
