import React from "react";
import { IconName, IconProps, iconsMap } from "../Icons/Icons";

export interface ExtendedIconProps extends IconProps {
    name: IconName;
}

const Icon = ({ name, ...otherProps }: ExtendedIconProps) => {
    const Component = iconsMap[name];

    return <Component {...otherProps} />;
};

export default Icon;
