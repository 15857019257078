import styled from "styled-components";
import { ChipProps } from "./Chip";

export const StyledChip = styled.div<ChipProps>`
    display: inline-flex;
    padding: 4px 4px;
    border-radius: 4px;
    background-color: ${({ selected, theme }) =>
        selected ? theme.colors.primary : theme.colors.grey300};
    color: ${({ selected, theme }) =>
        selected ? theme.colors.white : theme.colors.grey700};
    font-size: ${(props) => props.theme.typography.text.normal};
    font-weight: ${(props) => props.theme.typography.weight.bold};
    align-items: center;
    &:hover {
        cursor: ${({ onClick }) => (onClick ? `pointer` : `default`)};
    }
    > span {
        margin: 0 5px;
    }
    button {
        zoom: 0.6;
        margin-right: 5px;
    }
    > svg,
    > button > svg {
        width: 12px;
        align-items: center;
    }
    > svg {
        margin-left: 5px;
        ${({ selected, theme }) =>
            selected &&
            `
                path, polygon, rect{
                    fill: ${theme.colors.white}
                }
            `};
    }
`;
