import React from "react";

import { StyledChip } from "./ChipStyles";
import { IconButton } from "../IconButton";
import { Close, Check, Plus } from "../Icons";

export interface ChipProps extends React.HTMLAttributes<HTMLDivElement> {
    check?: boolean;
    selected?: boolean;
    disabled?: boolean;
    onClick?: (...args: any) => void;
    onDelete?: (...args: any) => void;
}

const Chip = ({
    children,
    check = false,
    disabled,
    selected = false,
    onClick,
    onDelete,
    ...otherProps
}: ChipProps) => {
    const elRef = React.createRef<HTMLDivElement>();

    return (
        <StyledChip
            {...otherProps}
            selected={selected}
            onClick={onClick}
            ref={elRef}
        >
            {check && (selected ? <Check /> : <Plus />)}
            <span>{children}</span>
            {onDelete && (
                <IconButton
                    disabled={disabled}
                    onClick={(elRef) => {
                        onDelete(elRef.currentTarget.parentElement);
                    }}
                >
                    <Close />
                </IconButton>
            )}
        </StyledChip>
    );
};

export default Chip;
